import * as React from "react";
import Modal from "@mui/material/Modal";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { ethers } from 'ethers';
import { useNFTData } from './NFTDataContext';
import { Typography, Box, Button, Skeleton } from "@mui/material";

const loadingStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  borderRadius: 1,
  boxShadow: 0,
  bgcolor: "background.paper",
  p: 1,
  maxHeight: "500px", 
  outline: "none",
};

const style = {
  maxHeight: "500px", 
  overflowY: "scroll"
};

export function ContractModal(props) {
  // Props destructuring with validation
  const {
    userAccount,
    setBAYC,
    NFTsetOpen,
    switchPC,
    themeColor
  } = props;

 const { userNftData, isLoading, error } = useNFTData();

 //console.log("TEST123 - ContractModal received context:", { userNftData, isLoading, error });

  React.useEffect(() => {
    console.log("ContractModal: userNftData updated:", userNftData, isLoading, error);
    if (userNftData && Object.keys(userNftData).length > 0) {
      const contracts = Object.entries(userNftData).map(([address, tokens]) => ({
        contract: address,
        symbol: tokens[0]?.symbol || 'Unknown',
        name: tokens[0]?.name || tokens[0]?.symbol || 'Unknown Collection',
        image: tokens[0]?.metadata?.image || null,
        tokenCount: tokens.length
      }));
      console.log("ContractModal: processed contracts:", contracts);
      setListOfContracts(contracts);
    }
  }, [userNftData, userAccount]); // Only depend on nftData

     // Add debug effect
     React.useEffect(() => {
      console.log("NFT Data Structure:", {
          type: typeof userNftData,
          isArray: Array.isArray(userNftData),
          keys: Object.keys(userNftData),
          values: Object.values(userNftData),
          raw: userNftData
      });
  }, [userNftData, userAccount]);


  // Local state
  const [open, setOpen] = React.useState(false);
  const [listOfContracts, setListOfContracts] = React.useState([]);

  // Modal handlers
  const handleOpen = () => {
    console.log("Opening modal with NFT data:", {
      hasData: !!userNftData,
      dataKeys: userNftData ? Object.keys(userNftData) : [],
      userNftData: userNftData
    });
    setOpen(true);
  };
  
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    console.log("ContractModal Debug:", {
      userAccount: userAccount,
      nftDataKeys: Object.keys(userNftData),
      userNftData: userNftData
    });
    if (userNftData && Object.keys(userNftData).length > 0) {
      console.log("Processing NFT data for ContractModal:", userNftData);
      
      const contracts = Object.entries(userNftData).map(([address, tokens]) => {
        // Ensure tokens is an array and has at least one item
        if (!Array.isArray(tokens) || tokens.length === 0) {
          console.log("Invalid tokens for address:", address);
          return null;
        }

        return {
          contract: address,
          symbol: tokens[0]?.symbol || 'Unknown',
          name: tokens[0]?.name || tokens[0]?.symbol || 'Unknown Collection',
          image: tokens[0]?.metadata?.image || null,
          tokenCount: tokens.length
        };
      }).filter(Boolean); // Remove any null entries
      
      console.log("Processed contracts:", contracts);
      setListOfContracts(contracts);
    } else {
      console.log("No NFT data available:", { userNftData });
      setListOfContracts([]);
    }
  }, [userNftData, userAccount]);

  // Handle contract selection
  const onSelectContract = async (contractAddress) => {
    console.log("Contract selected:", contractAddress);
    
    if (!ethers.isAddress(contractAddress)) {
      console.error("Invalid contract address:", contractAddress);
      return;
    }

    // Set the selected contract
    if (setBAYC) {
      setBAYC(contractAddress);
    }

    // Close the modal
    handleClose();
    
    // Open NFT selection modal for Call options
    if (switchPC === 'Call' && NFTsetOpen) {
      setTimeout(() => {
        if (userNftData && userNftData[contractAddress]) {
          NFTsetOpen(true);
        }
      }, 100);
    }
  };

  // Render loading state if no wallet connected
  if (!ethers.isAddress(userAccount)) {
    return (
      <Button 
        variant='outlined' 
        sx={{
          color: themeColor,
          borderColor: themeColor
        }} 
        disabled
      >
        Connect wallet
      </Button>
    );
  }

  // Main render
  return (
    <div>
      <Button 
        variant='outlined' 
        sx={{
          color: themeColor,
          borderColor: themeColor,
          '&:hover': {
            backgroundColor: `${themeColor}20`,  // 12% opacity
            borderColor: `${themeColor}80`,      // 50% opacity
          }
        }} 
        onClick={handleOpen}
      >
        Select
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="contract-modal-title"
      >
        <Box sx={loadingStyle}>
          {isLoading ? (
            <>
              <Typography>Loading NFT Collections...</Typography>
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {[1, 2, 3].map((n) => (
                  <ListItem key={n} alignItems="flex-start">
                    <Skeleton 
                      animation="wave" 
                      variant="rectangular" 
                      width='100%'
                      height={60}
                      sx={{ borderRadius: 2, mb: 1 }}
                    />
                  </ListItem>
                ))}
              </List>
            </>
          ) : error ? (
            <Typography color="error">Error loading NFT collections: {error}</Typography>
          ) : !userNftData ? (
            <Typography>Loading NFT data...</Typography>
          ) : listOfContracts.length === 0 ? (
            <Box>
              <Typography variant="h6" gutterBottom>No NFTs Found</Typography>
              <Typography variant="body2" color="text.secondary">
                Debug Info:<br />
                NFT Data Available: {JSON.stringify(!!userNftData)}<br />
                Number of Contracts: {Object.keys(userNftData || {}).length}<br />
                Raw Data: {JSON.stringify(userNftData, null, 2)}<br />
                <Button 
                  onClick={() => console.log('NFT Data:', userNftData)} 
                  variant="outlined" 
                  size="small" 
                  sx={{ mt: 1 }}
                >
                  Log NFT Data
                </Button>
              </Typography>
            </Box>
          ) : (
            <>
              <Typography variant="h6" gutterBottom>
                SELECT COLLECTION ({listOfContracts.length})
              </Typography>
              <Box sx={style}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  {listOfContracts.map((contract, index) => (
                    <div key={contract.contract}>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar 
                            alt={contract.symbol} 
                            src={contract.image || "/static/images/avatar/1.jpg"} 
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={contract.name}
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {`${contract.contract.substring(0,7)}...${contract.contract.substring(38)}`}
                              </Typography>
                              <Typography
                                component="span"
                                variant="caption"
                                color="text.secondary"
                                display="block"
                              >
                                {`${contract.tokenCount} NFT${contract.tokenCount !== 1 ? 's' : ''}`}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                        <Button 
                          variant='outlined' 
                          sx={{
                            color: themeColor,
                            borderColor: themeColor,
                            marginTop: 1,
                            '&:hover': {
                              backgroundColor: `${themeColor}20`,  // 12% opacity
                              borderColor: `${themeColor}80`,      // 50% opacity
                            }
                          }} 
                          onClick={() => onSelectContract(contract.contract)}
                        >
                          Select
                        </Button>
                      </ListItem>
                      {index < listOfContracts.length - 1 && (
                        <Divider variant="inset" component="li" />
                      )}
                    </div>
                  ))}
                </List>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}