import React, { useState } from 'react';
import RedeemPut from './RedeemPut';
import {Box, Link} from '@mui/material';

const RedeemPutManager = ({ 
  options, 
  userAccount, 
  switchPC, 
  curr,
  disabled = false,
  nftSelections,
  setNftSelections,
  themeColor
}) => {
  // Use an object to track modal states with optionId as key
  const [modalStates, setModalStates] = useState({});
  // Track selected NFT states per option
  
  // Track select text states per option
  const [selectTexts, setSelectTexts] = useState({});

  const handleOpenModal = (optionId) => {
    // Close all other modals first
    const newModalStates = {};
    Object.keys(modalStates).forEach(key => {
      newModalStates[key] = false;
    });
    // Open only the clicked modal
    newModalStates[optionId] = true;
    setModalStates(newModalStates);
  };

  const handleCloseModal = (optionId) => {
    setModalStates(prev => ({
      ...prev,
      [optionId]: false
    }));
  };

  const handleSetSelectedNFT = (optionId, nftId) => {
    setNftSelections(prev => ({
      ...prev,
      [optionId]: nftId
    }));
  };

  const handleSetSelectText = (optionId, text) => {
    setSelectTexts(prev => ({
      ...prev,
      [optionId]: text
    }));
  };

  if (!disabled){

  
  return (
    <>
    
      {options.map(option => (
        <Box key={`redeem-put-${option.optionId}`} sx={{height: '30px', width:'100%', paddingTop:0, paddingBottom:0, paddingLeft:'7px', paddingRight:'7px'}}>
          <RedeemPut
            NFTopen={modalStates[option.optionId] || false}
            NFTsetOpen={(isOpen) => isOpen ? handleOpenModal(option.optionId) : handleCloseModal(option.optionId)}
            fakeBaycAddress={option.contractAddress}
            userAccount={userAccount}
            selectedNFTid={nftSelections[option.optionId] || 6}
            setSelectedNFTid={(nftId) => handleSetSelectedNFT(option.optionId, nftId)}
            RPselectText={selectTexts[option.optionId] || 'Select NFT'}
            setRPSelectText={(text) => handleSetSelectText(option.optionId, text)}
            switchPC={switchPC}
            disabled={disabled || (option.expiry < curr) || (String(option.ownerOf).toLowerCase() !== String(userAccount).toLowerCase())}
            themeColor={themeColor}
          />
        </Box>
      ))}
    
    </>
  );
} else {
  return (
    <></>
  );
}
};

export default RedeemPutManager;