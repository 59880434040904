import * as React from "react";
import Modal from "@mui/material/Modal";
import Swipeable from "./Swipeable";
import { useNFTData } from './NFTDataContext';
import { Box, Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  borderRadius: 1,
  boxShadow: 0,
  bgcolor: "transparent",
  p: 1,
  outline: "none",
};

export function SelectNFTmodal(props) {
  // Props destructuring
  const {
    contractAddress,
    userAccount,
    setSelectedNFTid,
    selectText,
    setSelectText,
    switchPC,
    isRP,
    NFTopen,
    NFTsetOpen,
    themeColor
  } = props;

  // Context and local state
  const { userNftData, getOwnedNFTsForContract, getImageFromMetadata, isLoading } = useNFTData();
  const [listOfNfts, setListOfNfts] = React.useState([]);
  const [currImage, setCurrImage] = React.useState('placeholder');
  const [open, setOpen] = React.useState(false);



  React.useEffect(() => {
    if (contractAddress && userNftData) {
      const normalizedAddress = contractAddress.toLowerCase();
      const contractNFTs = userNftData[normalizedAddress];
      
      if (contractNFTs && contractNFTs.length > 0) {
        const formattedNFTs = contractNFTs.map(nft => ({
          id: nft.tokenId,
          tokenUri: nft.tokenUri,
          metadata: nft.metadata
        }));
        
        setListOfNfts(formattedNFTs);
      } else {
        setListOfNfts([]);
      }
    }
  }, [contractAddress, userNftData]);


const handleOpen = () => {
    console.log("Opening NFT selection modal for contract:", contractAddress);
    console.log("SelectNFTModal -- Data should be from NFTDataContext:", listOfNfts, userNftData);
    setOpen(true);
  };
  
  const handleClose = () => setOpen(false);

  // Sync with external open state
  React.useEffect(() => {
    NFTsetOpen(open);
  }, [open, NFTsetOpen]);

  React.useEffect(() => {
    setOpen(NFTopen);
  }, [NFTopen]);


  React.useEffect(() => {
    console.log("SelectNFTModal Debug:", {
      contractAddress,
      nftDataKeys: Object.keys(userNftData),
      nftDataForContract: contractAddress ? userNftData[contractAddress.toLowerCase()] : null,
      listOfNfts
    });
  }, [contractAddress, userNftData, listOfNfts]);

  // Update button text based on wallet connection
  React.useEffect(() => {
    if (!userAccount) {
      setSelectText('Connect wallet');
    } else {
      setSelectText(isRP ? 'Select NFT' : 'Select ERC721 from wallet');
    }
  }, [userAccount, isRP, setSelectText]);

  // Handle NFT selection
  const handleSelect = (tokenId) => {
    console.log('NFT selected:', tokenId);
    setSelectedNFTid(tokenId);
    setSelectText(`ID #${tokenId} selected`);
    handleClose();
  };

  return (
    <Box sx={{width:'100%', height:'100%'}}>
      <Button
        variant="outlined"
        size='small'
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          maxWidth: "100%",
          minWidth: "100%",
          minHeight: "100%",
          marginTop: 0,
          fontSize:'12px'
        }}
        sx={{
          color: themeColor,
          borderColor: themeColor
        }}
        onClick={handleOpen}
        disabled={!contractAddress || selectText === 'Connect wallet'}
      >
        {selectText}
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Swipeable
            contractAddress={contractAddress}
            listOfNfts={listOfNfts}
            currImage={currImage}
            setCurrImage={setCurrImage}
            handleSelect={handleSelect}
            switchPC={switchPC}
            placeholder='placeholder'
            getImageFromMetadata={getImageFromMetadata}
            themeColor={themeColor}
          />
        </Box>
      </Modal>
    </Box>
  );
}