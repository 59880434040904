import React, { createContext, useContext, useState, useEffect, useCallback, useRef, useMemo } from 'react';
import Moralis from "moralis";
import { EvmChain } from "@moralisweb3/common-evm-utils";
import { ethers } from 'ethers';
import BAYC from "./artifacts/contracts/FakeBAYC.sol/BAYC.json";

import { getWalletNFTs } from './NFTProviderWrapper'; // Our new wrapper


const NFTDataContext = createContext();

const USE_ALCHEMY = process.env.REACT_APP_USE_ALCHEMY === "true";

const INFURA_URL = process.env.REACT_APP_INFURA_URL;
const IPFS_GATEWAY = "https://ipfs.io/ipfs/";

export function NFTDataProvider({ children }) {
 
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [connectedAddress, setConnectedAddress] = useState(null);
    const [tokenMetadata, setTokenMetadata] = useState({});

    const [userNftData, setUserNftData] = useState({}); // Replace ref with state

    const _setNFTData = useCallback((data) => {
      console.log("NFTDataContext: Setting NFT data:", data);
      setUserNftData(data);
      // Force a re-render
      setIsLoading(i => !i);
  }, []);

    // Add debug logging for address updates
    const setConnectedAddressWithLogging = useCallback((address) => {
      console.log("NFTDataContext: Setting connected address:", address);
      setConnectedAddress(address);
  }, []);

    // Fetch NFT data when wallet connects
    useEffect(() => {
      async function fetchNFTData() {
          if (!connectedAddress) return;
  
          setIsLoading(true);
          try {
              // Choose between Alchemy or Moralis based on some configuration:
      const response = await getWalletNFTs(connectedAddress, USE_ALCHEMY);

      // If using Moralis, the response is an array of NFT objects.
      // If using Alchemy, the response is an object with .ownedNfts, etc.

      let processedData;

      if (USE_ALCHEMY) {
        // For Alchemy: response.ownedNfts is an array
        console.log("Response from Alchemy:", response);
      
        // Re-map response.ownedNfts into a contract-based object
        processedData = response.ownedNfts.reduce((acc, nft) => {
          // In Alchemy's response, the contract address is under nft.contract.address
          const address = nft.contract?.address?.toLowerCase() || "unknown_contract";
      
          // Create an array for this contract if it doesn't exist
          if (!acc[address]) {
            acc[address] = [];
          }
      
          // Grab fields from the NFT response, with some fallback logic
          const tokenIdHex = nft.id?.tokenId || "";  // Alchemy often returns hex (e.g. "0x123")
          const tokenIdDecimal = parseInt(tokenIdHex, 16).toString(); // Convert hex to decimal string if needed
          const metadata = nft.metadata || nft.rawMetadata || {};
          const tokenUri = nft.tokenUri?.raw || "";
          const name = metadata.name || nft.title || "";
          const symbol = nft.contractMetadata?.symbol || nft.contract?.symbol || "";
              
          // Push the NFT data into our array
          acc[address].push({
            id: tokenIdDecimal,
            tokenId: tokenIdDecimal,  // or keep hex as is if you prefer
            name,
            symbol,
            tokenAddress: address,
            metadata,
            tokenUri,
          });
      
          return acc;
        }, {});
      } else {
                // For Moralis: response is an array
                console.log("Response from Moralis:", response);
                processedData = response.reduce((acc, nft) => {
                  const address = nft.tokenAddress._value.toLowerCase();
                  if (!acc[address]) acc[address] = [];

                  acc[address].push({
                    id: nft.tokenId,
                    tokenId: nft.tokenId,
                    name: nft.name,
                    symbol: nft.symbol,
                    tokenAddress: address,
                    metadata: nft.metadata,
                    tokenUri: nft.tokenUri
                  });
                  return acc;
                }, {});
            }
              console.log("TEST123 - fetchNFTData processedData:", processedData);
              //console.log("Setting NFT data:", processedData);
              _setNFTData(processedData);
          } catch (err) {
              console.log("TEST123 - fetchNFTData error:", err);
              console.error("Error fetching NFTs:", err);
              setError(err.message);
          } finally {
              setIsLoading(false);
          }
      }
      console.log("TEST123 - fetchNFTData called in NFTDataContext:", connectedAddress);
      fetchNFTData();
  }, [connectedAddress,  _setNFTData]);


// Moved from SelectNFTModal.js
const tokenURI = useCallback(async (contractAddress, tokenId) => {
  if (!contractAddress) return null;
  
  const normalizedAddress = contractAddress.toLowerCase();
  
  try {
    // Check cache first
    if (userNftData?.[normalizedAddress]) {
      const token = userNftData[normalizedAddress].find(
        t => String(t.tokenId) === String(tokenId)
      );
      if (token?.tokenUri) return token.tokenUri;
    }
    
    // Fallback to contract call
    const provider = new ethers.InfuraProvider("sepolia", INFURA_URL);
    const contract = new ethers.Contract(contractAddress, BAYC.abi, provider);
    return await contract.tokenURI(tokenId);
  } catch (error) {
    console.error("Error fetching tokenURI:", error);
    return null;
  }
}, []);

const getOwnedNFTsForContract = useCallback((contractAddress) => {
  if (!connectedAddress || !contractAddress) return [];

  const normalizedAddress = contractAddress.toLowerCase();
  
  // Check userNftData for this contract
  if (!userNftData[normalizedAddress]) {
    return [];
  }

  // Filter NFT list by the connected address
  return userNftData[normalizedAddress].filter(
    token => token.ownerOf?.toLowerCase() === connectedAddress.toLowerCase()
  );
}, [connectedAddress, userNftData]);

const getImageFromMetadata = useCallback(async (tokenURI) => {
  if (!tokenURI) return null;
  
  try {
    let fetchUrl = tokenURI;
    if (tokenURI.startsWith('ipfs://')) {
      fetchUrl = IPFS_GATEWAY + tokenURI.substring(7);
    }

    const response = await fetch(fetchUrl).then(data => data.json());
    
    // Try different metadata formats
    let imageUrl = response.image;
    if (response.properties?.image?.description) {
      imageUrl = response.properties.image.description;
    }

    if (imageUrl?.startsWith('ipfs://')) {
      imageUrl = IPFS_GATEWAY + imageUrl.substring(7);
    }

    return imageUrl;
  } catch (error) {
    console.error("Error fetching metadata:", error);
    return null;
  }
}, []);


const value = useMemo(() => ({
  userNftData: userNftData || {},
  isLoading,
  error,
  setConnectedAddress: setConnectedAddressWithLogging,
  hasData: Object.keys(userNftData || {}).length > 0,
  tokenURI,
  getOwnedNFTsForContract,
  getImageFromMetadata
}), [userNftData, isLoading, error, tokenURI, getOwnedNFTsForContract, getImageFromMetadata]);

//console.log("TEST123 - NFTDataContext value being provided:", value);
return (
    <NFTDataContext.Provider value={value}>
      {children}
    </NFTDataContext.Provider>
  );
}

export function useNFTData() {
    const context = useContext(NFTDataContext);
    if (context === undefined) {
        throw new Error('useNFTData must be used within a NFTDataProvider');
    }
    return context;
}