import * as React from 'react';
import {Box, Skeleton, Link, IconButton,FormGroup, FormControlLabel, Checkbox, Card, CardContent, CardActions, Stack, Divider, Grid, TextField, Button, InputLabel, MenuItem, FormControl, Select, Typography, ButtonGroup, CardMedia} from '@mui/material';
import {SelectNFTmodal} from './SelectNFTModal.js';
import { ethers } from 'ethers'

import {Logo} from './ethlogo.js';
import InputAdornment from '@mui/material/InputAdornment';
import IOSSwitch from './IOSwitch';
import {ContractModal} from './ContractModal';
import UploadFile from './IPFSupload';
import LinearProgress from './LinearProgress';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import OSwhite from './logos/OSwhite.svg' ;
import OSBlack from './logos/OSblack.svg' ;
import ESlogo from './logos/etherscan-logo-light-circle.svg';
import ESlogoBlack from './logos/etherscan-logo-dark.svg';

import ModalCreateOrder from './ModalCreateOrder';
import { useTheme } from '@material-ui/core/styles';

import RedeemPutManager from './RedeemPutManager';

//import BAYC from './artifacts/contracts/FakeBAYC.sol/BAYC.json'
import Option from './artifacts/contracts/ERC721Option.sol/ERC721Option.json'
import Marketplace from './artifacts/contracts/Marketplace.sol/Marketplace.json'
import {ZtrikeAddress,  NFTAddress, MarketplaceAddress} from './ethContracts';
import {initializeWallet, redeemOption, regretOption, retrieveOption } from "./Etherfuncs";
import {batchRetrieveExpiredOptions} from './ethCreateOffer.js';
import { getOrder, getBlockGas, supabase, getOptions, getOptionImage, getOwners, getActiveOrderIdByOptionId, getOrderOfferOnly} from './supaBaseFuncs.js';
import OptCardMedia from './OptCardMedia.js';

import { useConnectWallet } from '@web3-onboard/react'
import SearchableContractInput from './contractSelectDropdown.js';

import NFTCard from './NFTCard.js';
import OptionNameCard from './OptionNameCard.js';
import Slider from '@mui/material/Slider';
import debounce from 'lodash/debounce';
import { getUniqueWriters, getUniqueOwners } from './supaBaseFuncs';
import MemoizedOptionCard from './MemoizedOptionCard';
import CloseIcon from '@mui/icons-material/Close';
import { useBlockNumber } from './contexts/BlockContext';
import { useData } from './contexts/DataContext';

import { convertBlocksToTime } from './Etherfuncs';




const Histogram = ({ data, height = 20, theme, mode }) => {
  console.log("histogram",mode);
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        height: height,
        width: '100%',
        mb: -4
      }}
    >
      {data.map((value, index) => (
        <Box
          key={index}
          sx={{
            height: `${value * height}px`,
            flex: 1,
            backgroundColor: mode === 'light'
            ? '#414345'
            : '#dbdbdb',
            opacity: 0.2,
            mx: '1px'
          }}
        />
      ))}
    </Box>
  );
};

// Wrap OptionsView with React.memo
export function OptionsView(props) {
  const theme = props.theme;
  const mode = props.mode;
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();

  const {
    // Filter states
    filterStates: {
      showExpired,
      showCancelled,
      showPuts,
      showCalls,
      showMyOptions,
      strikeRange,
      expiryRange,
      filterContract,
    },
    // Filter actions
    filterActions: {
      setShowExpired,
      setShowCancelled,
      setShowPuts,
      setShowCalls,
      setShowMyOptions,
      setStrikeRange,
      setExpiryRange,
      setFilterContract,
      debouncedSetStrikeRange,
      debouncedSetExpiryRange
    },

    // Data
    options,
    filteredOptions,
    sortedOptions,
    orderDetails,
    activeOrderIds,
    uniqueContracts,

    // Range data
    possibleStrikes,
    possibleExpiries,

    // Loading state
    isLoading,

    // URL filter states
    urlFilterOptionId,
    setUrlFilterOptionId,
    showSpecificOption,
    setShowSpecificOption,
    // nft images
    nftImages,
    imageLoadingStates
  } = useData();

  const switchPC = props.switchPC;
  const userAccount = props.userAccount;
  const toggleTabBar = props.toggleTabBar;

  const { 
    blockNumber: curr, 
    loading: blockLoading,
    error: blockError 
  } = useBlockNumber();
  
  if (blockError) {
    console.error('Block error:', blockError);
  }
  
  const [rpOpen, setRPopen] = React.useState(false);
  const [RPselectText, setRPSelectText] = React.useState('Select NFT');
  const [selectedNFTid, setSelectedNFTid] = React.useState(6);
  const [openModalId, setOpenModalId] = useState(null);
  const [redeemFlowId, setRedeemFlowId] = useState(null);
  const [nftSelections, setNftSelections] = useState({});

  const [batchRetrieveLoading, setBatchRetrieveLoading] = useState(false);
  const [batchRetrieveStatus, setBatchRetrieveStatus] = useState('');

    // Keep UI-related states in OptionsView
    const [filterOn, setFilterOn] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [visualStrikeRange, setVisualStrikeRange] = useState(strikeRange);
    const [visualExpiryRange, setVisualExpiryRange] = useState(expiryRange);



    const [histData, setHistData] = useState([]);
    const [expiryHistData, setExpiryHistData] = useState([]);

    const calculateHistogram = (options, min, max, type = 'strike', bins = 40) => {
      const histogram = new Array(bins).fill(0);
      const binWidth = (max - min) / bins;
    
      options.forEach(option => {
        try {
          // Get value based on type
          const value = type === 'strike' 
            ? parseFloat(ethers.formatEther(String(option.strike)))
            : Number(option.expiry);
    
          if (!isNaN(value) && value >= min && value <= max) {
            const binIndex = Math.min(Math.floor((value - min) / binWidth), bins - 1);
            histogram[binIndex]++;
          }
        } catch (error) {
          console.error(`Error processing ${type} value:`, option[type], error);
        }
      });
    
      const maxCount = Math.max(...histogram);
      return maxCount > 0 ? histogram.map(count => count / maxCount) : histogram;
    };
    
    // Then in your component:
    useEffect(() => {
      const filteredByContract = options.filter(option => 
        (!filterContract || option.contractAddress?.toLowerCase() === filterContract?.toLowerCase()) &&
        (showExpired || Number(option.expiry) > curr) &&
        (showCancelled || !option.isCancelled)
      );
      // For strike histogram
      setHistData(calculateHistogram(
        filteredByContract, 
        possibleStrikes[0], 
        possibleStrikes[1], 
        'strike'
      ));
    }, [filteredOptions, possibleStrikes, filterContract]);
    
    // For expiry histogram (if needed)
    useEffect(() => {
      const filteredByContract = options.filter(option => 
        (!filterContract || option.contractAddress?.toLowerCase() === filterContract?.toLowerCase()) &&
        (showExpired || Number(option.expiry) > curr) &&
        (showCancelled || !option.isCancelled)
      );
      setExpiryHistData(calculateHistogram(
        filteredByContract, 
        possibleExpiries[0], 
        possibleExpiries[1], 
        'expiry'
      ));
    }, [filteredOptions, possibleExpiries, filterContract]);

  


    const handleOptionTypeChange = (type, checked) => {
      console.log(`Changing ${type} to ${checked}`);
      console.log('Current states - puts:', showPuts, 'calls:', showCalls);
      
      if (type === 'puts') {
        setShowPuts(checked);
        if (!checked && !showCalls) {
          console.log('Forcing calls to true as puts unchecked');
          setShowCalls(true);
        }
      } else {
        setShowCalls(checked);
        if (!checked && !showPuts) {
          console.log('Forcing puts to true as calls unchecked');
          setShowPuts(true);
        }
      }
    };

// Update the handlers to use the properly destructured functions
const handleStrikeChange = (event, newValue) => {
  setIsDragging(true);
  setVisualStrikeRange(newValue);
  setStrikeRange(newValue);
};

const handleExpiryChange = (event, newValue) => {
  setIsDragging(true);
  setVisualExpiryRange(newValue);
  setExpiryRange(newValue);
};

  const handleOpen = useCallback((optionId) => {
    setOpenModalId(optionId);
  }, []);

  const handleClose = useCallback(() => {
    setOpenModalId(null);
  }, []);

  const handleRedeemFlow = useCallback((optionId) => {
    setRedeemFlowId(optionId);
  }, []);

  const toggleFilter = () => setFilterOn(!filterOn);

// URL filter effect
useEffect(() => {
  const handleHashChange = () => {
    const hashParts = window.location.hash.toLowerCase().split('#');
    const filteredParts = hashParts.filter(part => part);
    
    if (filteredParts[0] === 'options' && filteredParts[1] !== undefined) {
      const optionId = parseInt(filteredParts[1], 10);
      if (!isNaN(optionId) || optionId === 0) {
        setUrlFilterOptionId(optionId);
        setShowSpecificOption(optionId.toString());
      } else {
        setUrlFilterOptionId('');
        setShowSpecificOption('');
      }
    } else {
      setUrlFilterOptionId('');
      setShowSpecificOption('');
    }
  };

  handleHashChange();
  window.addEventListener('hashchange', handleHashChange);
  return () => window.removeEventListener('hashchange', handleHashChange);
}, []);

const clearUrlFilter = () => {
  setUrlFilterOptionId('');
  setShowSpecificOption('');
  window.history.replaceState(null, null, '#options');
};



  // Memoize card props
  const cardProps = useMemo(() => ({
    mode: props.mode,
    theme: props.theme,
    switchPC: props.switchPC,
    MarketplaceAddress,
    toggleTabBar: props.toggleTabBar,
    redeemOption,
    retrieveOption,
    regretOption
  }), [
    props.mode,
    props.theme,
    props.switchPC,
    MarketplaceAddress,
    props.toggleTabBar,
    redeemOption,
    retrieveOption,
    regretOption
  ]);

  // Handle batch retrieve
  const handleBatchRetrieve = async () => {
    try {
      setBatchRetrieveLoading(true);
      setBatchRetrieveStatus('Retrieving expired options...');
      
      const expiredOptions = options.filter(option => 
        option.optWriter?.toLowerCase() === userAccount?.toLowerCase() &&
        option.ownerOf?.toLowerCase() !== "0x0000000000000000000000000000000000000000" &&
        (Number(option.expiry) - curr) < 1
      );
      
      const optionIds = expiredOptions.map(option => option.optionId);

      // if there is only one option just normal retrieve function
      if (optionIds.length === 1) {
        const result = await retrieveOption(optionIds[0]);
        setBatchRetrieveStatus(result.success ? 
          `Option ${optionIds[0]} retrieved successfully` : 
          `Failed: ${result.message}`
        );
        return;
      }


      const result = await batchRetrieveExpiredOptions(optionIds);
      
      setBatchRetrieveStatus(result.success ? 
        'Options retrieved successfully' : 
        `Failed: ${result.message}`
      );
    } catch (error) {
      setBatchRetrieveStatus(`Error: ${error.message}`);
    } finally {
      setBatchRetrieveLoading(false);
    }
  };


  if (isLoading) {
    return (
      <Card sx={{
        minHeight:'500px', 
        maxWidth:{xs:'100%', sm:'100%', lg:'100%'}, 
        minWidth: {sm:'97%', sm:'90%', lg:'50%'},
        paddingLeft:1, 
        paddingRight:0, 
        paddingTop:1
      }}>
        <CardContent sx={{paddingLeft:0, paddingRight:0, marginLeft:1, marginRight:0}}>
          <Grid container spacing={{ xs: 2, sm:3, md: 4 }} justify='top' 
            alignItems="stretch" columns={{ xs: 2, sm: 4, md: 6 }}>
            
            {/* Title and Search Area */}
            <Grid item xs={12} style={{ paddingTop: 10 }}>
              <Typography variant="h6" align='left' style={{paddingBottom:0, paddingTop:2}}>
                Options
              </Typography>
              
              {/* Searchable Contract Input Skeleton */}
              <Box sx={{mb:1, mt:2}}>
                <Skeleton 
                  variant="rectangular" 
                  width="98%" 
                  height={56} 
                  sx={{ 
                    borderRadius: 1,
                    marginTop: '-15px',
                    mb: 2
                  }}
                />
              </Box>
            </Grid>
  
            {/* Options Cards */}
            <Grid container item xs={12} wrap='nowrap' style={{
              paddingTop: 0,
              maxHeight: '1000px',
              overflowY: 'hidden',
              overflowX: 'hidden',
              display: "flex",
              flexDirection: "row",
              maxWidth: '100%',
              marginBottom: 0
            }}>
              {[1, 2, 3, 4, 5].map((_, index) => (
                <Grid item xs={12} sm={10} md={6}
                  style={{
                    padding: 3,
                    paddingTop: 0,
                    marginTop: 0,
                    marginBottom: 10,
                    minWidth: '190px',
                    maxWidth: '190px',
                    height: '610px'
                  }}
                  key={index}
                >
                  <Card style={{
                    backgroundImage: theme.custom.gradient1,
                    padding: 2,
                    height: '100%'
                  }}>
                    {/* Image skeleton */}
                    <Skeleton 
                      variant="rectangular" 
                      width="100%" 
                      height={310}
                      sx={{ mb: 1 }}
                    />
  
                    {/* Header with Option ID and Links */}
                    <Stack direction="row" spacing={0.5} sx={{ width: '100%', justifyContent: 'space-between', mb: 1 }}>
                      <Skeleton variant="text" width={80} height={24} />
                      <Stack direction="row" spacing={0.5}>
                        <Skeleton variant="circular" width={28} height={28} />
                        <Skeleton variant="circular" width={28} height={28} />
                      </Stack>
                    </Stack>
  
                    {/* Option Name Card */}
                    <Skeleton variant="rectangular" width="100%" height={80} sx={{ mb: 1 }} />
  
                    {/* NFT Card */}
                    <Skeleton variant="rectangular" width="100%" height={60} sx={{ mb: 1 }} />
  
                    {/* Owner and Writer Info */}
                    <Skeleton variant="text" width="80%" height={20} sx={{ mb: 0.5 }} />
                    <Skeleton variant="text" width="70%" height={20} sx={{ mb: 1 }} />
  
                    {/* Action Buttons */}
                    <Stack direction="row" spacing={1} sx={{ width: '100%', mb: 1 }}>
                      <Skeleton variant="rectangular" width="33%" height={32} />
                      <Skeleton variant="rectangular" width="33%" height={32} />
                      <Skeleton variant="rectangular" width="33%" height={32} />
                    </Stack>
  
                    {/* Sell/Bid Button */}
                    <Box sx={{ mt: 'auto', width: '100%' }}>
                      <Skeleton variant="rectangular" width="100%" height={32} />
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }




  return (
    <Card sx={{minHeight:'500px', maxWidth:{xs:'100%', sm:'100%', lg:'100%'}, minWidth: {sm:'97%', sm:'90%', lg:'50%'},
     paddingLeft:1, paddingRight:0, paddingTop:1}}>
      <CardContent sx={{paddingLeft:0, paddingRight:0, marginLeft:1, marginRight:0}}>
        <Grid container spacing={{ xs: 2, sm:3, md: 4 }} justify='top' 
          alignItems="stretch" columns={{ xs: 2, sm: 4, md: 6 }}>
          <Grid item xs={12} style={{ paddingTop: 10 }}>
            <Typography variant="h6" align='left' style={{paddingBottom:0, paddingTop:2}}>
              {urlFilterOptionId ? `Option #${urlFilterOptionId}` : 'Options'}
            </Typography>
            
            <Stack 
              direction="row" 
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                paddingTop: 2,
                paddingRight:1,
                alignContent:'top'
              }}
            >
              {userAccount && filteredOptions.filter(option => 
                option.optWriter?.toLowerCase() === userAccount?.toLowerCase() &&
                option.ownerOf?.toLowerCase() !== "0x0000000000000000000000000000000000000000" &&
                (Number(option.expiry) - curr) < 1
              ).length > 0 && (
                <Box sx={{ width: 'auto', paddingBottom:1, marginTop:-6.5, paddingRight:1 }}>
                  <Button 
                    variant="outlined" 
                    size="small"
                    onClick={handleBatchRetrieve}
                    disabled={batchRetrieveLoading}
                    color={props.switchPC === 'Call' ? 'primary': 'secondary'}
                    sx={{
                      border: 1,
                      borderColor: 'primary.main',
                      mt: 1
                    }}
                  >
                    <Typography sx={{fontSize: 12, color: 'primary.main'}}>
                      {batchRetrieveLoading ? 'Retrieving...' : 'Batch Retrieve'}
                    </Typography>
                  </Button>
                  {batchRetrieveStatus && (
                    <Typography 
                      variant="caption" 
                      color={batchRetrieveStatus.includes('successfully') ? 'success.main' : 'error.main'}
                      sx={{ fontSize: 8, maxWidth:'50px', overflow:'scroll'}}
                    >
                      {batchRetrieveStatus}
                    </Typography>
                  )}
                </Box>
              )}
              <Box sx={{ width: 'auto', paddingBottom:1, marginTop:-6.5, paddingRight:1 }}>
                <IconButton onClick={toggleFilter}>
                  {filterOn ? <FilterListOffIcon/> : <FilterListIcon/>}
                </IconButton>
              </Box>
              
              {userAccount && (
                <Box sx={{ width: 'auto', paddingBottom:1, marginTop:-6.5, paddingRight:1 }}>
                  <FormGroup>
                    <FormControlLabel 
                      control={
                        <Checkbox 
                          checked={showMyOptions} 
                          onChange={(e) => setShowMyOptions(e.target.checked)}
                          sx={{ 
                            '& .MuiSvgIcon-root': { 
                              fontSize: 20
                            }
                          }}
                        />
                      } 
                      label="My options"
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          fontSize: '0.875rem'
                        }
                      }}
                    />
                  </FormGroup>       
                </Box>
              )}
            </Stack>
            <Box sx={{mb:1, mt:-2, mr:2}}>
            <SearchableContractInput
                  contracts={uniqueContracts}
                  onChange={setFilterContract}
                  value={filterContract}
                  label="Underlying Contract"
                  sx={{
                    paddingBottom: 2, 
                    paddingRight: 1,
                    width: '100%'
                  }}
                  mode={mode}
                />
            </Box>
            {urlFilterOptionId !== '' && (
              <Box sx={{ 
                width: '100%', 
                display: 'flex', 
                justifyContent: 'flex-start',
                ml:0,
                mt: 1,
                mb: 1
              }}>
                <Button 
                  size="small"
                  variant="outlined"
                  onClick={clearUrlFilter}
                  sx={{ 
                    minWidth: '100px',
                    fontSize: '0.75rem'
                  }}
                >
                  Show all options
                </Button>
              </Box>
            )}
            
            {filterOn && (
              <>
                <Divider textAlign="left" sx={{paddingBottom:2}}>Filter Options</Divider>
                
                
                <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControlLabel 
                    control={
                      <Checkbox 
                        checked={showExpired} 
                        onChange={(e) => setShowExpired(e.target.checked)}
                        sx={{ 
                          '& .MuiSvgIcon-root': { 
                            fontSize: 20
                          }
                        }}
                      />
                    } 
                    label="Expired"
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '0.875rem',
                        marginRight: 2
                      }
                    }}
                  />
                  <FormControlLabel 
                    control={
                      <Checkbox 
                        checked={showCancelled} 
                        onChange={(e) => setShowCancelled(e.target.checked)}
                        sx={{ 
                          '& .MuiSvgIcon-root': { 
                            fontSize: 20
                          }
                        }}
                      />
                    } 
                    label="Cancelled" 
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '0.875rem'
                      }
                    }}
                  />
                  <FormControlLabel 
                    control={
                      <Checkbox 
                        checked={showPuts} 
                        onChange={(e) => handleOptionTypeChange('puts', e.target.checked)}
                        sx={{ 
                          '& .MuiSvgIcon-root': { 
                            fontSize: 20
                          }
                        }}
                      />
                    } 
                    label="Puts" 
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '0.875rem'
                      }
                    }}
                  />
                  <FormControlLabel 
                    control={
                      <Checkbox 
                        checked={showCalls} 
                        onChange={(e) => handleOptionTypeChange('calls', e.target.checked)}
                        sx={{ 
                          '& .MuiSvgIcon-root': { 
                            fontSize: 20
                          }
                        }}
                      />
                    } 
                    label="Calls" 
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '0.875rem'
                      }
                    }}
                  />
                </FormGroup>
                <Divider sx={{paddingTop:2}}/>
                <Box sx={{ 
                  width: '95%',
                  paddingRight: 1,
                  paddingTop: 1,
                  paddingBottom: 0,
                  position: 'relative',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}>
                  <Typography 
                    variant="caption" 
                    sx={{ 
                      position: 'absolute',
                      top: 8,
                      left: '5%',
                      color: 'text.secondary',
                      fontSize: '0.7rem'
                    }}
                  >
                    Strike
                  </Typography>

                  
                    <Histogram 
                      data={histData}
                      height={20}
                      theme={theme}
                      mode={mode}
                    />
                  


                  <Slider
                    value={isDragging ? visualStrikeRange : strikeRange}
                    onChange={handleStrikeChange}
                    onChangeCommitted={(event, newValue) => {
                      setIsDragging(false);
                      debouncedSetStrikeRange(newValue);
                    }}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => `${value.toFixed(4)} ETH`}
                    min={possibleStrikes[0]}
                    max={possibleStrikes[1]}
                    step={(possibleStrikes[1] - possibleStrikes[0]) / 39}
                    marks={[...Array(40)].map((_, index) => ({
                      value: possibleStrikes[0] + (index * (possibleStrikes[1] - possibleStrikes[0]) / 39),
                      label: `strike-mark-${index}`
                    }))}
                    sx={{
                      marginTop: 2,
                      '& .MuiSlider-rail': {
                        opacity: 0.3,
                        height: 2,
                        backgroundColor: theme.palette.grey[400]
                      },
                      '& .MuiSlider-track': {
                        height: 2,
                        border: 'none',
                        transition: 'none'
                      },
                      '& .MuiSlider-thumb': {
                        height: 16,
                        width: 16,
                        backgroundColor: theme.palette.background.paper,
                        border: '2px solid currentColor',
                        transition: 'none',
                        '&:focus, &:hover, &.Mui-active': {
                          boxShadow: 'inherit',
                          transition: 'none'
                        }
                      },
                      '& .MuiSlider-markLabel': {
                        display: 'none'
                      },
                      '& .MuiSlider-valueLabel': {
                        fontSize: '0.7rem',
                        padding: '0.3rem 0.5rem',
                        backgroundColor: theme.palette.primary.main
                      }
                    }}
                  />
                </Box>

                <Box sx={{ 
                  width: '95%',
                  paddingRight: 1,
                  paddingTop: 0,
                  paddingBottom: 1,
                  position: 'relative',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: -2
                }}>
                  <Typography 
                    variant="caption" 
                    sx={{ 
                      position: 'absolute',
                      top: 8,
                      left: '5%',
                      color: 'text.secondary',
                      fontSize: '0.7rem',
                      mt:-2
                    }}
                  >
                    Expiry
                  </Typography>
                  <Histogram 
                      data={expiryHistData}
                      height={20}
                      theme={theme}
                      mode={mode}
                    />
                  <Slider
                    value={isDragging ? visualExpiryRange : expiryRange}
                    onChange={handleExpiryChange}
                    onChangeCommitted={(event, newValue) => {
                      setIsDragging(false);
                      debouncedSetExpiryRange(newValue);
                    }}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) =>  
                      convertBlocksToTime(value-curr) === "expired" ? "1 second" : convertBlocksToTime(value-curr)
                    }
                    min={possibleExpiries[0]}
                    max={possibleExpiries[1]}
                    step={(possibleExpiries[1] - possibleExpiries[0]) / 39}
                    marks={[...Array(40)].map((_, index) => ({
                      value: possibleExpiries[0] + (index * (possibleExpiries[1] - possibleExpiries[0]) / 39),
                      label: `expiry-mark-${index}`
                    }))}
                    sx={{
                      marginTop: 2,
                      '& .MuiSlider-rail': {
                        opacity: 0.3,
                        height: 2,
                        backgroundColor: theme.palette.grey[400]
                      },
                      '& .MuiSlider-track': {
                        height: 2,
                        border: 'none',
                        transition: 'none'
                      },
                      '& .MuiSlider-thumb': {
                        height: 16,
                        width: 16,
                        backgroundColor: theme.palette.background.paper,
                        border: '2px solid currentColor',
                        transition: 'none',
                        '&:focus, &:hover, &.Mui-active': {
                          boxShadow: 'inherit',
                          transition: 'none'
                        }
                      },
                      '& .MuiSlider-markLabel': {
                        display: 'none'
                      },
                      '& .MuiSlider-valueLabel': {
                        fontSize: '0.7rem',
                        padding: '0.3rem 0.5rem',
                        backgroundColor: theme.palette.primary.main
                      }
                    }}
                  />
                </Box>
              </>
            )}
            {filterOn && <Divider sx={{paddingTop:2}}/>}
          </Grid>

          <Grid container item xs={12} wrap='nowrap' style={{
            paddingTop: 0,
            maxHeight: '1000px',
            overflowY: 'hidden',
            overflowX: 'auto',
            display: "flex",
            flexDirection: "row",
            maxWidth: '100%',
            scrollbarColor: '#282929 #6f7070',
            scrollbarWidth: 'thin',
            marginBottom: 0
          }}>
            {isLoading ? (
              [1, 2, 3, 4, 5, 6].map((_, index) => (
                <Grid item xs={12} sm={10} md={6}
                  style={{
                    padding: 3,
                    paddingTop: 0,
                    marginTop: 0,
                    marginBottom: 10,
                    minWidth: '190px',
                    maxWidth: '190px',
                    minHeight: '400px'
                  }}
                  key={`skeleton-${index}`}
                >
                  <Card style={{
                    backgroundImage: theme.custom.gradient1,
                    padding: 2,
                    height: '100%'
                  }}>
                    <Box style={{ marginTop: 0, paddingTop: 0 }}>
                      <Skeleton variant="rounded" width={210} height={250} />
                      <Skeleton variant="rectangular" width={210} height={60} />
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    </Box>
                  </Card>
                </Grid>
              ))
            ) : sortedOptions.length === 0 ? (
              <Grid item xs={12} style={{ padding: 20, textAlign: 'center' }}>
                <Typography variant="body1" color="text.secondary">
                  {filterOn ? 
                    "No options found matching your criteria" : 
                    "No options available"}
                </Typography>
              </Grid>
            ) : (
              sortedOptions.map((option) => {
                const fixedNftid = option.underlyingId === "any" ? 0 : option.underlyingId;
                const key = `${option.contractAddress}-${fixedNftid}`;
                const currImage = nftImages[key] || 'placeholder';
                const imageLoading = imageLoadingStates[key] || false;
                //console.log("OptionsView - option id", option.optionId, 'Rendering:',key, fixedNftid, currImage, imageLoading);
                return (
                <MemoizedOptionCard
                  key={`option-${option.optionId}`}
                  option={option}
                  {...cardProps}
                  theme={theme}
                  mode={mode}
                  userAccount={userAccount}
                  orderDetails={orderDetails}
                  activeOrderIds={activeOrderIds}
                  nftSelections={nftSelections}
                  setNftSelections={setNftSelections}
                  redeemFlowId={redeemFlowId}
                  setRedeemFlowId={handleRedeemFlow}
                  openModalId={openModalId}
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                  currImage={currImage}
                  imageLoading={imageLoading}
                />
                );
              })
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

