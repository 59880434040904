import React, { createContext, useState, useContext, useCallback } from 'react';
import { Card, CardContent } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

// Enum for notification types
const NotificationType = {
  INFO: 'info',
  SUCCESS: 'success', 
  FAIL: 'fail'
};

// Styling configuration for different notification types
const notificationStyles = {
  [NotificationType.INFO]: {
    color: 'info',
    icon: <InfoIcon sx={{ fontSize: 16, color: 'white' }} />
  },
  [NotificationType.SUCCESS]: {
    color: 'success',
    icon: <CheckCircleIcon sx={{ fontSize: 16, color: 'white' }} />
  },
  [NotificationType.FAIL]: {
    color: 'error',
    icon: <ErrorIcon sx={{ fontSize: 16, color: 'white' }} />
  }
};

// Notification Context
const NotificationContext = createContext(null);

// Notification Provider Component
export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);

  // Show notification method
  const showNotification = useCallback((message, type = NotificationType.INFO, duration = 3000) => {
    setNotification({ message, type });

    // Auto-dismiss
    const timer = setTimeout(() => {
      setNotification(null);
    }, duration);

    return () => clearTimeout(timer);
  }, []);

  // Clear notification method
  const clearNotification = useCallback(() => {
    setNotification(null);
  }, []);

  return (
    <NotificationContext.Provider value={{ showNotification, clearNotification }}>
      {children}
      {notification && (
        <Card 
          sx={{
            position: 'fixed',
            bottom: 50,
            left: '50%',
            transform: 'translateX(-50%)',
            width: 'calc(100% - 40px)',
            maxWidth: 600,
            zIndex: 1000,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: 'white'
          }}
          variant="outlined"
        >
          <CardContent 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              padding: '8px 16px !important',
              '&.MuiCardContent-root': {
                paddingBottom: '8px !important'
              }
            }}
          >
            {notificationStyles[notification.type]?.icon}
            <span 
              style={{ 
                marginLeft: 8, 
                fontSize: '0.675rem', 
                flexGrow: 1 
              }}
            >
              {notification.message}
            </span>
          </CardContent>
        </Card>
      )}
    </NotificationContext.Provider>
  );
};

// Custom hook for using notifications
export const useNotification = () => {
  const context = useContext(NotificationContext);
  
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }

  return context;
};

// Export the NotificationType for external use
export { NotificationType };