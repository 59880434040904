import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Box } from '@mui/material';
import ContractCard from './ContractCard';
import { useNFTData } from './NFTDataContext';

const SearchableContractInput = ({ contracts = [], onChange, value, label = "Underlying contract", mode }) => {
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { nftData } = useNFTData();

  const handleInputChange = (event, newInputValue) => {
    console.log("Input changed:", newInputValue);
    // Only set inputValue if we're actually typing, not when selecting
    if (!value) {
      setInputValue(newInputValue);
    }
  };

  const handleChange = (event, newValue) => {
    console.log("Selection changed:", newValue);
    if (onChange) {
      onChange(newValue);
      // Clear input value when we select something
      setInputValue('');
    }
  };


  const getOptionLabel = (option) => {
    if (!option) return '';
    
    // If we have NFT data for this contract, use its name
    if (nftData && nftData[option] && nftData[option][0]) {
      const contractData = nftData[option][0];
      return contractData.name || contractData.symbol || String(option);
    }
    
    // Fallback to address
    return String(option);
  };

  const filterOptions = (contracts, { inputValue }) => {
    return Array.isArray(contracts) 
      ? contracts.filter((contract) => {
          const optionLabel = getOptionLabel(contract).toLowerCase();
          const searchValue = inputValue.toLowerCase();
          return optionLabel.includes(searchValue) || 
                 String(contract).toLowerCase().includes(searchValue);
        })
      : [];
  };

  const clearValue = () => {
    handleChange(null, null);
    setInputValue('');
  };

  return (
    <Autocomplete
    open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      options={Array.isArray(contracts) ? contracts : []}
      renderInput={(params) => (
        <Box 
          position="relative"
          onClick={() =>  {
            if (value && !inputValue) {
              setIsOpen(true);
            }
          }}
          sx={{
            overflow: 'hidden' 
          }}
        >
          <TextField 
            {...params} 
            label={value && !inputValue ? '' : label}
            placeholder="All contracts"
            fullWidth
            sx={{
              '& .MuiInputBase-input': {
                height: '36px',
                // Only show input when there's no value or we're in input mode
                opacity: !value || inputValue ? 1 : 0,
              },
              // Preserve the clear button
              '& .MuiInputAdornment-root': {
                zIndex: 2
              }
            }}
          />
          {value && !inputValue && (
                     <Box
                     position="absolute"
                     top={0}
                     left={0}
                     right={0}
                     bottom={0}
                     sx={{ 
                       cursor: 'pointer',
                       display: 'flex',
                       alignItems: 'center',
                       paddingX: 2,
                       ml:'-15px',
                       mr:'-7px',
                       overflowX: 'auto',  // Enable horizontal scrolling
                       overflowY: 'hidden',
                       '& > *': {          // Target direct children (ContractCard)
                         minWidth: '270px',
                         flexShrink: 0     // Prevent the child from shrinking
                       },
                       // Custom scrollbar styling
                        scrollbarColor: '#282929 #6f7070',
                        scrollbarWidth: 'none',  // Firefox
                        '&::-webkit-scrollbar': {
                          display: 'none',  // Chrome, Safari, Edge
                        },
                        '-ms-overflow-style': 'none',  // IE and Edge
                        '&': {
                          msOverflowStyle: 'none',  // IE and Edge alternative syntax
                          scrollbarWidth: 'none',  // Firefox alternative syntax
                        }
                     }}
                   >
                     <ContractCard
                       theme={{}}
                       mode={'light'}
                       contractAddress={value}
                       nftData={nftData && nftData[value] ? nftData[value][0] : null}
                       isDropdownMode={true}
                       onClear={clearValue}
                     />
                   </Box>
          )}
        </Box>
      )} /// end of renderInput
      inputValue={inputValue}
      onInputChange={handleInputChange}
      value={value || null}
      onChange={handleChange}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value) => 
        option === value || 
        String(option).toLowerCase() === String(value).toLowerCase()
      }
      freeSolo
      filterOptions={filterOptions}
      openOnFocus
      clearOnBlur={false}
      blurOnSelect={false}
      size="small"
      sx={{ 
        backgroundColor: (theme) => 
          value ? (
            mode === 'light' 
              ? theme.palette.primary.main
              : theme.palette.primary.light
          ) : theme.palette.background.paper,
        borderRadius: '10px',
        width: '100%',
        '& .MuiFormControl-root': {
          marginRight: '0px',
          paddingRight: '0px'
        },
        '& .MuiAutocomplete-popper': {
          minWidth: '250px !important',
          // Target the paper specifically
          '& .MuiPaper-root.MuiAutocomplete-paper': {
            minWidth: '250px !important',
            overflow: 'auto !important'  // Override the auto overflow
          }
        },
        '& .MuiAutocomplete-option': {
          minWidth: '250px !important',
          padding: '2px 8px !important',
          display: 'block'
        }
      }}
      ListboxProps={{
        style: { 
          minWidth: '250px !important',
          overflow: 'auto',
          scrollbarColor: '#282929 #6f7070',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none', 
        }
      }}
      renderOption={(props, option) => {
        return (
          <Box
          key={`contract-${option}`}
          component="li"
          sx={{ 
            margin: 0,
            overflowX: 'hidden !important',
            overflowY: 'hidden !important',
            minWidth: '250px !important',
            scrollbarColor: '#282929 #6f7070',
            scrollbarWidth: 'thin',
            msOverflowStyle: 'none',  // Corrected property name
            '::-webkit-scrollbar': {
              height: '2px'
            },
            // Force ContractCard width
            '& .MuiCard-root': {
              minWidth: '250px !important',
              flexShrink: 0
            }
          }}
          {...props}
        >
            <ContractCard
              theme={{}}
              mode={'light'}
              contractAddress={option}
              nftData={nftData && nftData[option] ? nftData[option][0] : null}
              isDropdownMode={true}
            />
          </Box>
        );
      }}
    />
  );
};

export default SearchableContractInput;