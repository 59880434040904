
import { ethers } from 'ethers';
import {ZtrikeAddress,  NFTAddress, MarketplaceAddress} from './ethContracts';
import BAYC from './artifacts/contracts/FakeBAYC.sol/BAYC.json';
import Option from './artifacts/contracts/ERC721Option.sol/ERC721Option.json'
import { notificationService } from './contexts/notificationService';

//import { useConnectWallet } from '@web3-onboard/react'

//import { useAccount } from 'wagmi';
//const { address, isConnecting, isDisconnected } = useAccount()
const INFURA_URL = process.env.REACT_APP_INFURA_URL;
const provider = new ethers.InfuraProvider("sepolia", INFURA_URL);
export const ZtrikeContractProvider = new ethers.Contract(ZtrikeAddress, Option.abi, provider);

// Wallet information singleton
let wallet = null;

export function initializeWallet(walletInfo) {
  wallet = walletInfo;
}


async function getProvider(){
  if (wallet) {
    let account = wallet.accounts[0].address;
    const provider= new ethers.BrowserProvider(wallet.provider, 'any')
    const signer = await provider.getSigner()
    return [provider, signer, account];
}
}


export async function batchGetOwners(tokenIds) {
    console.log("batchGetOwners Called");
    //const provider = new ethers.providers.JsonRpcProvider(INFURA_URL);
    //const nftContract = new ethers.Contract(NFT_CONTRACT_ADDRESS, NFT_ABI, provider);
  
    // Create multicall aggregator contract instance
    const multicallAddress = '0xcA11bde05977b3631167028862bE2a173976CA11'; // Ethereum mainnet/sepolia
    const multicallAbi = ['function aggregate(tuple(address target, bytes callData)[] calls) view returns (uint256 blockNumber, bytes[] returnData)'];
    const multicall = new ethers.Contract(multicallAddress, multicallAbi, provider);
    // Create an array of calldata for each ownerOf call
    const calls = tokenIds.map(id => ({
      target: ZtrikeAddress,
      callData: ZtrikeContractProvider.interface.encodeFunctionData('ownerOf', [id])
    }));
  
    try {
          // Use multicall to batch all requests in a single API call
          const [blockNumber, returnData] = await multicall.aggregate(calls);
      
          // Decode the results
          const owners = returnData.map(data => {
            try {
              return ZtrikeContractProvider.interface.decodeFunctionResult('ownerOf', data)[0];
            } catch (error) {
              console.error('Error decoding result:', error);
              return null; // or handle this case as appropriate
            }
          });
      
          return owners;

    } catch (error) {
      console.error('Error fetching owners:', error);
      throw error;
    }
  }
  
async function _approveBAYC(underlyingId, underlyingContract) {
  if (wallet) {
    const [provider, signer, account] = await getProvider();
      const contract = new ethers.Contract(underlyingContract, BAYC.abi, signer);
      console.log(`Approving: ${underlyingId}`)
      notificationService.notify("Approving option #"+String(underlyingId)+' - Approve in wallet', "info");
      const transation = await contract.approve(ZtrikeAddress, underlyingId);
      await transation.wait();
      notificationService.notify("Approved ZtrikeAddress "+ZtrikeAddress, "success");
      console.log(`Approved ZtrikeAddress ${ZtrikeAddress}`);
    }
    }

  export async function redeemOption(redeemOptionId, isCall, tokenId, strike, underlyingContract) {
    //setLoadingText('Redeeming option');
    //setShowHide(true);
    console.log(ZtrikeAddress);
    if (wallet) {
      const [provider, signer, account] = await getProvider();
      const contract = new ethers.Contract(ZtrikeAddress, Option.abi, signer);
      console.log(`Redeeming Option from ABI --- STRIKE: ${strike}`)
      
      const tVal = ethers.parseEther(`${strike}`);
      console.log(tVal);

      if (isCall){
        console.log("redeeming call");
        notificationService.notify("Redeeming option #"+String(redeemOptionId)+' - Approve in wallet', "info");
        const transation = await contract.redeemOption(redeemOptionId, 0, {value: tVal});
        await transation.wait();
        notificationService.notify("Succefully redeemed option #"+String(redeemOptionId), "success");
      } else {
        console.log("redeeming put");
        // FIRST APPROVE ERC721OPT by FROM MSG.SENDER !!
        await _approveBAYC(tokenId, underlyingContract);
        console.log(`Approved id ${tokenId}`)
        notificationService.notify("Redeeming option #"+String(redeemOptionId)+' - Approve in wallet', "info");
        const transation = await contract.redeemOption(redeemOptionId, tokenId);
        await transation.wait();
        notificationService.notify("Succefully redeemed option #"+String(redeemOptionId), "success");
      }
      //setLoadingText('Option redeemed');
      //setShowHide(false);
      //handleClose();
    }
    }

    // Function for retrieving strike/underlying from option after expiry
    export async function retrieveOption(redeemOptionId, isCall) {
      //setLoadingText('Retrieving option');
      //setShowHide(true);
      //console.log(ZtrikeAddress);
      if (wallet) {
        const [provider, signer, account] = await getProvider();
        const contract = new ethers.Contract(ZtrikeAddress, Option.abi, signer);
        //console.log(`Retrieving Option from ABI --- STRIKE: ${activeStrike}`)
        
        if (isCall){
          console.log("retrieving call");
          const transation = await contract.retrieveNFTfromExpiredOption(redeemOptionId);
          await transation.wait();
          notificationService.notify("Succefully retrieved option #"+String(redeemOptionId), "success");
        } else {
          console.log("retrieving put");
          const transation = await contract.retrieveNFTfromExpiredOption(redeemOptionId);
          await transation.wait();
          notificationService.notify("Succefully retrieved option #"+String(redeemOptionId), "success");
        }
        //setLoadingText('Option retrieved');
        //setShowHide(false);
        //handleClose();
      }
      }
  
      export async function regretOption(regretOptionId) {
        //setLoadingText('Regretting option');
        //setShowHide(true);
        //console.log(ZtrikeAddress);
        if (wallet) {
          const [provider, signer, account] = await getProvider();
          const contract = new ethers.Contract(ZtrikeAddress, Option.abi, signer);
          //console.log(`Retrieving Option from ABI --- STRIKE: ${activeStrike}`)
          
          console.log("regretting option");
          notificationService.notify("Regretted option #"+String(regretOptionId)+' - Approve in wallet', "info");
          const transation = await contract.cancelWriteReturnNFT(regretOptionId);
          await transation.wait();
          notificationService.notify("Succefully regretted option #"+String(regretOptionId), "success");

          //setLoadingText('Option regretted');
          //setShowHide(false);
          //handleClose();
        }
        }

export async function getBalance(userAccount) {
    if (typeof window.ethereum !== 'undefined') {
        const provider = new ethers.InfuraProvider("sepolia", INFURA_URL);
        const balance = await provider.getBalance(userAccount);
        return balance;
    } else {
        return 0;
    }
}

export async function requestAccount() {
    const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' });
    return account;
}


export function convertBlocksToTime(nBlocksLeft) {
  if (nBlocksLeft <= 0) {
    return "expired";
  }
  // Assuming an average of 1 block mined every 10 minutes
  const minutesPerBlock = 0.2;
  const totalMinutes = nBlocksLeft * minutesPerBlock;

  const months = Math.floor(totalMinutes / (30 * 24 * 60));
  const days = Math.floor(totalMinutes / (24 * 60));
  const hours = Math.floor(totalMinutes / 60);

  if (months > 0) {
    return `${months} ${months === 1 ? 'month' : 'months'}`;
  } else if (days > 0) {
    return `${days} ${days === 1 ? 'day' : 'days'}`;
  } else if (hours > 0) {
    return `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
  } else {
    const minutes = Math.max(1, Math.round(totalMinutes));
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
  }
}