import * as React from "react";
import { Box, Typography, AppBar, IconButton, Toolbar, Divider, Stack} from '@mui/material'
import {Refresh, LocalGasStation, ViewInAr} from '@mui/icons-material';
import { useData } from './contexts/DataContext'; 

// Custom hook for counting animation
const useCountUp = (end, duration = 2000, start = 0) => {
        const [count, setCount] = React.useState(start);
        const [isAnimating, setIsAnimating] = React.useState(true);
      
        React.useEffect(() => {
          if (end !== '-' && !isNaN(end)) {
            let startTimestamp;
            const step = (timestamp) => {
              if (!startTimestamp) startTimestamp = timestamp;
              const progress = Math.min((timestamp - startTimestamp) / duration, 1);
              setCount(Math.floor(progress * (end - start) + start));
              if (progress < 1) {
                window.requestAnimationFrame(step);
              } else {
                setIsAnimating(false);
              }
            };
            window.requestAnimationFrame(step);
          }
        }, [end, duration, start]);
      
        return [isAnimating ? count : end, isAnimating];
      };
      export function AppBarFooter(props) {
        const { bb, gg, mode } = props;
        
        const { refreshDataOverwrite} = useData(); // Add this line
        const [isRefreshing, setIsRefreshing] = React.useState(false);
        
        const [animatedBb, bbAnimating] = useCountUp(bb, 2000, 0);
        const [animatedGg, ggAnimating] = useCountUp(gg, 2000, 0);
    
        const [bbChanged, setBbChanged] = React.useState(false);
        const [ggChanged, setGgChanged] = React.useState(false);
        const [dataUpdated, setDataUpdated] = React.useState(false);


        const handleRefresh = async () => {
            setIsRefreshing(true);
            try {
                await refreshDataOverwrite(); // This will handle loading state internally
            } finally {
                setIsRefreshing(false);
            }
        };

        React.useEffect(() => {
                if (bb !== 0) {
                setBbChanged(true);
                setDataUpdated(true);
                const timer = setTimeout(() => setBbChanged(false), 1000);
                return () => clearTimeout(timer);
                }
        }, [bb]);
            
        React.useEffect(() => {
                if (gg !== 0) {
                    setGgChanged(true);
                    setDataUpdated(true);
                    const timer = setTimeout(() => setGgChanged(false), 1000);
                    return () => clearTimeout(timer);
                }
            }, [gg]);
    
        return (
            <AppBar 
                position="fixed" 
                sx={{ 
                    height: '20px',
                    minHeight: '20px',
                    top: 'auto', 
                    bottom: 0, 
                    backgroundColor: mode == "light" ? 'rgba(255, 255, 255, 0.7)': 'rgba(0, 0, 0, 0.7)',
                    backdropFilter: 'blur(5px)',
                    boxShadow: 'none',
                    zIndex: 1300
                }}
            >
                <Toolbar 
                    disableGutters
                    sx={{ 
                        minHeight: '20px !important',
                        height: '100%',
                        px: 1
                    }}
                >
                    <Box
                    sx={{
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                        backgroundColor: dataUpdated ? '#4caf50' : '#f44336',
                        marginRight: 1,
                        transition: 'background-color 0.3s ease',
                        }}
                    />
                    <Typography 
                        variant='caption'
                        sx={{
                            fontSize: 9,
                            fontFamily: 'Roboto Mono, monospace',
                            color: mode === 'dark' ? 'white' : 'black',
                            lineHeight: 1,
                            opacity: 0.8,
                            letterSpacing: -0.3,
                        }}
                    >
                       <ViewInAr sx={{ 
                           fontSize: 11,
                           mb: '-2px',
                           opacity: 0.8,
                           color: mode === 'dark' ? 'white' : 'black'
                       }} />
                       {' '}#
                        <span style={{
                            transition: 'color 0.5s ease',
                            color: bbChanged ? '#4caf50' : 'inherit',
                        }}>
                            {bbAnimating ? animatedBb : bb}
                        </span>
                        {' '}
                       <LocalGasStation sx={{ 
                           fontSize: 11,
                           mb: '-2px',
                           opacity: 0.8,
                           color: mode === 'dark' ? 'white' : 'black'
                       }} />
                       {' '}
                        <span style={{
                            transition: 'color 0.5s ease',
                            color: ggChanged ? '#4caf50' : 'inherit',
                        }}>
                            {ggAnimating ? animatedGg : gg}
                        </span>
                        {' '}gwei
                    </Typography>
                    <Box sx={{ml: '5px'}}>
                        <Stack direction="row" spacing={0} flexWrap="nowrap">
                            <Divider 
                                orientation="vertical" 
                                flexItem 
                           sx={{
                               height: '12px',  // Explicit height
                               borderColor: mode === 'dark' ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.2)', // Add visible color
                               mx: 1  // Add margin on both sides
                           }}
                       />
                        <IconButton
                            onClick={handleRefresh}
                            disabled={isRefreshing}
                            sx={{
                                padding: 0,
                                mt:'-2px',
                                width: 16,
                                height: 16,
                                color: mode === 'dark' ? 'white' : 'black',
                                opacity: 0.8,
                                '&:hover': {
                                    opacity: 1,
                                    backgroundColor: 'transparent'
                                },
                                animation: isRefreshing ? 'spin 1s linear infinite' : 'none',
                                '@keyframes spin': {
                                    '0%': {
                                        transform: 'rotate(0deg)',
                                    },
                                    '100%': {
                                        transform: 'rotate(360deg)',
                                    },
                                }
                            }}
                        >
                            <Refresh sx={{ fontSize: 14 }} />
                        </IconButton>
                      
                        <Divider 
                           orientation="vertical" 
                           flexItem 
                           sx={{
                               height: '12px',  // Explicit height
                               borderColor: mode === 'dark' ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.2)', // Add visible color
                               mx: 1  // Add margin on both sides
                           }}
                       />
                       </Stack>
                    </Box>
                </Toolbar>     
            </AppBar>
        );
    }
