import * as React from 'react';
import {SelectNFTmodal} from './SelectNFTModal';
import Box from '@mui/material/Box';

function RedeemPut(props) {
    const NFTopen = props.NFTopen;
    const NFTsetOpen =  props.NFTsetOpen;
    const fakeBaycAddress =  props.fakeBaycAddress;  
    const userAccount =  props.userAccount;
    const selectedNFTid =  props.selectedNFTid;
    const setSelectedNFTid =  props.setSelectedNFTid;
    const RPselectText =  props.RPselectText;
    const setRPSelectText =  props.setRPSelectText;
    const switchPC =  props.switchPC;
    const themeColor = props.themeColor;
    const disabled = props.disabled;
return(
        <Box display="flex" justifyContent="center" alignItems="center" sx={{width:'100%'}}>
{ !disabled &&
            <SelectNFTmodal 
            NFTopen = {NFTopen}
            NFTsetOpen = {NFTsetOpen}
            fakeBaycAddress = {fakeBaycAddress}  
            userAccount = {userAccount}
            selectedNFTid = {selectedNFTid}
            setSelectedNFTid = {setSelectedNFTid}
            selectText = {RPselectText} 
            setSelectText = {setRPSelectText}
            switchPC = {switchPC}
            isRP = {true}
            themeColor={themeColor}
        />  }
        </Box>
);
}

export default RedeemPut;