/**
 * NFTProviderWrapper.js
 *
 * A simple wrapper to fetch NFTs from Moralis OR Alchemy.
 * Example usage:
 *
 *    import { getWalletNFTs } from "./NFTProviderWrapper";
 *
 *    async function myFetch() {
 *      const nfts = await getWalletNFTs("0x1234...", true); // useAlchemy = true
 *      console.log(nfts);
 *    }
 */

import Moralis from "moralis";
import { EvmChain } from "@moralisweb3/common-evm-utils";

// Removed: import { Alchemy, Network } from "alchemy-sdk";

const MORALIS_API_KEY = process.env.REACT_APP_MORALIS_API_KEY;
const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY || "REPLACE_WITH_YOUR_KEY";
const ALCHEMY_NETWORK = process.env.REACT_APP_ALCHEMY_NETWORK || "eth-mainnet"; // e.g. "eth-sepolia" or "eth-mainnet"

/**
 * Ensures Moralis is initialized (lazy-initialized).
 */
async function ensureMoralisInitialized() {
  if (!Moralis.Core.isStarted) {
    if (!MORALIS_API_KEY) {
      throw new Error("Moralis API key is not configured");
    }
    await Moralis.start({
      apiKey: MORALIS_API_KEY,
    });
  }
}

/**
 * Fetches NFTs for given wallet address using Moralis.
 *
 * @param {string} walletAddress - The wallet address to look up.
 * @returns {Promise<Array>} - Array of NFT data returned by Moralis.
 */
async function getWalletNFTsMoralis(walletAddress) {
  await ensureMoralisInitialized();
  const response = await Moralis.EvmApi.nft.getWalletNFTs({
    address: walletAddress,
    chain: EvmChain.SEPOLIA,   // Adjust to your chain
  });

  const filtered = (response.result || []).filter(nft => 
    !nft.name?.toLowerCase().includes('ztrike')
  );
  return filtered;
}

/**
 * Fetches NFTs for given wallet address using Alchemy’s REST API via fetch.
 *
 * @param {string} walletAddress - The wallet address to look up.
 * @returns {Promise<Object>} - The JSON response from Alchemy's REST endpoint.
 */
async function getWalletNFTsAlchemy(walletAddress) {
  const baseUrl = `https://${ALCHEMY_NETWORK}.g.alchemy.com/v2/${ALCHEMY_API_KEY}`;
  const endpoint = `/getNFTsForOwner?owner=${walletAddress}&withMetadata=true&pageSize=100`;

  const url = `${baseUrl}${endpoint}`;
  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json'
    }
  };

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`Error fetching Alchemy data: ${response.status} - ${response.statusText}`);
    }
    const data = await response.json();
    console.log('Alchemy data:', data);
    
    // Filter out NFTs with "Ztrike" in the name
    const filtered = {
      ...data,
      ownedNfts: data.ownedNfts.filter(nft => 
        !nft.title?.toLowerCase().includes('ztrike')
      )
    };
    return filtered;
  } catch (err) {
    console.error("Error in getWalletNFTsAlchemy:", err);
    throw err;
  }
}

/**
 * Main wrapper function. 
 * Set useAlchemy=true to use Alchemy, false to use Moralis.
 *
 * @param {string} walletAddress
 * @param {boolean} useAlchemy
 * @returns {Promise<any>} - NFTs, in the format the chosen provider returns.
 */
export async function getWalletNFTs(walletAddress, useAlchemy = false) {
  if (!walletAddress) return [];

  if (useAlchemy) {
    console.log("Using Alchemy (fetch) to fetch NFTs for", walletAddress);
    return getWalletNFTsAlchemy(walletAddress);
  } else {
    console.log("Using Moralis to fetch NFTs for", walletAddress);
    return getWalletNFTsMoralis(walletAddress);
  }


}