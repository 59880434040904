import React from 'react';
import { Box, Card, Skeleton, Image, Stack, Typography } from '@mui/material';
import { useData } from './contexts/DataContext';
import VerifiedIcon from '@mui/icons-material/Verified';
import CloseIcon from '@mui/icons-material/Close';


const ContractCard = ({ theme, mode, contractAddress, nftid = 0, cardMode = "default", onClear }) => {
  const { 
    contractData, 
    fetchContractData, 
    collectionLogos, 
    logoLoadingStates 
  } = useData();

  const hasFetchedRef = React.useRef(false);

  const currentContractData = contractData[contractAddress?.toLowerCase()] || null;

  // Use the ref-based effect we added earlier
  React.useEffect(() => {
    // Skip if we've already fetched or if there's no contract address
    if (!contractAddress || hasFetchedRef.current) return;

    // Check if we already have the data
    if (!currentContractData) {
      hasFetchedRef.current = true;
      fetchContractData(contractAddress);
    }
  }, [contractAddress]); 


  const logoUrl = collectionLogos[contractAddress] || currentContractData?.collection_logo;
  const isLogoLoading = logoLoadingStates[contractAddress];

  if (!currentContractData) {
    return (
      <Card sx={{height:'50px', width:'100%'}}>
        <Stack flexWrap="nowrap" direction='row'>
          <Skeleton variant="circular" width={50} height={50} />
          <Box sx={{ width: '100%', ml: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Skeleton variant="text" width="60%" height={24} />
            <Skeleton variant="text" width="40%" height={16} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto', mr: 2 }}>
            <Skeleton variant="text" width={60} height={20} />
          </Box>
        </Stack>
      </Card>
    );
  }

  if (cardMode === "default") {
    return (
      <Card sx={{
        height:'50px', 
        width:'100%',
        // Add scrollbar styling
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#282929',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#6f7070',
          borderRadius: '4px',
        },
        scrollbarColor: '#282929 #6f7070',
        scrollbarWidth: 'thin',
      }}>
        <Stack flexWrap="nowrap" direction='row'>
        {isLogoLoading ? (
          <Skeleton variant="circular" width={50} height={50} />
        ) : (
          <img 
            src={logoUrl}
            height='50px'
            sx={{ borderRadius: '85px', boxShadow: 0, maxWidth:'25%', minWidth:'50px' }}
          />
        )}
          <Box sx={{mr:'5px', border: 0, borderRadius: '16px', borderColor: '#000000', padding:1}}>
            <Typography variant='h6' align="left">
              {currentContractData.symbol}
              <VerifiedIcon style={{ width: '20px', height: '20px', marginLeft:'2px', verticalAlign: 'middle' }} />
            </Typography>
          </Box>

          <Stack flexWrap="nowrap" direction="column" spacing={0}
          sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'100%', marginTop:'10px'}}>
             <Box sx={{
               display:"flex", 
               flexDirection:"column", 
               justifyContent:"center",
               border: 0, 
               borderRadius: '16px', 
               borderColor: '#000000', 
               padding: '2px 8px'  // reduced padding
             }}>
               <Typography align="left" sx={{fontSize:10}}>
                 {String(currentContractData.token_address).slice(0,6)+'...'+String(currentContractData.token_address).slice(35,40)}
               </Typography>
             </Box>
              <Box sx={{
               display:"flex", 
               flexDirection:"column", 
               justifyContent:"center",
               border: 0, 
               borderRadius: '16px', 
               borderColor: '#000000', 
               padding: '2px 8px',  // reduced padding
               marginTop: '-4px'    // negative margin to pull up
             }}>
               <Typography variant='h6' align="left" sx={{fontSize:10, color:'#878787'}}>
                 {currentContractData.floor_price? 'Ξ10.62' : 'Ξ10.62'}
               </Typography>
             </Box>
         </Stack>
         
          {onClear && (
            <Box 
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 'auto',
                marginRight: 1,
                cursor: 'pointer'
              }}
              onClick={(e) => {
                e.stopPropagation();
                onClear();
              }}
            >
              <CloseIcon sx={{ fontSize: 20, color: 'text.secondary' }} />
            </Box>
          )}
        </Stack>
      </Card>
    );
  } else {
    return (
      <Card sx={{
        height:'60px', 
      width:'100%', 
      borderRadius:'10px', 
      boxShadow:0, 
      background:'transparent',
      // Add scrollbar styling
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#282929',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#6f7070',
        borderRadius: '4px',
      },
      scrollbarColor: '#282929 #6f7070',
      scrollbarWidth: 'thin',
      }}>
        <Stack flexWrap="nowrap" direction='column'>
          <Box sx={{
            mr:'5px', 
            border: 0, 
            borderRadius: '0px', 
            borderColor: '#000000', 
            padding:0, 
            height:'25px', 
            display:'flex', 
            justifyContent:'left', 
            alignItems:'center'
          }}>
            <Stack flexWrap="nowrap" direction="row">
              <Typography variant='h6' align="left" sx={{fontSize:15, display:'flex', alignItems:'center', justifyContent:'space-between', mr:'5px'}}>
              {isLogoLoading ? (
                  <Skeleton variant="circular" width={25} height={25} />
                ) : (
                  <img src={logoUrl} height='25px'/>
                )}
              </Typography>
              <Typography variant='h6' align="left" sx={{fontSize:15, display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                {currentContractData.symbol}
                <VerifiedIcon style={{ width: '15px', height: '15px', marginLeft:'4px', verticalAlign: 'middle' }} />
              </Typography>
            </Stack>
          </Box>
          <Box sx={{
            display:"flex", 
            flexDirection:"row", 
            justifyContent:"center",
            border: 0, 
            borderRadius: '16px', 
            borderColor: '#000000', 
            padding:0, 
            height:'20px', 
            display:'flex', 
            justifyContent:'left', 
            alignItems:'center'
          }}>
            <Typography sx={{fontSize:12}}>
              {nftid==="any" ? 'Any NFT' : `ID #${nftid}`}
            </Typography>      
          </Box>
          <Box sx={{
            display:"flex", 
            flexDirection:"row", 
            justifyContent:"center",
            border: 0, 
            borderRadius: '16px', 
            borderColor: '#000000', 
            padding:0, 
            height:'25px', 
            display:'flex', 
            justifyContent:'left', 
            alignItems:'center'
          }}>
            <Stack flexWrap="nowrap" direction="row" sx={{mt:'-5px'}}>
              <Typography variant='h6' align="left" sx={{fontSize:10, color:'#878787'}}>
                {currentContractData.floor_price? 'Ξ10.62' : 'Ξ10.62'}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Card>
    );
  }
};

export default ContractCard;