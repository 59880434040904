import React from 'react';
import { Box,  Card, Skeleton, CardMedia, CardContent, Stack, ButtonGroup, Button, Typography, Grid } from '@mui/material';
import { getNftData, getContractData } from './supaBaseFuncs';
import VerifiedIcon from '@mui/icons-material/Verified';
import OSwhite from './logos/OSwhite.svg';
import OSblack from './logos/OSblack.svg';
import ME from './logos/ME.svg';
import MEDark from './logos/MEDark.svg';
import Blur from './logos/Blur.svg';
import BlurDark from './logos/BlurDark.svg';

import ContractCard from './ContractCard';


import { useData } from './contexts/DataContext';



const NFTCard = ({ theme, mode, contractAddress, nftid, currImage, imageLoading}) => {

  return (
    <Card sx={{height:'65px', width:'100%', borderRadius:0, padding:0, paddingLeft:0, margin:0, boxShadow:0, background:'transparent'}}>
      <Stack
        direction="row"
        spacing={1}
        sx={{
            justifyContent: "flex-start",
            alignItems: "space-between",
        }}
        >
        {(currImage === 'placeholder' || imageLoading) ? (
          <Skeleton 
            variant="rectangular"
            sx={{
              borderRadius: '4px',
              height: '65px',
              width: '50px'
            }}
          />
        ) : (
          <Box
            component="img"
            src={currImage}
            alt=""
            sx={{
              borderRadius: '4px', 
              padding: 0, 
              margin: 0, 
              objectFit: "cover",
              height: '65px', 
              width: '50px',
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }}
          />
        )}
       
        <ContractCard
        contractAddress={contractAddress}
        cardMode='vertical'
        nftid={nftid}
        mode={mode}
        theme={theme}
        />
       </Stack>
    </Card>
    );
};

export default React.memo(NFTCard, (prev, next) => {
  return (
    prev.contractAddress === next.contractAddress &&
    prev.nftid === next.nftid &&
    prev.mode === next.mode &&
    prev.theme === next.theme &&
    prev.currImage === next.currImage &&
    prev.imageLoading === next.imageLoading
  );
});