import React from 'react';
import { Box,  Card, Link, CardMedia, CardContent, Stack, ButtonGroup, Button, Typography, Grid } from '@mui/material';
import { getNftData, getContractData } from './supaBaseFuncs';
import VerifiedIcon from '@mui/icons-material/Verified';
import ContractCard from './ContractCard';
import { ethers } from 'ethers';
import { convertBlocksToTime } from './Etherfuncs.js';

const OptionNameCard = ({ theme, mode, contractAddress, optionId, isCall, strike, expiry, block, userAccount,
  isOptionInactive = false
}) => {
  
  // Updated function to handle strike formatting using ethers v6
  const formatStrike = (strikeValue) => {
    try {
      // Convert the value to a number first
      const numericStrike = Number(strikeValue);
      if (isNaN(numericStrike)) {
        console.error('Invalid strike value:', strikeValue);
        return '0';
      }
      // Format the number to ether units
      return ethers.formatEther(String(numericStrike));
    } catch (error) {
      console.error('Error formatting strike:', error);
      return '0';
    }
  };

  const linkColor = isOptionInactive ? 'text.disabled' : (isCall ? mode==='light' ?
    theme.palette.primary.main : theme.palette.primary.light : theme.palette.secondary.main);

  return (
    <Card sx={{height:'60px', width:'100%', borderRadius:0, padding:0, paddingLeft:0, margin:0, mb:'2px', boxShadow:0, background:'transparent'
    }}>
      <Stack
        flexWrap="nowrap"
        direction="row"
        spacing={1}
        sx={{
            justifyContent: "flex-start",
            alignItems: "space-between",
        }}
        >
          <Card sx={{mr:'2px',height:'60px', width:'5px', borderRadius:'2px', boxShadow:1, background: isOptionInactive ? 'text.disabled' : (isCall? '#264a39':'#f44336')}}>
                <Typography sx={{fontSize:10,  textOrientation: 'sideways', writingMode: 'vertical-rl'}}>
                    {'  '}
                </Typography>
          </Card>
          <Stack flexWrap="nowrap" direction="column">
            <Typography sx={{fontSize:14, display:'flex', justifyContent:'left', alignItems:'center', color:isOptionInactive ? 'text.disabled' : 'inherit'}}>
              {isCall?'CALL':'PUT'} @ {formatStrike(strike)}
            </Typography>

              <Typography sx={{fontSize:8, display:'flex', justifyContent:'left', alignItems:'center',color:isOptionInactive ? 'text.disabled' : 'inherit'}}>
                <Link target="_blank" href={`https://sepolia.etherscan.io/address/${contractAddress}`}
                sx={{color:linkColor}}>
                  {String(contractAddress).slice(0,10)+'...'+String(contractAddress).slice(30,45)}
                </Link>
            </Typography>
            
            {Number(expiry) > block ?
            <Typography sx={{fontSize:10, display:'flex', justifyContent:'left', alignItems:'center'}}>
              Expires in <Link target="_blank" href={`https://sepolia.etherscan.io/block/${expiry}`} 
              sx={{ml:1, color:linkColor}}> {convertBlocksToTime(Number(expiry) - block)} </Link> 
            </Typography>
            :
            <Typography sx={{fontSize:10, display:'flex', justifyContent:'left', alignItems:'center'}}>
              Expired at block <Link target="_blank" href={`https://sepolia.etherscan.io/block/${expiry}`} 
              sx={{ml:1, color:linkColor}}> {expiry}  </Link> 
            </Typography>
            }
            
          </Stack>
       </Stack>
    </Card>
    );
};

export default OptionNameCard;