import React from 'react';
import {Modal, Link, Grid, Stack, Box, Card, CardContent, Button, createTheme, ThemeProvider, Divider } from '@mui/material';
import { Typography, CssBaseline, Container} from '@mui/material';
import { ethers } from 'ethers';
import { getOptionsByOwner, getOptionsListedByOwner,getActiveOrderIdByOptionId } from './supaBaseFuncs';

import OSwhite from './logos/OSwhite.svg';
import OSblack from './logos/OSblack.svg';
import ME from './logos/ME.svg';
import MEDark from './logos/MEDark.svg';
import Blur from './logos/Blur.svg';
import BlurDark from './logos/BlurDark.svg';

import { convertBlocksToTime } from './Etherfuncs';
import { useData } from './contexts/DataContext';


import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 250,
    borderRadius: 1,
    boxShadow: 0,
    bgcolor: "transparent",
    p: 1,
    outline: "none",
};

export function SelectNFT(props) {
    const {
        theme,
        mode,
        toggleTabBar,
        setModalSelectedNFT,
        userAccount,
        switchPC,
        modalSelectedNFT,
        filterContract,
        filterStrike,
        filterIsCall,
        filterExpiry,
        orderId,
        open,
        setOpen,
        onOptionDataLoaded,
        allowFilterOverride = false,
        hasImplicitContext = false,
    } = props;

    const { currentBlock } = useData(); 
    const [myOptions, setMyOptions] = React.useState([]);
    const [myOptionsMarket, setMyOptionsMarket] = React.useState([]);
    
    const [filterOverridden, setFilterOverridden] = React.useState(false);

    React.useEffect(() => {
        async function getOp() {
            // Only fetch options if we have a valid userAccount
            if (!userAccount || !ethers.isAddress(userAccount)) {
                console.log("No valid userAccount provided, skipping options fetch");
                setMyOptions([]);
                setMyOptionsMarket([]);
                return;
            }

            console.log("getting options for userAccount", userAccount);
            try {
                const ops = await getOptionsByOwner(userAccount);
                const mops = await getOptionsListedByOwner(userAccount);
                console.log("mops", ops, mops);
                
                // Filter out undefined or null options
                const filteredOps = ops.filter(option => option !== undefined && option !== null);

                if (ops.length !== filteredOps.length) {
                    console.warn("Warning: Some options were undefined or null and have been filtered out.");
                }

                setMyOptions(filteredOps);
                setMyOptionsMarket(mops);
                console.log("mops", filteredOps, mops);
            } catch (error) {
                console.error("Error fetching options:", error);
                setMyOptions([]);
                setMyOptionsMarket([]);
            }
        }
        getOp();
    }, [filterContract, filterStrike, filterExpiry, userAccount]);

    async function handleSelectNFT(_orderId, optionId, e) {
        console.log("handleSelectNFT called with:", {_orderId, optionId});
        
        // Find the complete option details from myOptions or myOptionsMarket
        const selectedOption = myOptions.find(opt => opt.optionId === optionId) || 
                              myOptionsMarket.find(opt => opt.optionId === optionId);
        
        if (selectedOption) {
            // Format the option data consistently
            const formattedOption = {
                optionId: selectedOption.optionId,
                strike: selectedOption.strike,
                isCall: selectedOption.isCall,
                expiry: selectedOption.expiry,
                underlyingId: selectedOption.underlyingId,
                contractAddress: selectedOption.contractAddress
            };
             // Call onOptionDataLoaded first with complete data
            if (onOptionDataLoaded) {
                console.log("Calling onOptionDataLoaded with formatted data:", formattedOption);
                onOptionDataLoaded(formattedOption);
            }
             // Then update the modal selection state
            if (_orderId === null) {
                setModalSelectedNFT([optionId]);
            } else {
                setModalSelectedNFT({
                    ...modalSelectedNFT,
                    [_orderId]: optionId
                });
            }
        } else {
            console.error("Could not find option details for optionId:", optionId);
        }
        
        setOpen(null);
    }
    
   
    
    // Remove the selection clearing from handleClose
    const handleClose = () => {
        setOpen(null);
    };

    // Get unique contract addresses with null checks
    const uniqueContracts = [...new Set((myOptions || []).map(option => option?.contractAddress).filter(Boolean))];
    const uniqueContractsMarket = [...new Set((myOptionsMarket || []).map(option => option?.contractAddress).filter(Boolean))];

    // Update getOptionsForDisplay to handle null/undefined
   const getOptionsForDisplay = (options = [], contractAddress = null) => {
    if (!Array.isArray(options)) return [];
   
    // First filter out expired options
    const validOptions = options.filter(opt => {
        if (!opt || !opt.expiry || !currentBlock) return false;
        return Number(opt.expiry) > Number(currentBlock);
    });
    
    // Then apply contract filter if needed
    const filteredOptions = contractAddress 
        ? validOptions.filter(opt => opt?.contractAddress === contractAddress)
        : validOptions;
    
    // Sort the options
    return filteredOptions.sort((a, b) => {
        // First sort by call/put (calls first)
        if (a.isCall !== b.isCall) {
            return b.isCall - a.isCall; // true (1) before false (0)
        }
        
        // Then sort by expiry (ascending)
        if (Number(a.expiry) !== Number(b.expiry)) {
            return Number(a.expiry) - Number(b.expiry);
        }
        
        // Finally sort by strike (descending)
        return Number(ethers.formatEther(String(b.strike))) - Number(ethers.formatEther(String(a.strike)));
    });
   };

    // Update optionMatchesCriteria to handle null/undefined
    const optionMatchesCriteria = (option) => {
        if (!option || !currentBlock) return false;
   
        // Check if option is expired (this check should always apply)
        if (Number(option.expiry) <= Number(currentBlock)) return false;
         if (allowFilterOverride && filterOverridden) return true;
         
         return (
            // Changed this line to make filterIsCall permissive by default
            (filterIsCall === null || filterIsCall === undefined || option.isCall === filterIsCall) &&
            (!filterStrike || String(ethers.formatEther(String(option.strike))) === String(filterStrike)) &&
            (!filterExpiry || filterExpiry <= option.expiry)
        );
    };

    // Create a separate ContractHeader component
    const ContractHeader = ({ contractAddress }) => (
        <Typography style={{fontSize: 12, display: 'flex', alignItems: 'center', justifyContent:'space-between', marginBottom: '8px'}}>
            Contract
            <Link href={`https://opensea.io/assets/ethereum/${contractAddress}`} target="_blank" rel="noopener noreferrer" style={{marginLeft:'4px'}}>
                {String(contractAddress).slice(0, 6)}...{String(contractAddress).slice(-6)}
            </Link>

            <Link href={`https://opensea.io/assets/ethereum/${contractAddress}`} target="_blank" rel="noopener noreferrer">
                <img src={mode === 'light' ? OSblack : OSwhite} alt="OpenSea" style={{width: '16px', height: '16px', marginLeft:'10px', marginRight: '4px', marginTop:'2px'}} />
            </Link>

            <Link href={`https://blur.io/eth/collection/${contractAddress}`} target="_blank" rel="noopener noreferrer">
                <img src={mode === 'dark' ? Blur : BlurDark} alt="Blur" style={{width: '16px', height: '16px', marginRight: '4px', marginTop:'2px'}} />
            </Link>

            <Link href={`https://magiceden.io/collections/ethereum/${contractAddress}`} target="_blank" rel="noopener noreferrer">
                <img src={mode === 'dark' ? ME : MEDark} alt="Magic Eden" style={{width: '16px', height: '16px', marginTop:'2px'}} />
            </Link>
        </Typography>
    );

    // Modify OptionsListForContract to remove the header
    const OptionsListForContract = ({ options }) => (
        <div>
            {options.map((e, index) => {
                const blocksUntilExpiry = Number(e.expiry) - Number(currentBlock);
                const isNearExpiry = blocksUntilExpiry < 7200; // Less than ~24 hours left
                const timeLeft = convertBlocksToTime(blocksUntilExpiry);

                 return (
                <Grid item key={index}>
                    <Stack noWrap direction="row" sx={{display:'flex', justifyContent:'space-between'}}>
                        <Box sx={{
                            fontSize: 10,
                            padding: 0.3,
                            margin: 0.1,
                            border: 1,
                            borderRadius: 0.6,
                            minWidth: '25px',
                            maxWidth: '45px',
                            maxHeight: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center'
                        }}>
                            {e.optionId !== null && e.optionId !== undefined ? `#${e.optionId}` : 'N/A'}
                        </Box>
                        <Typography style={{ fontSize: 12,
                            color: isNearExpiry ? 'warning.main' : 'text.primary'
                         }}>
                            <span style={{ 
                                color: filterIsCall !== null ? 
                                    (filterIsCall === e.isCall ? 'text.primary' : 'grey') 
                                    : 'text.primary' 
                            }}>
                                {e.isCall ? 'Call' : 'Put'}
                            </span>
                            {' @ '}
                            <span style={{ 
                                color: filterStrike ? 
                                    (String(filterStrike) === String(ethers.formatEther(String(e.strike))) ? 'text.primary' : 'grey')
                                    : 'inherit'
                            }}>
                                {String(ethers.formatEther(String(e.strike)))}
                            </span>
                            {' / '}
                            <span style={{ 
                                   color: isNearExpiry ? 'warning.main' : (
                                       filterExpiry ? 
                                           (filterExpiry <= e.expiry ? 'inherit' : 'grey')
                                           : 'inherit'
                                   )
                               }}>
                                {e.expiry}
                            </span>
                        </Typography>

                        <Button 
                            sx={{fontSize:10, padding:0, margin:0.3, borderRadius:0.5}}
                            onClick={() => handleSelectNFT(orderId, e.optionId, e)}
                            disabled={!optionMatchesCriteria(e)}
                        >
                            Select
                        </Button>
                    </Stack>
                    <Divider />
                </Grid>
            )})}
        </div>
    );

    // Update the renderOptionsSection
    const renderOptionsSection = (options, uniqueContracts, sectionTitle) => (
        <>
            <Typography style={{fontSize:14, fontWeight: 'bold', marginTop: '16px'}}>
                {sectionTitle}
            </Typography>
            <Divider />
            
            {filterContract ? (
                <OptionsListForContract 
                    options={getOptionsForDisplay(options)}
                />
            ) : (
                uniqueContracts.map((contractAddress, index) => (
                    <div key={contractAddress}>
                        {index > 0 && <Divider style={{margin: '16px 0'}} />}
                        <Typography style={{fontSize:10, fontWeight: 'bold', marginTop: '16px'}}>
                            {contractAddress}
                        </Typography>
                        <OptionsListForContract 
                            options={getOptionsForDisplay(options, contractAddress)}
                        />
                    </div>
                ))
            )}
        </>
    );

    return (
        <div>
            <Modal
                open={Boolean(open)}  // Convert to boolean
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{
                    style: {
                      backgroundColor: 'rgba(0, 0, 0, 0.5)'  // Semi-transparent backdrop
                    }
                  }}
            >
                <Box  style={{
                        minWidth:'300px', 
                        maxWidth:'90%', 
                        maxHeight:'80vh', 
                        overflowY:'auto',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'transparent', //theme?.palette?.background?.paper || '#fff',  // Add fallback color
                        boxShadow: `0px 4px 24px ${theme.palette.primary.light}20`,
                        p: 0,
                        m:0
                        }} 
                        sx={style}>
                    <Card style={{maxWidth:'100%', boxShadow: `0px 4px 24px ${theme.palette.primary.light}40`,}}>
                        <CardContent>
                            {myOptions.length === 0 && myOptionsMarket.length === 0 ? (
                                <div>
                                    <Typography>
                                        You don't own any options - Write a new option
                                    </Typography>
                                </div>
                            ) : (
                                <div>
                                    <Typography style={{fontSize:14, fontWeight: 'bold'}}>
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                                <span>Select option</span>
                                                {(filterStrike || filterIsCall !== null || filterExpiry) && (
                                                    <span style={{color: 'grey', fontSize: 10}}>
                                                        {filterStrike && `${filterStrike} `}
                                                        {filterIsCall !== null && (filterIsCall ? 'CALL' : 'PUT')}
                                                        {filterExpiry && ` ${filterExpiry}`}
                                                    </span>
                                                )}
                                            </div>
                                                {allowFilterOverride && hasImplicitContext && (
                                                <Tooltip title={filterOverridden ? "All" : "Matching"}>
                                                    <IconButton 
                                                        size="small" 
                                                        onClick={() => setFilterOverridden(!filterOverridden)}
                                                        sx={{ 
                                                            color: filterOverridden ? 'warning.main' : 'text.secondary',
                                                            marginLeft: 1
                                                        }}
                                                    >
                                                        {filterOverridden ? <LockOpenIcon fontSize="small" /> : <LockIcon fontSize="small" />}
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </Typography>
                                    <Divider />
                                    
                                    {/* Show contract header first */}
                                    {filterContract ? (
                                        <ContractHeader contractAddress={filterContract} />
                                    ) : (
                                        uniqueContracts.length > 0 && <ContractHeader contractAddress={uniqueContracts[0]} />
                                    )}
                                    
                                    {myOptions.length > 0 && renderOptionsSection(
                                        myOptions,
                                        uniqueContracts,
                                        "Held Options"
                                    )}

                                    {myOptionsMarket.length > 0 && renderOptionsSection(
                                        myOptionsMarket,
                                        uniqueContractsMarket,
                                        "Listed Options"
                                    )}

                                    {(filterContract && 
                                     !myOptions.some(opt => opt.contractAddress === filterContract) && 
                                     !myOptionsMarket.some(opt => opt.contractAddress === filterContract)) || 
                                     (myOptions.length > 0 && !myOptions.some(opt => optionMatchesCriteria(opt)) &&
                                     myOptionsMarket.length > 0 && !myOptionsMarket.some(opt => optionMatchesCriteria(opt))) ? (
                                        <div>
                                            <Typography style={{fontSize: 12, marginTop: 1}}>
                                                You don't own any matching options for this contract
                                            </Typography>
                                            <Button 
                                                variant="outlined" 
                                                size="small" 
                                                onClick={() => toggleTabBar(0)}
                                                sx={{color:'text.primary', borderColor:'text.primary', width:'100%'}}
                                            >
                                                Write option
                                            </Button>
                                        </div>
                                    ) : null}
                                </div>
                            )}
                        </CardContent>
                    </Card>
                </Box>
            </Modal>
        </div>
    );
}

export default SelectNFT;