import * as React from 'react';
import {Link, Box, IconButton, Card, CardContent, Stack, Divider, Grid, Button, Typography, ButtonGroup, LinearProgress} from '@mui/material';
import { ethers } from 'ethers';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import Slider from '@mui/material/Slider';
import { SelectNFT } from './OrderViewSelectNFT.js';
import GreeksCard from './greeksCard.js';
import NFTCard from './NFTCard.js';
import OptionNameCard from './OptionNameCard.js';
import FormattedPrice from './FormattedPrice';
import { convertBlocksToTime } from './Etherfuncs.js';

const OrderViewCard = React.memo(({ 
    order: e,  // Rename order to e for compatibility
    theme,
    mode,
    switchPC,
    userAccount,
    curr,
    handleVisibilityClick,
    showOnlyMatching,
    bestMatchingPrice,
    userMatchingOrder,
    effectivelyCancelledMap,
    handleAcceptOrder,
    handleCancelOrder,
    cancelLoading,
    cancelStatus,
    toggleTabBar,
    handleExtendOffer,
    extendOrderId,
    setExtendOrderId,
    extendBlocks,
    setExtendBlocks,
    extendLoading,
    extendStatus,
    setExtendStatus,
    MyOrders,
    modalSelectedNFT,
    setModalSelectedNFT,
    openModalMap,
    setOpenModalMap,
    currImage,
    imageLoading
  }) => {
    return (
      <Grid item xs={12} sm={10} md={6} style={{ padding: 3, marginBottom:10, minWidth:'190px'}}>
        <Card style={{backgroundImage: theme.custom.gradient1, padding:2, height:'100%'}}>
          <Typography variant='h5' align="left" color={(e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  
              Order #{String(e._orderId)}
          </Typography>
          
          <Divider textAlign="left" color={(e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>
              <Typography variant='body2' align="left" sx={{fontSize:18}} color={(e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  
                  {!e.isOffer ? 'Bid to buy' : 'Offer to sell'} 
              </Typography>
          </Divider>
  
          <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant='body2' align="left" color={(e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>
                  Price:
              </Typography>
              <FormattedPrice 
                  price={e.price} 
                  isBid={!e.isOffer}
                  myOrder={String(e.orderCreator).toLowerCase() === String(userAccount).toLowerCase()}
                  isSpecific={!e.isGeneral}
                  specificOptionId={e.optionId}
                  variant="body2"
                  showLabels={false}
                  showOptionLink={false}
                  disabled={(e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1)}
                  toggleTabBar={toggleTabBar}
              />
          </Stack>
  
          <Typography 
              gutterBottom 
              variant='body2' 
              align="left" 
              sx={{fontSize: 10}} 
              color={(e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}
          >  
              Order valid for <Link 
                  href={`https://sepolia.etherscan.io/block/${e.validUntil}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  color={mode == "light" ? "primary.dark" : "primary.light"}
                  underline="hover"
              >
                  {convertBlocksToTime(Number(e.validUntil) - curr)} 
              </Link>
          </Typography>
  
          <Typography gutterBottom variant='body2' align="left" color={(e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}
          sx={{fontSize: 10}} >
              Creator: 
              <Link 
                  href={String(e.orderCreator).toLowerCase() === String(userAccount).toLowerCase() ? '#' : `https://etherscan.io/address/${e.orderCreator}`}
                  color={mode == "light" ? "primary.dark" : "primary.light"}
                  underline="hover"
              >
                  {String(e.orderCreator).toLowerCase() === String(userAccount).toLowerCase() ? ' You' : ` ${e.orderCreator.slice(0, 6)}...${e.orderCreator.slice(-4)}`}
              </Link>
          </Typography>
  
          {/* Status messages */}
          {e.isFilled ? (
              <Typography gutterBottom variant='body' align="left" color='primary'> 
                  Order Filled 
              </Typography>
          ) : e.isCancelled ? (
              <Typography gutterBottom variant='body' align="left" color="secondary"> 
                  Order Cancelled 
              </Typography>
          ) : effectivelyCancelledMap[e._orderId] ? (
              <Typography gutterBottom variant='body' align="left" color="secondary"> 
                  Option Not Available 
              </Typography>
          ) : ((Number(e.validUntil) - curr) < 1) ? (
              <Typography gutterBottom variant='body' align="left" color="secondary"> 
                  Order Expired 
              </Typography>
          ) : null}
  
          {(e.isFilled | e.isCancelled) ? null : <br/>}
  
          {/* Option details */}
          <Divider textAlign="left" color={(e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}> 
              {e.isGeneral ? (
                  <Typography variant='body' align="left" style={{fontSize:10}} color={(e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  
                      Any option matching:
                  </Typography>
              ) : (
                  <Typography variant='body' align="left" color={(e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  
                      <Link
                          component="button"
                          variant="body2"
                          onClick={(event) => {
                              event.preventDefault();
                              window.directLinkNavigation = true;
                              setTimeout(() => {
                                  window.location.hash = `options#${e.optionId}`;
                                  toggleTabBar(1);
                              }, 0);
                          }}
                          color={(e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}
                          underline="hover"
                          sx={{ 
                              cursor: 'pointer',
                              '&:hover': {
                                  textDecoration: 'underline'
                              }
                          }}
                      >
                          {`Option #${String(e.optionId)}`}
                      </Link>
                  </Typography>
              )}
          </Divider>
  
          {/* Option details cards */}
          <Stack direction="column" sx={{justifyItems:'left', alignItems:'flex-start'}}>
              <OptionNameCard
                  theme={theme}
                  mode={mode}
                  contractAddress={e.underlyingContract}
                  optionId={e.optionId}
                  isCall={e.isCall}
                  strike={ethers.parseEther(String(e.strike))}
                  expiry={e.expiry}
                  block={curr}
                  userAccount={userAccount}
              />
  
              <NFTCard
                  contractAddress={e.underlyingContract}
                  nftid={(e.isGeneral || !e.isCall) ? 'any' : e.underlyingId}
                  currImage={currImage}
                  imageLoading={imageLoading}
              />
  
              <GreeksCard
                  theme={theme}
                  mode={mode}
                  contractAddress={e.underlyingContract}
                  price={Number(ethers.formatEther(String(e.price)))}
                  isCall={e.isCall}
                  strike={e.strike}
                  expiry={e.expiry}
                  block={curr}
              />
          </Stack>
  
          <Divider/>
  
          {/* Best match price */}
          <Stack direction="row" alignItems="center" spacing={1}>
              <Typography 
                  variant='body2' 
                  align="left" 
                  color={(e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"} 
                  sx={{fontSize: 12}}
              >    
                  {e.isOffer ? 'Best Bid: ' : 'Best Offer: '}
              </Typography>
              <FormattedPrice 
                  price={bestMatchingPrice || '-'}
                  isBid={e.isOffer}
                  myOrder={false}
                  isSpecific={false}
                  specificOptionId={null}
                  variant="body2"
                  fontSize={12}
                  showLabels={false}
                  showOptionLink={false}
                  disabled={(e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1)}
              />
              {bestMatchingPrice && (
                  <IconButton 
                      size="small"
                      onClick={() => handleVisibilityClick(e._orderId)}
                      sx={{ 
                          padding: 0,
                          color: showOnlyMatching === e._orderId ? 'primary.main' : 'text.secondary'
                      }}
                  >
                      <VisibilityIcon sx={{ fontSize: 16 }} />
                  </IconButton>
              )}
               
          </Stack>
          {/* Show if there's a matching user order */}
          {userMatchingOrder && (
               <Typography variant="body2" color="primary" fontSize={10}>
                   Matching {e.isOffer ? 'Bid' : 'Offer'} available
               </Typography>
           )}
  
          {/* Action buttons */}
          {String(e.orderCreator).toLowerCase() !== String(userAccount).toLowerCase() && (
              e.isGeneral ? (
                  <ButtonGroup sx={{width:'100%'}}>
                      <>
                      <Button variant="outlined" size="small"
                          sx={{width:'100%', marginBottom:1, marginTop:'auto', fontSize:10,
                            color: switchPC === 'Call' ? 
                            (mode==="light" ? theme.palette.primary.main : theme.palette.primary.light) : 'secondary',
                            borderColor: switchPC === 'Call' ? 
                            (mode==="light" ? theme.palette.primary.main : theme.palette.primary.light) : 'secondary'
                          }}
                          onClick={() => setOpenModalMap(prev => ({...prev, [e._orderId]: true}))}
                          disabled={e.isFilled | 
                                   e.isCancelled | 
                                   effectivelyCancelledMap[e._orderId] ||
                                   (Number(e.validUntil) - curr) < 1 |
                                   !userAccount
                          }
                      >
                          {modalSelectedNFT[e._orderId] ?
                              `OPT#${modalSelectedNFT[e._orderId]}` :
                              'Select'
                          }
                      </Button>
                      <SelectNFT
                          setModalSelectedNFT={setModalSelectedNFT}
                          modalSelectedNFT={modalSelectedNFT}
                          userAccount={userAccount}
                          filterContract={e.underlyingContract}
                          filterIsCall={e.isCall}
                          filterStrike={e.strike}
                          filterExpiry={e.expiry}
                          orderId={e._orderId}
                          theme={theme}
                          mode={mode}
                          toggleTabBar={toggleTabBar}
                          setOpen={(value) => setOpenModalMap(prev => ({...prev, [e._orderId]: value}))}
                          open={openModalMap[e._orderId]}
                      />
                      </>
  
                      <Button variant="contained" size="small"
                          color={switchPC === 'Call' ? 'primary': 'secondary'}
                          sx={{width:'100%', marginBottom:1, marginTop:'auto', fontSize:10,
                            backgroundColor: switchPC === 'Call' ? 
                            (mode==="light" ? theme.palette.primary.main : theme.palette.primary.light) : 'secondary',
                            borderColor: switchPC === 'Call' ? 
                            (mode==="light" ? theme.palette.primary.main : theme.palette.primary.light) : 'secondary'
                          }}
                          onClick={() => handleAcceptOrder(e._orderId, modalSelectedNFT[e._orderId])}
                          disabled={!userAccount || !modalSelectedNFT[e._orderId] 
                              || e.isFilled 
                              || e.isCancelled 
                              || effectivelyCancelledMap[e._orderId] ||
                              (Number(e.validUntil) - curr) < 1 || 
                              (String(e.orderCreator).toLowerCase() === String(userAccount).toLowerCase())
                          }
                      >
                          Accept bid
                      </Button>
                  </ButtonGroup>
              ) : (
                  <Button variant="contained" size="small"
                      sx={{width:'100%', marginBottom:1, marginTop:'auto'
                      }}
                      onClick={() => handleAcceptOrder(e._orderId)}
                      disabled={!userAccount || 
                          e.isFilled || 
                          e.isCancelled || 
                          effectivelyCancelledMap[e._orderId] ||
                          (Number(e.validUntil) - curr) < 1 ||
                          (String(e.orderCreator).toLowerCase() === String(userAccount).toLowerCase()) ||
                          (!e.isOffer && !MyOrders.some(order => 
                              order.optionId === e.optionId && 
                              order.orderCreator.toLowerCase() === userAccount.toLowerCase()
                          ))
                      }
                  >
                      Accept {e.isOffer ? 'offer' : 'bid'}
                  </Button>
              )
          )}
  
          {/* Extension and cancellation controls */}
          {userAccount && 
           e.orderCreator.toLowerCase() === userAccount.toLowerCase() && 
           e.isOffer && 
           !e.isFilled && 
           !e.isCancelled && 
           !effectivelyCancelledMap[e._orderId] &&
           ((Number(e.expiry) - Number(e.validUntil)) > 2000) ? (
              extendOrderId === e._orderId ? (
                  <Stack sx={{ width: '100%' }}> 
                      <Box sx={{ 
                          px: 2,  
                          py: 1, 
                          width: '100%'
                      }}>
                         <Slider
                            value={Number(extendBlocks) || Math.floor((Math.max(201, e.validUntil - curr + 1) + (e.expiry - curr - 200)) / 2)}
                            onChange={(_, newValue) => setExtendBlocks(String(newValue))}
                            min={Math.max(201, e.validUntil - curr + 1)}
                            max={e.expiry - curr - 500}
                            disabled={extendLoading[e._orderId]}
                            valueLabelFormat={(value) => convertBlocksToTime(value)}
                            valueLabelDisplay="auto"
                            size="small"
                            marks={[
                                {
                                    value: Math.max(201, e.validUntil - curr + 1),
                                    label: convertBlocksToTime(Math.max(201, e.validUntil - curr + 1))
                                },
                                {
                                    value: e.expiry - curr - 200,
                                    label: convertBlocksToTime(e.expiry - curr - 200)
                                }
                            ]}
                            sx={{
                                color: switchPC === 'Call' ? 
                                (mode==="light" ? theme.palette.primary.main : theme.palette.primary.light) : 'secondary',
                                borderColor: switchPC === 'Call' ? 
                                (mode==="light" ? theme.palette.primary.main : theme.palette.primary.light) : 'secondary',
                                '& .MuiSlider-markLabel[data-index="0"]': {
                                    paddingLeft: '20px',
                                    paddingRight: '-20px'
                                },
                                '& .MuiSlider-markLabel[data-index="1"]': {
                                    paddingRight: '20px'
                                }    
                            }}
                        />
                      </Box>
                      
                      <ButtonGroup 
                          variant="outlined" 
                          fullWidth
                          sx={{
                            color: switchPC === 'Call' ? 
                            (mode==="light" ? theme.palette.primary.main : theme.palette.primary.light) : 'secondary',
                            borderColor: switchPC === 'Call' ? 
                            (mode==="light" ? theme.palette.primary.main : theme.palette.primary.light) : 'secondary'
                        }}
                      >
                          <Button
                              sx={{ width: '85%',
                                color: switchPC === 'Call' ? 
                            (mode==="light" ? theme.palette.primary.main : theme.palette.primary.light) : 'secondary',
                            borderColor: switchPC === 'Call' ? 
                            (mode==="light" ? theme.palette.primary.main : theme.palette.primary.light) : 'secondary'
                               }}
                              onClick={() => handleExtendOffer(e._orderId)}
                              disabled={extendLoading[e._orderId]}
                          >
                              {extendLoading[e._orderId] ? 'EXTENDING...' : 'CONFIRM'}
                          </Button>
                          <Button
                              sx={{ width: '15%',
                                color: switchPC === 'Call' ? 
                            (mode==="light" ? theme.palette.primary.main : theme.palette.primary.light) : 'secondary',
                            borderColor: switchPC === 'Call' ? 
                            (mode==="light" ? theme.palette.primary.main : theme.palette.primary.light) : 'secondary'
                               }}
                              onClick={() => {
                                  setExtendOrderId(null);
                                  setExtendBlocks('');
                                  setExtendStatus(prev => {
                                      const newStatus = { ...prev };
                                      delete newStatus[e._orderId];
                                      return newStatus;
                                  });
                              }}
                          >
                              <CloseIcon fontSize="small" />
                          </Button>
                      </ButtonGroup>
                  </Stack>
              ) : (
                  <ButtonGroup 
                      variant="outlined" 
                      size="small" 
                      fullWidth
                      sx={{
                        color: switchPC === 'Call' ? 
                        (mode==="light" ? theme.palette.primary.main : theme.palette.primary.light) : 'secondary',
                        borderColor: switchPC === 'Call' ? 
                        (mode==="light" ? theme.palette.primary.main : theme.palette.primary.light) : 'secondary'
                    }}
                  >
                      <Button 
                          onClick={() => setExtendOrderId(e._orderId)}
                          disabled={extendLoading[e._orderId]}
                          sx={{
                            color: switchPC === 'Call' ? 
                            (mode==="light" ? theme.palette.primary.main : theme.palette.primary.light) : 'secondary',
                            borderColor: switchPC === 'Call' ? 
                            (mode==="light" ? theme.palette.primary.main : theme.palette.primary.light) : 'secondary'
                        }}
                      >
                          Extend
                      </Button>
                      <Button 
                          onClick={() => handleCancelOrder(e._orderId, e.isOffer)}
                          disabled={cancelLoading[e._orderId]}
                          sx={{
                            color: switchPC === 'Call' ? 
                            (mode==="light" ? theme.palette.primary.main : theme.palette.primary.light) : 'secondary',
                            borderColor: switchPC === 'Call' ? 
                            (mode==="light" ? theme.palette.primary.main : theme.palette.primary.light) : 'secondary'
                        }}
                      >
                          Cancel
                      </Button>
                  </ButtonGroup>
              )
          ) : (
              <Button 
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    color: switchPC === 'Call' ? 
                    (mode==="light" ? theme.palette.primary.main : theme.palette.primary.light) : 'secondary',
                    borderColor: switchPC === 'Call' ? 
                    (mode==="light" ? theme.palette.primary.main : theme.palette.primary.light) : 'secondary'
                }}
                  onClick={() => handleCancelOrder(e._orderId, e.isOffer)}
                  disabled={!userAccount || 
                           e.isFilled || 
                           e.isCancelled || 
                           effectivelyCancelledMap[e._orderId] ||
                           e.orderCreator.toLowerCase() !== userAccount.toLowerCase() || 
                           cancelLoading[e._orderId]}
              >
                  Cancel
              </Button>
          )}
  
          {/* Loading and status indicators */}
          {cancelLoading[e._orderId] && (
              <Box sx={{ width: '100%', mt: 1 }}>
                  <LinearProgress color={switchPC === 'Call' ? 'primary' : 'secondary'} />
              </Box>
          )}
  
          {(cancelStatus[e._orderId] || extendStatus[e._orderId]) && (
              <Typography 
                  variant="caption" 
                  color={
                      cancelStatus[e._orderId] ? 
                          (cancelStatus[e._orderId].includes('successfully') ? 'success.main' : 'error.main')
                          : (extendStatus[e._orderId]?.error ? 'error.main' : 'success.main')
                  }
                  sx={{ mt: 1, display: 'block', textAlign: 'center' }}
              >
                  {cancelStatus[e._orderId] || extendStatus[e._orderId]?.message}
              </Typography>
          )}
        </Card>
      </Grid>
    );
  });

  
export default OrderViewCard;