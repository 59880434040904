import * as React from 'react';
import { Typography, Container, Grid, TextField, Button, Snackbar, Alert   } from '@mui/material';
import { ethers } from 'ethers';
import BAYC from './artifacts/contracts/FakeBAYC.sol/BAYC.json';
import Option from './artifacts/contracts/ERC721Option.sol/ERC721Option.json';
import Marketplace from './artifacts/contracts/Marketplace.sol/Marketplace.json';
import { ZtrikeAddress, NFTAddress, MarketplaceAddress } from './ethContracts';
import { Link } from 'react-router-dom'; // Add this import
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Add this import


const INFURA_URL = process.env.REACT_APP_INFURA_URL;

export function ExtraMenu(props) {
  const { wallet, fakeBaycAddress, erc721OptionAddress, setOption } = props;

  // Add state for notifications
  const [notification, setNotification] = React.useState({
    open: false,
    message: '',
    severity: 'info'
  });

  // State for NFT transfer
  const [nftContractAddress, setNftContractAddress] = React.useState('');
  const [tokenId, setTokenId] = React.useState('');
  const [recipientAddress, setRecipientAddress] = React.useState('');
  const handleBackToApp = () => {
    window.location.hash = ''; // This will remove the #admin hash
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const showNotification = (message, severity = 'info') => {
    setNotification({
      open: true,
      message,
      severity
    });
  };

  // New NFT transfer function with better error handling
  async function transferNFT() {
    if (!wallet) {
      showNotification('Please connect your wallet first', 'error');
      return;
    }

    if (!nftContractAddress) {
      showNotification('NFT Contract Address is required', 'error');
      return;
    }

    if (!tokenId) {
      showNotification('Token ID is required', 'error');
      return;
    }

    if (!recipientAddress) {
      showNotification('Recipient Address is required', 'error');
      return;
    }

    try {
      const [provider, signer, account] = await getProvider();
      
      const minABI = [
        "function safeTransferFrom(address from, address to, uint256 tokenId)"
      ];
      
      const nftContract = new ethers.Contract(nftContractAddress, minABI, signer);
      
      showNotification('Initiating transfer...', 'info');
      
      const transaction = await nftContract.safeTransferFrom(
        account,
        recipientAddress,
        tokenId
      );
      
      showNotification('Waiting for transaction confirmation...', 'info');
      await transaction.wait();
      
      showNotification(`NFT ${tokenId} successfully transferred to ${recipientAddress}`, 'success');
      
      // Clear form after successful transfer
      setNftContractAddress('');
      setTokenId('');
      setRecipientAddress('');
    } catch (error) {
      console.error("Transfer failed:", error);
      showNotification(
        `Transfer failed: ${error.message || 'Unknown error'}`, 
        'error'
      );
    }
  }


  async function getProvider() {
    if (wallet) {
      let account = wallet.accounts[0].address;
      const provider = new ethers.BrowserProvider(wallet.provider, 'any')
      const signer = await provider.getSigner()
      return [provider, signer, account];
    }
  }

  // Existing functions
  async function safeMint() {
    if (wallet) {
      const [provider, signer, account] = await getProvider();
      const contract = new ethers.Contract(NFTAddress, BAYC.abi, signer);
      const transation = await contract.safeMint(account);
      await transation.wait();
      console.log(`BAYC minted successfully sent to ${account}`);
    }
  }

  async function setContractAddressOPT() {
    if (wallet) {
      const [provider, signer, account] = await getProvider();
      const contract = new ethers.Contract(MarketplaceAddress, Marketplace.abi, signer);
      const contractP = new ethers.Contract(MarketplaceAddress, Marketplace.abi, provider);
      const currentContract = await contractP.OptionContractAddress();
      if (ZtrikeAddress !== currentContract) {
        const transation = await contract.setOptionContractAddress(ZtrikeAddress);
        await transation.wait();
        console.log(`Marketplace: Option contract successfully set to ${ZtrikeAddress}`);
      }
    }
  }

  async function setContractAddressMKT() {
    if (wallet) {
      const [provider, signer, account] = await getProvider();
      console.log("MP trying 0");
      const contract = new ethers.Contract(ZtrikeAddress, Option.abi, signer);
      const contractP = new ethers.Contract(ZtrikeAddress, Option.abi, provider);
      const currentContract = await contractP.MarketplaceAddress();

      console.log(currentContract, "??", MarketplaceAddress);
      if (MarketplaceAddress !== currentContract) {
        console.log(`MP trying 1 ${MarketplaceAddress}`);
        const transation = await contract.setMarketplaceAddress(MarketplaceAddress);
        console.log("MP trying 2");
        await transation.wait();
        console.log(`Option: Marketplace contract successfully set to ${MarketplaceAddress}`);
      } else {
        console.log("MP: they are the same");
      }
    }
  }

  // New NFT transfer function
  async function transferNFT() {
    if (!wallet || !nftContractAddress || !tokenId || !recipientAddress) {
      console.log("Missing required parameters");
      return;
    }

    try {
      const [provider, signer, account] = await getProvider();
      
      const minABI = [
        "function safeTransferFrom(address from, address to, uint256 tokenId)"
      ];
      
      const nftContract = new ethers.Contract(nftContractAddress, minABI, signer);
      
      const transaction = await nftContract.safeTransferFrom(
        account,
        recipientAddress,
        tokenId
      );
      
      await transaction.wait();
      console.log(`NFT ${tokenId} transferred to ${recipientAddress}`);
    } catch (error) {
      console.error("Transfer failed:", error);
    }
  }

  return (
    <Container maxWidth="md">
            <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item>
          <Button
             onClick={handleBackToApp}
            startIcon={<ArrowBackIcon />}
            variant="outlined"
          >
            Back to App
          </Button>
        </Grid>
      </Grid>
      <Typography variant="h4" gutterBottom>
        Admin Panel
      </Typography>
      <Snackbar 
        open={notification.open} 
        autoHideDuration={6000} 
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseNotification} 
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>

      <Grid container spacing={3}>
        {/* NFT Transfer Section */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Transfer NFT
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="NFT Contract Address"
            variant="outlined"
            value={nftContractAddress}
            onChange={(e) => setNftContractAddress(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Token ID"
            variant="outlined"
            value={tokenId}
            onChange={(e) => setTokenId(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Recipient Address"
            variant="outlined"
            value={recipientAddress}
            onChange={(e) => setRecipientAddress(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={transferNFT}
            fullWidth
          >
            Transfer NFT
          </Button>
        </Grid>

        {/* Contract Management Section */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Contract Management
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={safeMint}
            fullWidth
          >
            Mint BAYC
          </Button>
        </Grid>

        <Grid item xs={12} md={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={setContractAddressMKT}
            fullWidth
          >
            Set Option Contract in Marketplace
          </Button>
        </Grid>

        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={setContractAddressOPT}
            fullWidth
          >
            Set Option Contract in Marketplace
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Option Contract Address"
            variant="outlined"
            value={ZtrikeAddress}
            onChange={e => setOption(e.target.value)}
          />
        </Grid>
      </Grid>
    </Container>
  );
}