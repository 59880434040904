import * as React from 'react';
import { Typography, AppBar, Grid, Toolbar, Button, Stack, Box } from '@mui/material';
import { MainMenu } from './MainMenu';
import { ethers } from 'ethers';
import Ztrike from './Ztrike.js';
import { useConnectWallet } from '@web3-onboard/react'
import { initializeWallet, getBalance } from './Etherfuncs.js';
import DarkModeSwitch from './darkModeSwitch.js';
import ethLogo from './logos/ethlogo.svg';

export function AppBarModule(props) {

    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()

    React.useEffect(() => {
        if (wallet) {
            initializeWallet(wallet);
        }
    }, [wallet]);

    const [currentBalance, setCurrentBalance] = React.useState('-');
    const { userAccount, setUserAccount, switchPC, erc721OptionAddress, fakeBaycAddress, mode, setMode, theme } = props;
    const [hideText, setHideText] = React.useState(false);

    React.useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setHideText(scrollPosition > 10);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    async function updateBalance() {
        if (wallet && wallet.provider) {
            const provider = new ethers.BrowserProvider(wallet.provider);
            const signer = await provider.getSigner();
            const address = await signer.getAddress();
            const balance = await provider.getBalance(address);
            setCurrentBalance(Number(ethers.formatEther(balance)).toFixed(2));
        }
    }

    React.useEffect(() => {
        if (wallet) {
            const walletAddress = wallet.accounts[0].address;
            setUserAccount(walletAddress);
            updateBalance();
        } else {
            setUserAccount('');
            setCurrentBalance('-');
        }
    }, [wallet, setUserAccount]);

    const handleConnect = async () => {
        if (wallet) {
            await disconnect(wallet);
        } else {
            await connect();
        }
    };

    function handleMode() {
        if (mode == "light") {
            setMode("dark");
        } else {
            setMode("light");
        }
    }

    return (
        <AppBar 
            position='fixed' 
            color="transparent" 
            elevation={0} 
            enableColorOnDark
            sx={{
                pointerEvents: 'none', // Make entire AppBar click-through by default
                '& .MuiToolbar-root': {
                    pointerEvents: 'none' // Make Toolbar click-through
                }
            }}
        >
            <Toolbar sx={{ 
                justifyContent: "space-between", 
                paddingTop: 1,
            }}>
                {/* Logo wrapper with pointer-events-auto */}
                <Box sx={{ pointerEvents: 'auto' }}>
                    <Ztrike 
                        width={150} 
                        logocolor={switchPC == 'Call' ? '#50DE8F' : '#b32d2d'}
                        logoInnerColor='#152321'  //"#152321"
                        mode={mode}
                        hideText={hideText}
                    />
                </Box>

                <div>
                    <Grid container justify="flex-end">
                        {/* Interactive elements wrapper with pointer-events-auto */}
                        <Box sx={{ pointerEvents: 'auto' }}>
                            <Stack direction="column" spacing={1}>
                                <Button
                                    variant='outlined'
                                    
                                    sx={{ marginLeft: "auto", marginRight: '5px', fontSize: 10,
                                        color: switchPC == 'Call' 
                                            ? mode == 'light' 
                                                ? theme.palette.primary.main
                                                : theme.palette.primary.light
                                            : theme.palette.secondary.main,
                                        borderColor: switchPC == 'Call' 
                                            ? mode == 'light' 
                                                ? theme.palette.primary.main
                                                : theme.palette.primary.light
                                            : theme.palette.secondary.main,
                                        '&:hover': {
                                            backgroundColor: switchPC == 'Call' 
                                                ? mode == 'light' 
                                                    ? `${theme.palette.primary.light}20`  // 20 is hex for 12% opacity
                                                    : `${theme.palette.primary.main}20`
                                                : `${theme.palette.secondary.main}20`,
                                            borderColor: switchPC == 'Call' 
                                                ? mode == 'light' 
                                                    ? `${theme.palette.primary.light}80`  // 80 is hex for 50% opacity
                                                    : `${theme.palette.primary.main}80`
                                                : `${theme.palette.secondary.main}80`,
                                        }
                                     }}
                                    onClick={handleConnect}
                                    disabled={connecting}
                                >
                                    {connecting ? 'Connecting...' : wallet ? (
                                        <Stack direction="row" spacing={0} style={{ paddingLeft: 0, paddingRight: 0, marginRight: 0 }}>
                                            <Typography size="small" style={{ paddingTop: 0, paddingRight: 4, margin: 0, fontSize: 10 }}>
                                                {wallet.accounts[0].address.substring(0, 5) + '...' + wallet.accounts[0].address.substring(38, 50)}
                                            </Typography>
                                            <div style={{ paddingTop: 0 }}>
                                                <img src={ethLogo} height="10" width="10" alt="ETH logo" />
                                            </div>
                                            <Typography size="small" style={{ paddingTop: 0, paddingLeft: 0, margin: 0, fontSize: 10 }}>
                                                {currentBalance}
                                            </Typography>
                                        </Stack>
                                    ) : 'Connect'}
                                </Button>
                                { false &&
                                <Button 
                                color='secondary' 
                                variant="outlined" 
                                size="small" 
                                sx={{ minHeight: 0, maxHeight: 20, minWidth: 0, padding: 1, marginLeft: "auto", marginRight: '5px' }}
                            >
                                <Typography variant='overline' sx={{ fontSize: 10 }}>
                                    SEPOLIA
                                </Typography>
                            </Button>
                            }
                            </Stack>
                        </Box>

                        <Box sx={{ maxWidth: 40, maxHeight: 3, pointerEvents: 'auto' }}>
                            <Stack direction="column" spacing={1}>
                            <Box style={{marginLeft:'5px', marginTop:'-5px'}}>
                                    <DarkModeSwitch
                                        onChange={handleMode}
                                        checked={mode=="light"}
                                    />
                                </Box>
                                { false &&
                                <MainMenu
                                    switchPC={switchPC}
                                    erc721OptionAddress={erc721OptionAddress}
                                    fakeBaycAddress={fakeBaycAddress}
                                    mode={mode}
                                />
                                }
                            </Stack>
                        </Box>
                    </Grid>
                </div>
            </Toolbar>
        </AppBar>
    );
}