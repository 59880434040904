import * as React from 'react';
import {Link, Box, IconButton, FormGroup, FormControlLabel, Checkbox, Card, CardContent, Stack, Divider, Grid, TextField, Button, InputLabel, MenuItem, FormControl, Select, Typography, ButtonGroup, LinearProgress} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { useState, useEffect, useCallback, useMemo } from 'react';
import SearchableContractInput from './contractSelectDropdown.js';
import ModalCreateOrder from './ModalCreateOrder';
import {matchOrders, createOrder, acceptOrder, getOrder, cancelOrder, batchCancelExpiredOrders, extendOffer} from './ethCreateOffer.js';
import { useData } from './contexts/DataContext';
import OrderViewCard from './OrderViewCard';
import { ethers } from 'ethers';
export function OrdersView(props) {
    // Destructure props with default values to prevent undefined access
    const {
        theme = {},
        mode = 'light',
        toggleTabBar = () => {},
        blockMapping = {},
        switchPC = 'Call',
        userAccount = ''
    } = props;

    const {
        orders: MyOrders,
        filterContract,
        setFilterContract,
        initialized,
        isLoading,
        error,
        uniqueContracts,
        currentBlock: curr,
        currentGas: currGas,
        filterStates,
        filterActions,
        filterOrders,
        expiredOrdersCount,
        setExpiredOrdersCount,
        batchCancelLoading,
        setBatchCancelLoading,
        batchCancelStatus,
        setBatchCancelStatus,
        isEffectivelyCancelledMapReady,
        setIsEffectivelyCancelledMapReady,
        effectivelyCancelledMap,
        setEffectivelyCancelledMap,
        bestMatches,
        setBestMatches,
        expiredOrders,
        getExpiredOrders,
        findBestPrice,  
        getBestMatch,
        filteredOrders,
        setFilteredOrders,
        bestOppositeOrders,
        userMatchingOrders,
        findMatchingUserOrder,
        nftImages,
        imageLoadingStates
      } = useData();



    // State variables related to modal and URL handling (non-data)
    const [filterOn, setFilterOn] = React.useState(false);
          
    const [openModalId, setOpenModalId] = useState(null);
    const [urlFilterOrderId, setUrlFilterOrderId] = useState('');
    const [OrderModalVisible, setOrderModalVisible] = useState(false);
    const [cancelStatus, setCancelStatus] = useState({});
    const [cancelLoading, setCancelLoading] = useState({});  
    const [modalSelectedNFT, setModalSelectedNFT] = useState({0: ''});
    const [openModalMap, setOpenModalMap] = useState({});
    const [extendOrderId, setExtendOrderId] = useState(null);
    const [extendBlocks, setExtendBlocks] = useState('');
    const [extendStatus, setExtendStatus] = useState({});
    const [extendLoading, setExtendLoading] = useState({});

      // Use filter states from context
      const {
        showCalls,
        showPuts,
        filterCreator,
        showExpired,
        showCancelled,
        showMyOrders,
        showFilled,
        showOnlyMatching,
      } = filterStates;
    
      // Use filter actions from context
      const {
        setShowCalls,
        setShowPuts,
        setFilterCreator,
        setShowExpired,
        setShowCancelled,
        setShowMyOrders,
        setShowFilled,
        setShowOnlyMatching,
      } = filterActions;
    
    // Add handleClose function
    const handleClose = () => setOpenModalId(null);

    // Add this function to handle cancel order
    const handleCancelOrder = async (orderId, isOffer) => {
        try {
            setCancelLoading(prev => ({ ...prev, [orderId]: true }));
            setCancelStatus(prev => ({ ...prev, [orderId]: 'Cancelling order...' }));
            
            const result = await cancelOrder(orderId, isOffer);
            
            setCancelStatus(prev => ({ 
                ...prev, 
                [orderId]: result.success ? 'Order cancelled successfully' : `Failed: ${result.message}`
            }));
            
        } catch (error) {
            setCancelStatus(prev => ({ 
                ...prev, 
                [orderId]: `Error: ${error.message}` 
            }));
        } finally {
            setCancelLoading(prev => {
                const newLoading = { ...prev };
                delete newLoading[orderId];
                return newLoading;
            });
        }
    };

    const findBestMatchingOrder = (order) => {
        if (!Array.isArray(MyOrders) || !userAccount) return null;
         // Filter orders where user is the creator
        const userOrders = MyOrders.filter(o => 
            String(o.orderCreator).toLowerCase() === String(userAccount).toLowerCase() &&
            o._orderId !== order._orderId && // Exclude the current order
            !o.isFilled && !o.isCancelled && // Only consider active orders
            (Number(o.validUntil) - curr) >= 1 // Only consider valid orders
        );
         // Find matching orders based on option details
        const matchingOrders = userOrders.filter(o => {
            // For specific orders, match exact optionId
            if (!order.isGeneral && !o.isGeneral) {
                return o.optionId === order.optionId;
            }
             // For general orders or mixing general/specific
            const baseDetailsMatch = 
                Number(o.strike) === Number(order.strike) &&
                o.isCall === order.isCall &&
                String(o.underlyingContract).toLowerCase() === String(order.underlyingContract).toLowerCase();
             if (!baseDetailsMatch) return false;
             // Conservative matching following contract rules
            if (order.isOffer) {
                return !o.isOffer && Number(order.expiry) >= Number(o.expiry);
            }
            return o.isOffer && Number(o.expiry) >= Number(order.expiry);
        });
         if (matchingOrders.length === 0) return null;
         // Sort by price and return the best match
        return matchingOrders.sort((a, b) => {
            const priceA = Number(a.price);
            const priceB = Number(b.price);
            return order.isOffer ? priceB - priceA : priceA - priceB;
        })[0];
     };

// Pass this function down to OrderViewCard
const handleAcceptOrder = async (orderId, selectedOptionId = null) => {
    const order = MyOrders.find(o => o._orderId === orderId);
    if (!order) return;
     // Check for user's matching order first
    const userMatch = userMatchingOrders[orderId];
    
    if (userMatch) {
        try {
            const bidId = order.isOffer ? userMatch._orderId : orderId;
            const offerId = order.isOffer ? orderId : userMatch._orderId;
            
            console.log("Matching with user's order", {
                bidId,
                offerId,
                matchPrice: userMatch.price,
                orderPrice: order.price
            });
            
            await matchOrders(bidId, offerId);
        } catch (error) {
            console.error('Error matching orders:', error);
        }
    } else {
        // No matching user order, proceed with regular acceptOrder
        console.log("No matching user order, proceeding with acceptOrder");
        await acceptOrder(orderId, selectedOptionId);
    }
};

    // Add this useEffect to handle URL hash changes
    useEffect(() => {
        const handleHashChange = () => {
            const hash = window.location.hash.toLowerCase();
            const parts = hash.split('#').filter(part => part);
            
            if (parts[0] === 'orders') {
                if (parts[1]) {
                    const orderId = parseInt(parts[1], 10);
                    if (!isNaN(orderId)) {
                        setUrlFilterOrderId(orderId);
                        setShowOnlyMatching(orderId.toString());
                        // Ensure the order exists in MyOrders
                        if (MyOrders.length > 0 && !MyOrders.some(order => order._orderId === orderId)) {
                            console.warn(`Order #${orderId} not found`);
                        }
                    }
                } else {
                    setUrlFilterOrderId('');
                    setShowOnlyMatching('');
                }
            }
        };

        // Initial check
        handleHashChange();
        
        // Add event listener
        window.addEventListener('hashchange', handleHashChange);
        
        // Cleanup
        return () => window.removeEventListener('hashchange', handleHashChange);
    }, [MyOrders]); // Add MyOrders as a dependency


    const getBestMatchingPrice = (order) => {
        const oppositeOrder = bestOppositeOrders[order._orderId];
        if (oppositeOrder && oppositeOrder.price) {
            // Return the raw Wei value - let FormattedPrice handle the conversion
            return oppositeOrder.price;
        }
        return null;
};




    function toggleFilter(){
        // show filtering section (doesnt affect wheter filter is on or off)
        setFilterOn(!filterOn);
    }

    // Modify the visibility icon click handler to update URL
    const handleVisibilityClick = (orderId) => {
        if (showOnlyMatching === orderId.toString()) {
            setShowOnlyMatching('');
            // Use window.location.hash to ensure the event is triggered
            window.location.hash = 'orders';
        } else {
            setShowOnlyMatching(orderId.toString());
            // Use window.location.hash to ensure the event is triggered
            window.location.hash = `orders#${orderId}`;
        }
    };

    // Add this function near your other handlers
    const clearUrlFilter = () => {
        setUrlFilterOrderId('');
        setShowOnlyMatching('');
        window.history.replaceState(null, null, '#orders');
    };

  const handleBatchCancelExpired = async () => {
    try {
      setBatchCancelLoading(true);
      setBatchCancelStatus('Cancelling expired orders...');
      
      const expiredOrdersList = getExpiredOrders(userAccount);
      const orderIds = expiredOrdersList.map(order => order._orderId);

      let result = '';
      if (expiredOrdersCount === 1) {
        console.log("cancelling single order", orderIds[0]);
        result = await cancelOrder(orderIds[0], expiredOrdersList[0].isOffer);
      } else {
        console.log("cancelling batch of orders", orderIds);
        result = await batchCancelExpiredOrders(orderIds);
      }
      
      setBatchCancelStatus(result.success ? 
        'Orders cancelled successfully' : 
        `Failed: ${result.message}`
      );
    } catch (error) {
      setBatchCancelStatus(`Error: ${error.message}`);
    } finally {
      setBatchCancelLoading(false);
    }
  };


    // Update the validation function
    const validateExtension = (blocks, order) => {
        const numBlocks = Number(blocks);
        const currentBlock = Number(curr);
        const orderExpiry = Number(order.validUntil);
        const optionExpiry = Number(order.expiry);
        const newValidUntil = currentBlock + numBlocks;

        // Must be larger than 200
        if (!blocks || isNaN(numBlocks) || numBlocks <= 200) {
            return {
                isValid: false,
                message: 'Extension must be more than 200 blocks'
            };
        }

        // Must be smaller than 20M (contract hard limit)
        if (numBlocks >= 20000000) {
            return {
                isValid: false,
                message: 'Extension must be less than 20M blocks'
            };
        }

        // Must result in a later expiry than current
        if (newValidUntil <= orderExpiry) {
            return {
                isValid: false,
                message: 'New expiry must be later than current expiry'
            };
        }

        // Must be at least 200 blocks before option expiry
        if (newValidUntil >= (optionExpiry - 200)) {
            return {
                isValid: false,
                message: 'Must expire at least 200 blocks before option expiry'
            };
        }

        return {
            isValid: true,
            message: ''
        };
    };


    const handleNewOrderModal = (visible) => {
      setOrderModalVisible(visible);
    }

    const handleExtendOffer = async (orderId) => {
      const order = filteredOrders.find(o => o._orderId === orderId);
      if (!order) return;

      const validation = validateExtension(extendBlocks, order);
      if (!validation.isValid) {
        setExtendStatus(prev => ({
          ...prev,
          [orderId]: { error: true, message: validation.message }
        }));
        return;
      }

      try {
        setExtendLoading(prev => ({ ...prev, [orderId]: true }));
        setExtendStatus(prev => ({
          ...prev,
          [orderId]: { error: false, message: 'Extending offer...' }
        }));

        const result = await extendOffer(orderId, Number(extendBlocks));

        setExtendStatus(prev => ({
          ...prev,
          [orderId]: {
            error: !result.success,
            message: result.success ? 'Offer extended successfully' : result.message
          }
        }));

        if (result.success) {
          setExtendOrderId(null);
          setExtendBlocks('');
        }

      } catch (error) {
        setExtendStatus(prev => ({
          ...prev,
          [orderId]: { error: true, message: error.message }
        }));
      } finally {
        setExtendLoading(prev => {
          const newLoading = { ...prev };
          delete newLoading[orderId];
          return newLoading;
        });
      }
    };

    // Effect for filtering orders - run immediately when MyOrders changes
    useEffect(() => {
        if (Array.isArray(MyOrders)) {
            const filtered = filterOrders(MyOrders);
            setFilteredOrders(filtered);
        }
    }, [MyOrders]);

    const renderOrders = useMemo(() => {
        if (!initialized || !Array.isArray(filteredOrders)) {
            return (
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <LinearProgress sx={{ width: '50%' }} />
                </Box>
            );
        }

        if (error) {
            return (
                <Typography color="error" align="center">
                    Error loading orders: {error}
                </Typography>
            );
        }

        if (filteredOrders.length === 0) {
            return (
                <Typography align="center" sx={{ width: '100%' }}>
                    No orders found
                </Typography>
            );
        }

        return filteredOrders.map(order => {
            const nftid=(order.isGeneral || !order.isCall) ? 'any' : order.underlyingId;
            const fixedNftid = nftid === "any" ? 0 : nftid;
            const key = `${order.underlyingContract}-${fixedNftid}`;
            const currImage = nftImages[key] || 'placeholder';
            const imageLoading = imageLoadingStates[key] || false;
            return(
            <OrderViewCard
                key={order._orderId}
                order={order}
                theme={theme}
                mode={mode}
                switchPC={switchPC}
                userAccount={userAccount}
                curr={curr}
                handleVisibilityClick={handleVisibilityClick}
                showOnlyMatching={showOnlyMatching}
                bestMatchingPrice={getBestMatchingPrice(order)} // Replace bestMatches with this
                userMatchingOrder={userMatchingOrders[order._orderId]} 
                effectivelyCancelledMap={effectivelyCancelledMap}
                handleAcceptOrder={handleAcceptOrder}
                handleCancelOrder={handleCancelOrder}
                cancelLoading={cancelLoading}
                cancelStatus={cancelStatus}
                toggleTabBar={toggleTabBar}
                handleExtendOffer={handleExtendOffer}
                extendOrderId={extendOrderId}
                setExtendOrderId={setExtendOrderId}
                extendBlocks={extendBlocks}
                setExtendBlocks={setExtendBlocks}
                extendLoading={extendLoading}
                extendStatus={extendStatus}
                setExtendStatus={setExtendStatus}
                MyOrders={MyOrders}
                modalSelectedNFT={modalSelectedNFT}
                setModalSelectedNFT={setModalSelectedNFT}
                openModalMap={openModalMap}
                setOpenModalMap={setOpenModalMap}
                currImage={currImage}
                imageLoading={imageLoading}
            />
        );
    });
    }, [
        initialized,
        filteredOrders,
        error,
        theme,
        mode,
        switchPC,
        userAccount,
        curr,
        showOnlyMatching,
        bestMatches,
        effectivelyCancelledMap,
        cancelLoading,
        cancelStatus,
        extendOrderId,
        extendBlocks,
        extendLoading,
        extendStatus,
        MyOrders,
        modalSelectedNFT,
        openModalMap
    ]);

    return(
    <Card sx={{maxWidth:{xs:'100%', sm:'100%', lg:'100%'}, minWidth: {sm:'97%', sm:'90%', lg:'50%'}, paddingLeft:1, paddingRight:0}}>
      <CardContent sx={{paddingLeft:0, paddingRight:0,  marginLeft:1, marginRight:0}}>    
          
          <Grid container  spacing={{ xs: 2, sm:3, md: 4 }} justify='center' 
          alignItems="stretch" columns={{ xs: 2, sm: 4, md: 6 }}>              
              <Grid item xs={12} style={{ paddingTop: 10 }}>
                  {/* Single row with all elements */}
                  <Stack 
                      direction="row" 
                      sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                          alignItems: 'center',
                          paddingBottom: 1
                      }}
                  >
                      {/* Title on the left */}
                      <Typography variant="h6">
                          {urlFilterOrderId ? `Order #${urlFilterOrderId}` : `Orders (${filteredOrders?.length || 0})`}
                      </Typography>

                      {/* Controls on the right */}
                      <Stack 
                          direction="row" 
                          spacing={1}
                          alignItems="center"
                      >

                            {userAccount && expiredOrdersCount > 0 && (
                                <Box sx={{ width: 'auto', paddingBottom: 1, marginTop: 0, paddingRight: 1 }}>
                                    <Button 
                                        variant="outlined" 
                                        size="small"
                                        onClick={handleBatchCancelExpired}
                                        disabled={Boolean(batchCancelLoading)}
                                        color={switchPC === 'Call' ? 'primary': 'secondary'}
                                        sx={{
                                            border: 1,
                                            borderColor: mode==="light" ? theme.palette.primary.main : theme.palette.primary.light,
                                            mt: 1
                                        }}
                                    >
                                        <Typography sx={{fontSize: 10, color: mode==="light" ? theme.palette.primary.main : theme.palette.primary.light}}>
                                            {batchCancelLoading ? 'Cancelling...' : `Cancel Expired Orders (${expiredOrdersCount})`}
                                        </Typography>
                                    </Button>
                                    {batchCancelStatus && (
                                        <Typography 
                                            variant="caption" 
                                            color={batchCancelStatus.includes('successfully') ? (mode==="light" ? theme.palette.primary.main : theme.palette.primary.light) : 'error.main'}
                                            sx={{ fontSize: 8, maxWidth: '50px', overflow: 'scroll' }}
                                        >
                                            {batchCancelStatus}
                                        </Typography>
                                    )}
                                </Box>
                            )}


                          <Button 
                              variant="outlined" 
                              size="small" 
                              disabled={false} 
                              onClick={() => handleNewOrderModal(true)} 
                              color={props.switchPC == 'Call' ? 'primary': 'secondary'}
                              sx={{
                                  border: 1, 
                                  borderColor: mode==="light" ? theme.palette.primary.main : theme.palette.primary.light
                              }}
                          >
                              <Typography sx={{fontSize: 12, 
                              color: mode==="light" ? theme.palette.primary.main : theme.palette.primary.light}}>
                                  New order
                              </Typography>
                          </Button>
                          <ModalCreateOrder
                              switchPC={switchPC}
                              openT={OrderModalVisible}
                              setOpenT={handleNewOrderModal}
                              expIsBlock={true}
                              blockMapping={blockMapping}
                              specificBid={false}
                              theme={theme}
                              mode={mode}
                              toggleTabBar={toggleTabBar}
                              userAccount={userAccount}
                              bb={curr}
                              hasImplicitContext={false}
                              />

                          <IconButton onClick={() => toggleFilter()}>
                              {filterOn ? <FilterListOffIcon/> : <FilterListIcon/>}
                          </IconButton>

                          {userAccount && (
                              <FormGroup>
                                  <FormControlLabel 
                                      control={
                                          <Checkbox 
                                              checked={showMyOrders} 
                                              onChange={(e) => {
                                                  setShowMyOrders(e.target.checked);
                                                  if (e.target.checked) {
                                                      setFilterCreator(''); // Clear the creator filter when showing my orders
                                                  }
                                              }}
                                              sx={{ 
                                                  '& .MuiSvgIcon-root': { 
                                                      fontSize: 20  // Smaller checkbox size
                                                  }
                                              }}
                                          />
                                      } 
                                      label="My orders"
                                      sx={{
                                          '& .MuiFormControlLabel-label': {
                                              fontSize: '0.875rem'  // Smaller label text (14px)
                                          },
                                          marginRight: 0  // Remove right margin
                                      }}
                                  />
                              </FormGroup>
                          )}
                      </Stack>
                  </Stack>
                  <Box sx={{mb:-2, mt:-1, mr:2}}>
                    <SearchableContractInput
                                contracts={uniqueContracts.length > 0 ? uniqueContracts : []}
                                onChange={setFilterContract}
                                value={filterContract}
                                label="Underlying Contract"
                                sx={{paddingBottom:2, paddingRight:1}}
                                mode={mode}
                            />
                  </Box>

                  {/* URL filter button */}
                  {urlFilterOrderId && (
                      <Box sx={{ 
                          width: '100%', 
                          display: 'flex', 
                          justifyContent: 'flex-start',
                          mb: 1
                      }}>
                          <Button 
                              size="small"
                              variant="outlined"
                              onClick={clearUrlFilter}
                              sx={{ 
                                  minWidth: '100px',
                                  fontSize: '0.75rem',
                                  mt:'20px',
                                  color: switchPC === 'Call' ? 
                                (mode==="light" ? theme.palette.primary.main : theme.palette.primary.light) : 'secondary',
                                borderColor: switchPC === 'Call' ? 
                                (mode==="light" ? theme.palette.primary.main : theme.palette.primary.light) : 'secondary'
                              }}
                          >
                              Show all orders
                          </Button>
                      </Box>
                  )}

                  {/* Rest of your filter content */}
                  {filterOn && (
                      <>
                          <Divider textAlign="left" sx={{paddingBottom:2, paddingTop:3}}>Filter Orders</Divider>
                          
                          
                          { false && <TextField 
                              id="outlined-basic" 
                              label="Order Creator" 
                              fullWidth
                              variant="outlined" 
                              size="small"
                              value={filterCreator}
                              onChange={(e) => setFilterCreator(e.target.value)}
                              sx={{paddingBottom:-1, paddingRight:1}}
                            /> }

                          <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                              <FormControlLabel 
                                  control={
                                      <Checkbox 
                                          checked={showExpired} 
                                          onChange={(e) => setShowExpired(e.target.checked)}
                                      />
                                  } 
                                  sx={{ 
                                    '& .MuiSvgIcon-root': { 
                                      fontSize: 20
                                    }
                                  }}
                                  label="Expired" 
                              />
                              <FormControlLabel 
                                  control={
                                      <Checkbox 
                                          checked={showCancelled} 
                                          onChange={(e) => setShowCancelled(e.target.checked)}
                                      />
                                  } 
                                  sx={{ 
                                    '& .MuiSvgIcon-root': { 
                                      fontSize: 20
                                    }
                                  }}
                                  label="Cancelled" 
                              />
                              <FormControlLabel 
                                  control={
                                      <Checkbox 
                                          checked={showFilled} 
                                          onChange={(e) => setShowFilled(e.target.checked)}
                                      />
                                  } 
                                  label="Filled" 
                              />
                              <FormControlLabel 
                                  control={
                                      <Checkbox 
                                          checked={showCalls} 
                                          onChange={(e) => {
                                              // Prevent unchecking both
                                              if (showPuts || e.target.checked) {
                                                  setShowCalls(e.target.checked);
                                              }
                                          }}
                                      />
                                  } 
                                  sx={{ 
                                    '& .MuiSvgIcon-root': { 
                                      fontSize: 20
                                    }
                                  }}
                                  label="Calls" 
                              />
                              <FormControlLabel 
                                  control={
                                      <Checkbox 
                                          checked={showPuts} 
                                          onChange={(e) => {
                                              // Prevent unchecking both
                                              if (showCalls || e.target.checked) {
                                                  setShowPuts(e.target.checked);
                                              }
                                          }}
                                      />
                                  } 
                                  sx={{ 
                                    '& .MuiSvgIcon-root': { 
                                      fontSize: 20
                                    }
                                  }}
                                  label="Puts" 
                              />
                          </FormGroup>
                          <Divider sx={{paddingTop:2}}/>
                      </>
                  )}
              </Grid>

              <Grid container item xs={12} wrap='nowrap' style={{ 
                paddingTop: 25, 
                maxHeight:'600px',
                overflowY:'hidden',
                overflowX: 'auto',
                display: "flex",
                flexDirection:"row",
                maxWidth: '100%',
                scrollbarColor: '#282929 #6f7070',
                scrollbarWidth: 'thin',
                marginBottom: 0
              }}>
                {renderOrders}
              </Grid>
                                        


          </Grid>
          
          {/* Add the ModalCreateOrder components here */}
          {Array.isArray(filteredOrders) && filteredOrders.map(e => (
    <ModalCreateOrder
        key={`modal-${e._orderId}-${userAccount}`}
        activeStrike={String(e.strike)}
        activeExpiry={e.expiry}
        underlyingContract={e.underlyingContract}
        activePC={e.isCall ? 'call' : 'put'}
        bb={curr}
        switchPC={e.isCall ? 'Call' : 'Put'}
        isSpecific={!e.isGeneral}
        activeUnderlyingID={e.underlyingId}
        optionID={e.optionId}
        orderType={e.isOffer ? 'Bid' : 'Offer'}
        openT={openModalId === e._orderId}
        setOpenT={handleClose}
        expIsBlock={true}
        blockMapping={blockMapping}
        userAccount={userAccount}
        hasImplicitContext={true}
    />
))}
      </CardContent>
</Card>)};

