import * as React from 'react';
import { useRef, useCallback } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Container, Skeleton } from '@mui/material';
import { useImageCache } from './contexts/ImageCacheContext';

// Custom hook for intersection observer
function useIntersectionObserver(callback, deps = []) {
    const observer = useRef(null);
    
    return useCallback(node => {
        if (observer.current) observer.current.disconnect();
        
        observer.current = new IntersectionObserver(entries => {
            callback(entries[0]);
        }, {
            root: null,
            rootMargin: '50px',
            threshold: 0.1 // Element is considered visible when 10% is visible
        });
        
        if (node) observer.current.observe(node);
    }, deps);
}

export default function OptCardMedia(props) {
    const height = props.height;
    const currOptionImage = props.currOptionImage;
    const isOptionInactive = props.isOptionInactive;
    const { getFromCache, addToCache } = useImageCache();
    const [isLoading, setIsLoading] = React.useState(true);
    const [imageData, setImageData] = React.useState(null);
    const [shouldRender, setShouldRender] = React.useState(false);
    const [isInView, setIsInView] = React.useState(false);
    
    const intersectionRef = useIntersectionObserver((entry) => {
        //console.log('Intersection status for:', currOptionImage, 'isIntersecting:', entry.isIntersecting);
        setIsInView(entry.isIntersecting);
        if (entry.isIntersecting) {
            setShouldRender(true);
        }
    }, [currOptionImage]);

    React.useEffect(() => {
        if (!currOptionImage || currOptionImage === "" || currOptionImage.includes('404')) {
            setIsLoading(false);
            return;
        }

        const cachedData = getFromCache(currOptionImage);
        if (cachedData) {
            setImageData(cachedData);
            setIsLoading(false);
            return;
        }

        fetch(currOptionImage)
            .then(response => response.blob())
            .then(blob => {
                const objectUrl = URL.createObjectURL(blob);
                addToCache(currOptionImage, objectUrl);
                setImageData(objectUrl);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error loading image:', error);
                setIsLoading(false);
            });
    }, [currOptionImage, getFromCache, addToCache]);

    if (!currOptionImage || currOptionImage === "" || currOptionImage.includes('404') || isLoading) {
        return (
            <Box sx={{ width: '100%', height: height, padding:0}}>
                <Container disableGutters sx={{padding:'-24px', width:'100%', margin:0}}>
                    <Skeleton 
                        animation="wave" 
                        variant="rectangular"
                        height={350} 
                        width='100%' 
                        sx={{marginTop:1, borderRadius:2, padding:0, margin:0}}
                    />
                </Container>  
            </Box>
        );
    }

    return (
        <Box 
            ref={intersectionRef}
            sx={{ 
                width: '100%', 
                height: height,
                opacity: isInView ? 1 : 0.5, // Visual feedback for visibility
                transition: 'opacity 0.3s ease-in-out' // Smooth transition
            }}
        >
            {(isInView && shouldRender) && (
                <>
                    <embed src={imageData || currOptionImage} height={height} width='100%' style={{opacity: isOptionInactive ? 0.5: 1}}/>
                </>
            )}
        </Box>
    );
}