import { ethers } from 'ethers';
import BAYC from './artifacts/contracts/FakeBAYC.sol/BAYC.json';
import Option from './artifacts/contracts/ERC721Option.sol/ERC721Option.json';
import Marketplace from './artifacts/contracts/Marketplace.sol/Marketplace.json';

import {ZtrikeAddress,  NFTAddress, MarketplaceAddress} from './ethContracts';

import { getOrder, getGeneralBidDetails, getOption} from './supaBaseFuncs.js';

import { getBlockGas } from './supaBaseFuncs.js';

import { notificationService } from './contexts/notificationService';


//const INFURA_URL = process.env.REACT_APP_INFURA_URL;




// Wallet information singleton
let wallet = null;

export function ethCreateOfferInitializeWallet(walletInfo) {
  if (wallet) {
    console.warn("ethCreateOffer: Wallet already initialized.");
    return;
  }
  if (!walletInfo) {
    console.error("ethCreateOffer: No wallet information provided.");
    return;
  }
  console.log("ethCreateOffer: Initializing wallet", walletInfo);
  wallet = walletInfo;
}

async function getProvider(){
  if (wallet) {
    let account = wallet.accounts[0].address;
    const provider= new ethers.BrowserProvider(wallet.provider, 'any')
    const signer = await provider.getSigner()
    return [provider, signer, account];
}
}

async function requestAccount() {
  const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' });
  return [account];
  }


/*
struct Order{
    uint orderId;
    uint256 optionId; 
    address payable orderCreator;
    uint256 priceInWei; // 1 ether = 1*10^18 = 1000000000000000000 = 1_000_000_000_000_000_000
    bool isOffer; // 1==Offer or 2==Bid
    bool isFilled; // order was filled
    bool isCancelled; // order was cancelled
    uint validForNblocks; //
    uint expiryBlock;
*/
async function matchOrders(bidId, offerId) {
  notificationService.notify("Matching orders #"+String(bidId)+" and #"+String(offerId), "info");
  if (!wallet) {
    console.error("matchOrders: No wallet available");
    throw new Error("Please connect wallet first");
  }
   const [provider, signer, account] = await getProvider();
  
  try {
    // Get both orders from database
    const [bidOrder, offerOrder] = await Promise.all([
      getOrder(bidId),
      getOrder(offerId)
    ]);
    
    if (!bidOrder[0] || !offerOrder[0]) {
      throw new Error("Could not find orders");
    }
     const bid = bidOrder[0];
    const offer = offerOrder[0];
     // Convert prices to ethers.js format
    const offerPrice = ethers.parseUnits(String(offer.price), 0);
    const bidPrice = ethers.parseUnits(String(bid.price), 0);
    
    // Calculate price difference only if bid creator is calling
    const isBidCreator = account.toLowerCase() === bid.orderCreator.toLowerCase();
    const priceDifference = isBidCreator ? offerPrice - bidPrice : 0n;
    
    console.log("matchOrders: Price calculation:", {
      offerPrice: ethers.formatEther(offerPrice),
      bidPrice: ethers.formatEther(bidPrice),
      priceDifference: ethers.formatEther(priceDifference),
      isBidCreator
    });
     const contract = new ethers.Contract(MarketplaceAddress, Marketplace.abi, signer);
    
    // Call matchOrders with the correct value
    const transaction = await contract.matchOrders(
      bidId,
      offerId,
      {
        value: priceDifference
      }
    );
     notificationService.notify("matchOrders: Transaction submitted: " + transaction.hash, "info");
    await transaction.wait();
    notificationService.notify("matchOrders: Transaction confirmed", "success");
    
    return { success: true };
   } catch (error) {
    console.error("matchOrders: Error occurred:", error);
    throw error;
  }
}

async function acceptOrder(orderId, optionId){
  if (wallet) {
    const [provider, signer, account] = await getProvider();
    const contractSigner = new ethers.Contract(MarketplaceAddress, Marketplace.abi, signer);
    //const contractProvider = new ethers.Contract(MarketplaceAddress, Marketplace.abi, provider);
    
    // get Order Details
    // get these from supabase instead
    //const order = await contractProvider.Orders(orderId);
    
    const order = await getOrder(orderId);
    console.log(orderId, "order Fetched: >>>>", order);
    let oo = {
      'orderId':order[0]['_orderId'],
      'optionId':order[0]['optionId'],
      'orderCreator':order[0]['orderCreator'],
      'priceInWei':order[0]['price'],
      'expiryBlock':order[0]['validUntil'],
      'isOffer':order[0]['isOffer'],
      'isFilled':order[0]['isFilled'],
      'isCancelled':order[0]['isCancelled'],
      'isGeneral':order[0]['isGeneral']
      };

    /*
    struct Order{
        uint orderId;
        uint256 optionId;  // or GeneralBidOptionInfoID
        address payable orderCreator;
        uint256 priceInWei; // 1 ether = 1*10^18 = 1000000000000000000 = 1_000_000_000_000_000_000
        uint validUntil; // block at which order expires
        bool isOffer; // 1==Offer or 2==Bid
        bool isFilled; // order was filled
        bool isCancelled; // order was cancelled
        bool isGeneral;
    }

    // store the details of a isGeneral order
    struct GeneralBid{
        uint orderId;
        bool isCall;
        uint strike;
        uint expiry; // block at which Option expires
        address contractAddress;
    }
    */
    console.log(oo)
    if (oo.isOffer){
    // if order is offer -- acceptOffer
    console.log("Accepting offer")
    const priceInWei = oo.priceInWei;
    const wei = ethers.formatUnits(String(priceInWei),'wei');
    //console.log("wei", wei);
    notificationService.notify("Accepting offer", "info");
    const acceptOfffer = await contractSigner.acceptOffer(Number(orderId), {value:wei});
    //console.log("Waiting");
    await acceptOfffer.wait();
    notificationService.notify("Offer accepted", "success");
    } else{

      if (oo.isGeneral){
          console.log("Not implemented yet -- generalBidFill")

          // get generalBid from supabase
          const gBid = getGeneralBidDetails(orderId);
          // maybe also get OptionId from supabase to check if everything matches before EVM rejection??
          const option = getOption(optionId);

            //console.log("gBid", gBid);
            //console.log("option", option);

          // make checks to see if details match so order can be accepted
          console.log("CRITICAL: missing implementation of detail checks");

          // call function to acceptBid
          notificationService.notify("Accepting general bid #"+String(orderId)+" for option #"+String(optionId), "info");
          const acceptBidGeneral = await contractSigner.acceptBidGeneral(orderId, optionId);
          //console.log("Waiting ~ ");
          await acceptBidGeneral.wait();
          notificationService.notify("General bid filled", "success");

      } else {
          // if order is bid -- acceptBid 
          console.log("accepting bid", orderId);
          notificationService.notify("Accepting bid #"+String(orderId), "info");
          const acceptBid = await contractSigner.acceptBid(orderId);
          //acceptBidGeneral(uint _orderId, uint _optionId)
          //acceptBid(uint _orderId) 
          //console.log("Waiting");
          await acceptBid.wait();
          notificationService.notify("Bid filled", "success");

      }
    }

  }
}


async function cancelOrder(_orderId, isOffer) {
  if (wallet) {
    const [provider, signer, account] = await getProvider();
    const contract = new ethers.Contract(MarketplaceAddress, Marketplace.abi, signer);
    console.log("trying cancel Order:", _orderId, isOffer);

    try {
      if (isOffer) {
        notificationService.notify("Cancelling offer #"+String(_orderId), "info");
        const cancelOrder = await contract.cancelOffer(_orderId);
        //console.log("Waiting for cancel offer transaction");
        await cancelOrder.wait();
        notificationService.notify("Offer cancelled", "success");
      } else {
        notificationService.notify("Cancelling bid #"+String(_orderId), "info");
        const cancelOrder = await contract.cancelBid(_orderId);
        //console.log("Waiting for cancel bid transaction"); 
        await cancelOrder.wait();
        notificationService.notify("Bid cancelled", "success");
      }
      return { success: true, message: 'Order cancelled successfully' };
    } catch (error) {
      console.error("Cancel order error:", error);
      notificationService.notify("Cancel order error: "+error.message, "error");
      return { success: false, message: error.message };
    }
  }
  return { success: false, message: 'Please connect wallet' };
}


async function getCurrentBlock() {
  const [bb, fGas] = await getBlockGas();
  console.log("blockGasInfo", bb);
  return bb;
}

async function createGeneralBid(underlyingAddress, strike, isCall, expiry, bid, validForNBlocks, setLoadingStatus, expIsBlock=false){
  console.log("createGeneralBid: Starting with params:", {
    underlyingAddress,
    strike,
    isCall,
    expiry,
    bid,
    validForNBlocks,
    expIsBlock
  });

  try {
    const loadingMessage = `Creating order to buy any ${isCall ? 'call' : 'put'} option with strike ${strike} on underlying collection ${underlyingAddress} for Ξ${bid} in the next ${validForNBlocks} blocks`;
    console.log("createGeneralBid: Setting loading status:", loadingMessage);
    setLoadingStatus({
      visible: true,
      loadingText: loadingMessage
    });
  
    if (wallet) {
      console.log("createGeneralBid: Wallet connected, getting provider");
      const [provider, signer, account] = await getProvider();
      console.log("createGeneralBid: Got signer for account:", account);

      const contract = new ethers.Contract(MarketplaceAddress, Marketplace.abi, signer);
      console.log("createGeneralBid: Created contract instance at:", MarketplaceAddress);
      
      // Calculate expiry block
      let expiryBlock = Number(expiry);
      if (expIsBlock) {
        console.log("createGeneralBid: Using direct expiry block:", expiryBlock);
      } else {
        const currentBlock = await getCurrentBlock();
        expiryBlock = Number(expiry) + Number(currentBlock);
        console.log("createGeneralBid: Calculated expiry block:", {
          currentBlock,
          providedExpiry: expiry,
          calculatedExpiryBlock: expiryBlock
        });
      }
      
      // Format contract parameters
      const strikeInWei = ethers.parseEther(String(strike));
      const bidInWei = ethers.parseEther(String(bid));
      
      console.log("createGeneralBid: Preparing contract call with params:", {
        underlyingAddress,
        strikeInWei: strikeInWei.toString(),
        isCall,
        expiryBlock,
        bidInWei: bidInWei.toString(),
        validForNBlocks,
        valueInWei: bidInWei.toString()
      });
      notificationService.notify("Creating general bid", "info");
      const bidCreate = await contract.createBidGeneral(
        underlyingAddress,
        strikeInWei,
        isCall,
        expiryBlock,
        bidInWei, 
        validForNBlocks, 
        {value: bidInWei}
      );
      
      //console.log("createGeneralBid: Transaction submitted:", bidCreate.hash);
      //console.log("createGeneralBid: Waiting for confirmation...");
      await bidCreate.wait();
      notificationService.notify("General bid created", "success");
      //console.log("createGeneralBid: Transaction confirmed");
      
      setLoadingStatus({visible: false, loadingText: 'Order created'});
      console.log("createGeneralBid: Order creation completed successfully");
    } else {
      console.log("createGeneralBid: No wallet connected");
    }
  } catch (error) {
    console.error("createGeneralBid: Error occurred:", error);
    notificationService.notify("General bid creation failed: "+error.message, "error");
    setLoadingStatus({
      visible: false,
      loadingText: 'Order creation failed for general bid'
    });
    throw error;
  }
}

async function createOrder(optionId, priceInETH, validForNblocks, isCall, isSell, setLoadingStatus){
  console.log("createOrder", optionId, priceInETH, validForNblocks, isCall, isSell);
  try {
    setLoadingStatus({
      visible: true,
      loadingText: `Creating order to ${isSell ? 'sell' : 'buy'} option ${optionId}  @ Ξ${priceInETH} in the next ${validForNblocks} blocks`
    });
    
    const priceInWei = ethers.parseUnits(priceInETH, "ether");
    console.log('ETH', priceInETH, 'wei', priceInWei);
     
    if (wallet) {
      const [provider, signer, account] = await getProvider();
      // Rest of the existing code...
      const ztrikeContract = new ethers.Contract(ZtrikeAddress, Option.abi, signer);
      const ztrikeContractProvider = new ethers.Contract(ZtrikeAddress, Option.abi, provider);
      // Check ownership first if trying to sell
      if (isSell ) {
        const owner = await ztrikeContractProvider.ownerOf(optionId);
        console.log("Option owner:", owner.toLowerCase());
        console.log("Account trying to sell:", account.toLowerCase());
        
        if (owner.toLowerCase() !== account.toLowerCase()) {
          setLoadingStatus({
            visible: false,
            loadingText: `Error: You don't own option #${optionId}`
          });
          return;
        }
      }


      console.log("trying approve", account, MarketplaceAddress);
      const isApproved = await ztrikeContractProvider.isApprovedForAll(account, MarketplaceAddress);
      console.log("isApproved", isApproved);

      if (false){
        const approval = await ztrikeContract.setApprovalForAll(MarketplaceAddress, true);
        await approval.wait();
        console.log("approved");
      } else {
        console.log("already approved");
      };
      
      const contract = new ethers.Contract(MarketplaceAddress, Marketplace.abi, signer);

      if (isSell){
          console.log("trying createOffer with args:", optionId, priceInWei, validForNblocks);
          setLoadingStatus({
            visible: true, 
            loadingText: `Creating transaction to sell option ${optionId} @ Ξ${priceInETH} in the next ${validForNblocks} blocks`
          });
          const offerCreate = await contract.createOffer(optionId, priceInWei, validForNblocks);
          //console.log("Waiting");
          notificationService.notify("Creating offer", "info");
          await offerCreate.wait();
          notificationService.notify("Offer created", "success");
          //console.log("Offer created")
          setLoadingStatus({visible: false, loadingText: 'Order created'});
      } else {
          console.log("trying createBidSpecifc with args:", optionId, priceInWei, validForNblocks);
          setLoadingStatus({
            visible: true, 
            loadingText: `Creating transaction to buy option ${optionId} @ Ξ${priceInETH} in the next ${validForNblocks} blocks`
          });
          const bidCreate = await contract.createBidSpecifc(optionId, priceInWei, String(validForNblocks),
            {value: priceInWei});
          //console.log("Waiting");
          notificationService.notify("Creating specific bid", "info");
          await bidCreate.wait();
          notificationService.notify("Specific bid created", "success");
          //console.log("specific bid created")
          setLoadingStatus({visible: false, loadingText: 'Order created'});
      }
    }
  } catch (error) {
    console.error("Error in createOrder:", error);
    notificationService.notify("Order creation failed: "+error.message, "error");
    setLoadingStatus({
      visible: false,
      loadingText: `Order creation failed: ${error.message}`
    });
  }
}

// Add this new function
async function batchCancelExpiredOrders(orderIds) {
  console.log("batchCancelExpiredOrders: Starting with orderIds:", orderIds);

  if (!wallet) {
    console.error("batchCancelExpiredOrders: No wallet available");
    throw new Error("Please connect wallet first");
  }

  try {
    const [provider, signer, account] = await getProvider();
    const contract = new ethers.Contract(MarketplaceAddress, Marketplace.abi, signer);
    
    console.log("batchCancelExpiredOrders: Calling contract with orderIds:", orderIds);
    notificationService.notify("Cancelling multiple orders ["+String(orderIds)+"]", "info");
    const transaction = await contract.cancelMultipleOrders(orderIds);
    
    //console.log("batchCancelExpiredOrders: Transaction submitted:", transaction.hash);
    await transaction.wait();
    //console.log("batchCancelExpiredOrders: Transaction confirmed");
    notificationService.notify("Orders cancelled", "success");
    
    return { success: true, message: 'Orders cancelled successfully' };
  } catch (error) {
    console.error("batchCancelExpiredOrders: Error occurred:", error);
    notificationService.notify("Orders cancellation failed: "+error.message, "error");
    return { success: false, message: error.message };
  }
}

// Add this new function
async function batchRetrieveExpiredOptions(optionIds) {
  console.log("batchRetrieveExpiredOptions: Starting with optionIds:", optionIds);

  if (!wallet) {
    console.error("batchRetrieveExpiredOptions: No wallet available");
    throw new Error("Please connect wallet first");
  }

  try {
    const [provider, signer, account] = await getProvider();
    const contract = new ethers.Contract(ZtrikeAddress, Option.abi, signer);
    
    console.log("batchRetrieveExpiredOptions: Calling contract with optionIds:", optionIds);
    notificationService.notify("Retrieving expired options ["+String(optionIds)+"]", "info");
    const transaction = await contract.batchRetrieveExpiredOptions(optionIds);
    
    //console.log("batchRetrieveExpiredOptions: Transaction submitted:", transaction.hash);
    await transaction.wait();
    //console.log("batchRetrieveExpiredOptions: Transaction confirmed");
    notificationService.notify("Options retrieved", "success");
    
    return { success: true, message: 'Options retrieved successfully' };
  } catch (error) {
    console.error("batchRetrieveExpiredOptions: Error occurred:", error);
    notificationService.notify("Options retrieval failed: "+error.message, "error");
    return { success: false, message: error.message };
  }
}

// Add this new function
async function extendOffer(orderId, additionalBlocks) {
  console.log("extendOffer: Starting with params:", { orderId, additionalBlocks });

  if (!wallet) {
    console.error("extendOffer: No wallet available");
    throw new Error("Please connect wallet first");
  }

  try {
    const [provider, signer, account] = await getProvider();
    const contract = new ethers.Contract(MarketplaceAddress, Marketplace.abi, signer);
    
    //console.log("extendOffer: Calling contract with params:", { orderId, additionalBlocks });
    notificationService.notify("Extending offer #"+String(orderId), "info");
    const transaction = await contract.extendOffer(orderId, additionalBlocks);
    
    //console.log("extendOffer: Transaction submitted:", transaction.hash);
    await transaction.wait();
    //console.log("extendOffer: Transaction confirmed");
    notificationService.notify("Offer extended", "success");
    
    return { success: true, message: 'Offer extended successfully' };
  } catch (error) {
    console.error("extendOffer: Error occurred:", error);
    notificationService.notify("Offer extension failed: "+error.message, "error");
    return { success: false, message: error.message };
  }
}

export {matchOrders, createOrder, createGeneralBid, acceptOrder, cancelOrder, getCurrentBlock, batchCancelExpiredOrders, batchRetrieveExpiredOptions, extendOffer};

