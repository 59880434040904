import { createClient } from '@supabase/supabase-js'
import { ethers } from 'ethers';
import { MarketplaceAddress } from './ethContracts';
import {NFTAddress} from './ethContracts';
// Create a single supabase client for interacting with your database
const supabase = createClient('https://xmrsyqbtjkwmrxwzbodf.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhtcnN5cWJ0amt3bXJ4d3pib2RmIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjYwNjgxNjAsImV4cCI6MjA0MTY0NDE2MH0.KQQUZEFTfz_-ZHvYuf1Y2Uym4n6PGkmiZcbObUDMvN4')


async function old_getNftData(contractAddress, nftId){
    let { data: nftData, error } = await supabase
    .from('moralisnfts')
    .select('*')
    .eq('token_address', contractAddress)
    .eq('token_id', nftId)
    .limit(1);
    
    //console.log("nftData", contractAddress, nftId, nftData);
    return nftData
}

async function getNftData(contractAddress, nftId){
    let { data: nftData, error } = await supabase
    .from('alchemy_nfts')
    .select('*')
    .eq('contract_address', contractAddress)
    .eq('token_id', nftId)
    .limit(1);

    // Handle token_uri if it's in JSON format
    if (nftData && nftData[0]) {
        try {
            const tokenUri = nftData[0].token_uri;
            if (typeof tokenUri === 'string' && tokenUri.startsWith('{')) {
                const parsedUri = JSON.parse(tokenUri);
                nftData[0].token_uri = parsedUri.gateway || parsedUri.raw || tokenUri;
            }
        } catch (e) {
            console.warn('Error parsing token_uri:', e);
            // Keep original token_uri if parsing fails
        }
    }
    
    //console.log("nftData", contractAddress, nftId, nftData);
    return nftData;
}

async function getContractData(contractAddress){
    let { data: contractData, error } = await supabase
    .from('moraliscontracts')
    .select('*')
    .ilike('token_address', contractAddress)
    .limit(1);


    let moD = contractData[0];
    console.log("address", contractAddress, "NFTAddress", NFTAddress, 'GNSS','0x5EEd09B51FaaFe7c0f62b198692B45D9fa594f8A');
    // Special case for first NFTAddress (GNSS)
    if (String(contractAddress).toLowerCase() === String('0x5EEd09B51FaaFe7c0f62b198692B45D9fa594f8A').toLowerCase()) {
        console.log("GNSS special case");
        moD = {
            "token_address": "0xa1de9f93c56c290c48849b1393b09eb616d55dbb",
            "name": "GNSS",
            "symbol": "GNSS",
            "contract_type": "ERC721",
            "synced_at": "2022-03-17T11:38:40.000Z",
            "possible_spam": false,
            "verified_collection": true,
            "collection_logo": 'https://assets.mgxs.co/2004.png',
            "collection_banner_image": 'https://i.seadn.io/gae/PTS_vqird-CNZ-grJ_ZPKCVGaPrzoTmt4d9r-U3dXkTqa6EYkhEqsSf_JkAXRIaDGVOj4A0Bm_UVXQM3vwLa7BLXGbXGFVYgkw6bN0E?auto=format&dpr=1&w=2048',
            "collection_category": null,
            "project_url": null,
            "wiki_url": null,
            "discord_url": null,
            "telegram_url": null,
            "twitter_username": null,
            "instagram_username": null,
            "floor_price": "0.047997",
            "floor_price_usd": "184.282",
            "floor_price_currency": "eth"
        };
    }

    // Special case for NFTAddress
    if (String(contractAddress).toLowerCase() === String(NFTAddress).toLowerCase()) {
        moD = {
            "token_address": "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d",
            "name": "BoredApeYachtClub",
            "symbol": "BAYC",
            "contract_type": "ERC721",
            "synced_at": "2021-04-22T03:03:16.000Z",
            "possible_spam": false,
            "verified_collection": true,
            "collection_logo": "https://i.seadn.io/gae/Ju9CkWtV-1Okvf45wo8UctR-M9He2PjILP0oOvxE89AyiPPGtrR3gysu1Zgy0hjd2xKIgjJJtWIc0ybj4Vd7wv8t3pxDGHoJBzDB?w=500&auto=format",
            "collection_banner_image": "https://i.seadn.io/gae/i5dYZRkVCUK97bfprQ3WXyrT9BnLSZtVKGJlKQ919uaUB0sxbngVCioaiyu9r6snqfi2aaTyIvv6DHm4m2R3y7hMajbsv14pSZK8mhs?w=500&auto=format",
            "collection_category": "pfps",
            "project_url": "http://www.boredapeyachtclub.com/",
            "wiki_url": null,
            "discord_url": "https://discord.gg/3P5K3dzgdB",
            "telegram_url": null,
            "twitter_username": "BoredApeYC",
            "instagram_username": null,
            "floor_price": "10.9482177",
            "floor_price_usd": "29343.618",
            "floor_price_currency": "eth"
        };
    }
    
    return [moD]
}

function printDictDetails(dict) {
    for (let key in dict) {
        if (dict.hasOwnProperty(key)) {
            console.log(`Name: ${key}, Value: ${dict[key]}, Type: ${typeof dict[key]}`);
        }
    }
  }
  
async function getUniqueUnderlyingFromOrders(){
    try {
        let { data: orders, error } = await supabase
            .from('orders')
            .select('underlyingContract')
            .not('underlyingContract', 'is', null);
        
        if (error) throw error;

        // Get unique contracts and filter out invalid addresses
        const uniqueContracts = [...new Set(
            orders
                .map(order => order.underlyingContract?.toLowerCase())
                .filter(contract => contract && ethers.isAddress(contract))
        )];

        console.log("Unique contracts from orders:", uniqueContracts);
        return uniqueContracts;
    } catch (error) {
        console.error("Error getting unique contracts from orders:", error);
        return [];
    }
}

async function getActiveOrderIdByOptionId(optionId) {
    let { data: orders, error } = await supabase
        .from('orders')
        .select('*')
        .eq('optionId', optionId)
        .eq('isOffer', true)
        .is('isCancelled', false)
        .is('isFilled', false)
        .is('isGeneral', false)
        .order('_orderId', { ascending: false })
        .limit(1);        

    if (error) {
        console.error("Error fetching orders:", error);
        return null; // Return null if there's an error
    }

    // Check if any active orders were found
    if (orders.length > 0) {
        return orders[0]._orderId; // Return the orderId of the first active order
    }

    return null; // Return null if no active orders found
}


async function getOrders() {
    //console.log("connecting to sb");
    let { data: orders, error } = await supabase
    .from('orders')
    .select('*').order('_orderId', { ascending: true });
    //console.log("orders", orders);
    //printDictDetails(orders[0]);
    return orders;
}

async function getOrder(orderId) {
    //console.log("connecting to sb");
    let { data: orders, error } = await supabase
    .from('orders')
    .select('*').eq('_orderId', orderId);
    //console.log(orders);
    //printDictDetails(orders[0]);
    return orders;
}


async function getOrderOfferOnly(orderId) {
    let { data: orders, error } = await supabase
        .from('orders')
        .select('*')
        .eq('_orderId', orderId)
        .eq('isOffer', true)
        .is('isCancelled', false)
        .is('isFilled', false)
        .is('isGeneral', false)
        .order('_orderId', { ascending: false })
        .limit(1);

    if (error) {
        console.error("Error fetching orders:", error);
        return null;
    }

    return orders;
}

async function getOwners() {
    //console.log("connecting to sb");
    let { data: owners, error } = await supabase
    .from('owners')
    .select('*');
    //console.log(orders);
    //printDictDetails(orders[0]);
    return owners;
}


async function getGeneralBidDetails(orderId) {
    //console.log("connecting to sb");
    let { data: gBid, error } = await supabase
    .from('GeneralBids')
    .select('*').eq('orderId', orderId);
    //console.log(orders);
    //printDictDetails(orders[0]);
    return gBid;
}


async function getOption(optionId) {
    //console.log("connecting to sb");
    let { data: option, error } = await supabase
    .from('options')
    .select('*').eq('optionId',optionId);
    return option;
}


async function getOptions() {
    //console.log("connecting to sb");
    let { data: options, error } = await supabase
    .from('options')
    .select('*')//.neq('tokenURI','none');
    //console.log(options);
    //printDictDetails(orders[0]);
    return options;
}

async function getOptionsListedByOwner(address) {
    if (!address || !ethers.isAddress(address)) {
        console.warn("Invalid address provided to getOptionsListedByOwner:", address);
        return [];
    }

    try {
        //console.log("Fetching listed options for address:", address);
        
        // Get all orders created by this address that are active
        const { data: orders, error: ordersError } = await supabase
            .from('orders')
            .select('*')
            .filter('orderCreator', 'ilike', address) // Case-insensitive comparison
            .eq('isOffer', true)
            .is('isCancelled', false)
            .is('isFilled', false);

        if (ordersError) {
            console.error("Error fetching orders:", ordersError);
            return [];
        }

        //console.log("Found orders:", orders);

        if (!orders || orders.length === 0) {
            //console.log("No active orders found for address");
            return [];
        }

        // Get the option details for these orders
        const optionIds = orders.map(order => order.optionId);
        //console.log("Fetching options for IDs:", optionIds);
        
        const { data: optionsData, error: optionsError } = await supabase
            .from('options')
            .select('*')
            .in('optionId', optionIds);

        if (optionsError) {
            console.error("Error fetching option details:", optionsError);
            return [];
        }

        //console.log("Found options:", optionsData);
        return optionsData || [];
    } catch (error) {
        console.error("Unexpected error in getOptionsListedByOwner:", error);
        return [];
    }
}

async function getOptionsByOwner(address) {
    if (!address || !ethers.isAddress(address)) {
        console.warn("Invalid address provided to getOptionsByOwner:", address);
        return [];
    }

    try {
        //console.log("Fetching owned options for address:", address);
        
        const { data: ownersData, error: ownersError } = await supabase
            .from('owners')
            .select('*')
            .filter('owner', 'ilike', address); // Case-insensitive comparison

        if (ownersError) {
            console.error("Error fetching owners:", ownersError);
            return [];
        }

        //console.log("Found ownership records:", ownersData);

        if (!ownersData || ownersData.length === 0) {
            //console.log("No ownership records found for address");
            return [];
        }

        const optionIds = ownersData.map(item => item.optionId);
        //console.log("Fetching options for IDs:", optionIds);
        
        const { data: optionsData, error: optionsError } = await supabase
            .from('options')
            .select('*')
            .in('optionId', optionIds);

        if (optionsError) {
            console.error("Error fetching option details:", optionsError);
            return [];
        }

        //console.log("Found options:", optionsData);
        return optionsData || [];
    } catch (error) {
        console.error("Unexpected error in getOptionsByOwner:", error);
        return [];
    }
}


async function getOptionImage(id) {
    //console.log("connecting to sb");
    const { data } = supabase
            .storage
            .from('optionImages')
            .getPublicUrl(`OptionId${id}.svg`)
    return data;
}


async function getBlockGas() {
    //console.log("block");
    //console.log("connecting to sb ---- block gas");
    const { data, error } = await supabase
        .from('blockInfo')
        .select('*')
        .order('created_at', { ascending: false })
        .limit(1)
    //console.log(data);
    //console.log(data[0]["block"], data[0]['gas']);
    //printDictDetails(data[0]);
    return [data[0]["block"], data[0]['gas']];
}

async function getUniqueWriters(contractAddress = null) {
    try {
        let query = supabase
            .from('options')
            .select('optWriter')

        if (contractAddress) {
            query = query.ilike('contractAddress', contractAddress);
        }

        const { data, error } = await query;

        if (error) throw error;

        // Get unique writers and sort them
        const uniqueWriters = [...new Set(data.map(item => item.optWriter.toLowerCase()))]
            .sort((a, b) => a.localeCompare(b));

        return uniqueWriters;
    } catch (error) {
        console.error("Error fetching unique writers:", error);
        return [];
    }
}

async function getUniqueOwners(contractAddress = null) {
    try {
        if (contractAddress) {
            // First get all optionIds for the given contract
            const { data: optionsData, error: optionsError } = await supabase
                .from('options')
                .select('optionId')
                .ilike('contractAddress', contractAddress);

            if (optionsError) throw optionsError;

            const optionIds = optionsData.map(option => option.optionId);
            console.log("optionIds", optionIds);
            // Then get owners for these optionIds
            const { data: ownersData, error: ownersError } = await supabase
                .from('owners')
                .select('owner')
                .in('optionId', optionIds);
            console.log("ownersData", ownersData);
            if (ownersError) throw ownersError;

            const uniqueOwners = [...new Set(ownersData.map(item => item.owner.toLowerCase()))]
                .sort((a, b) => a.localeCompare(b));
            console.log("uniqueOwners", uniqueOwners);
            return uniqueOwners;
        } else {
            // If no contractAddress specified, get all unique owners
            const { data, error } = await supabase
                .from('owners')
                .select('owner')

            if (error) throw error;

            const uniqueOwners = [...new Set(data.map(item => item.owner.toLowerCase()))]
                .sort((a, b) => a.localeCompare(b));

            return uniqueOwners;
        }
    } catch (error) {
        console.error("Error fetching unique owners:", error);
        return [];
    }
}

export {
    getOrders,
    getUniqueUnderlyingFromOrders, 
    getBlockGas, 
    supabase, 
    getOptions, 
    getOptionImage, 
    getOptionsByOwner,
    getOwners, 
    getOrder, 
    getOption, 
    getGeneralBidDetails, 
    getActiveOrderIdByOptionId, 
    getOptionsListedByOwner, 
    getOrderOfferOnly,
    getNftData, 
    getContractData,
    getUniqueWriters,
    getUniqueOwners,
};