import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";
import { Stack, Box, Card, CardContent, Button, createTheme, ThemeProvider } from '@mui/material';

import useStyles from './styles';
import { Typography, CssBaseline, Container} from '@mui/material'


import { ExtraMenu } from './ExtraMenu.js';
import {MintForm} from './MintForm';
//import { createTheme, ThemeProvider } from '@mui/material/styles';
import {AppBarModule} from './AppBarModule';
import {AppBarFooter} from './AppBarFooter';

import {OrdersView} from './OrdersView';
import { OptionsView } from './OptionsView';
import {OrderViewContract} from './OrderViewContract';

import { useState } from 'react';
import {Ztrike} from './ztrikeLogoConst';
import {ZtrikeAddress,  NFTAddress, MarketplaceAddress} from './ethContracts';

import { convertBlocksToTime } from './Etherfuncs.js';

//import SvgComponent from './optionMetadata/OptionSVG';

import { Link } from "react-router-dom";

import { getOrders, getBlockGas, supabase,getUniqueUnderlyingFromOrders } from './supaBaseFuncs.js';


import Onboard, { ThemingMap } from '@web3-onboard/core'
import { Web3OnboardProvider, init, useConnectWallet } from '@web3-onboard/react'
import injectedModule from '@web3-onboard/injected-wallets'
import { ethers } from 'ethers'

// Add this import at the top with other imports
import { NFTDataProvider } from './NFTDataContext';

import { BlockProvider } from './contexts/BlockContext';
import { NotificationProvider } from './contexts/NotificationContext.js';

import { useBlockNumber } from './contexts/BlockContext';
// Also add the useNFTData hook import since we use it in MainContent
import { useNFTData } from './NFTDataContext';

import { DataProvider } from './contexts/DataContext';
import { useData } from './contexts/DataContext';
import { ImageCacheProvider } from './contexts/ImageCacheContext';

import { notificationService } from './contexts/notificationService';
import { useNotification, NotificationType } from './contexts/NotificationContext';

// Rest of the file remains the same
import { ethCreateOfferInitializeWallet } from './ethCreateOffer.js';

import walletConnectModule from '@web3-onboard/walletconnect';
import phantomModule from '@web3-onboard/phantom'
import coinbaseWalletModule from '@web3-onboard/coinbase'
const coinbaseWalletSdk = coinbaseWalletModule()

const customTheme = {
  '--w3o-background-color': '#232526', 
  '--w3o-foreground-color': '#414345',
  '--w3o-text-color': '#fff',
  '--w3o-border-color': '#fffff',
  '--w3o-action-color': '#f44336',
  '--w3o-border-radius': '12px'
}

const wcInitOptions = {
    /**
     * Project ID associated with [WalletConnect account](https://cloud.walletconnect.com)
     */
    projectId: '9a2720e8c4ba95bac8830a812a6d68ad',
    /**
     * Chains required to be supported by all wallets connecting to your DApp
     */
    requiredChains: [1],
    /**
     * Chains required to be supported by all wallets connecting to your DApp
     */
    optionalChains: [11155111, 42161, 8453, 10, 137, 56],
    /**
     * Defaults to `appMetadata.explore` that is supplied to the web3-onboard init
     * Strongly recommended to provide atleast one URL as it is required by some wallets (i.e. MetaMask)
     * To connect with WalletConnect
     */
    dappUrl: 'http://ztrike.io'
  };
  
  // initialize the module with options
  const walletConnect = walletConnectModule(wcInitOptions);
  const phantom = phantomModule();

let web3Onboard;
//const apiKey = '1730eff0-9d50-4382-a3fe-89f0d34a2070'
const injected = injectedModule()
const INFURA_URL = process.env.REACT_APP_INFURA_URL;
const MORALIS_API_KEY = process.env.REACT_APP_MORALIS_API_KEY;

console.log("_______________________");
console.log("STARTING: INFURA --> ", INFURA_URL, typeof(INFURA_URL));
console.log("_______________________");
const rpcUrl = `https://mainnet.infura.io/v3/${INFURA_URL}`
const sepoliaRpcUrl = `https://sepolia.infura.io/v3/${INFURA_URL}`
//const sepoliaRpcUrl = `https://site1.moralis-nodes.com/sepolia/${MORALIS_API_KEY}`;

console.log("->>>", rpcUrl, sepoliaRpcUrl);

const initializeOnboard = async () => {
  const ethereumSepolia = {
    id: 11155111,
    token: 'ETH',
    label: 'Sepolia',
    rpcUrl: sepoliaRpcUrl
  };

  const mainnet = {
    id: '0x1',
    token: 'ETH',
    label: 'Ethereum Mainnet',
    rpcUrl: rpcUrl
  };

  const chains = [
    ethereumSepolia
  ];

  const wallets = [injectedModule(), walletConnect, phantom, coinbaseWalletSdk];

  web3Onboard = Onboard({
    apiKey: '1730eff0-9d50-4382-a3fe-89f0d34a2070', // Add the API key here
    theme: customTheme,
    wallets,
    chains,
    appMetadata: {
      name: 'ztrike.io',
      icon: Ztrike,
      description: 'The NFT option protocol'
    }
  });
};


  const App = () => {
    const [onboardInitialized, setOnboardInitialized] = useState(false);
  
    useEffect(() => {
      initializeOnboard().then(() => setOnboardInitialized(true));
    }, []);

    
  
    if (!onboardInitialized) {
      return <div>Loading...</div>;
    }
  
    return (
      <NFTDataProvider>
      <NotificationProvider>
        <Web3OnboardProvider web3Onboard={web3Onboard}>
          
            <DataProvider>
              <BlockProvider>
                <ImageCacheProvider>  
                  <MainContent />
                </ImageCacheProvider>
              </BlockProvider>
            </DataProvider>
          </Web3OnboardProvider>
      </NotificationProvider>
      </NFTDataProvider>
    );
  };
  

const MainContent = () => {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const { userAccount, setUserAccount } = useData(); // Add this
  const { setConnectedAddress } = useNFTData(); 

  useEffect(() => {
    if (wallet && !connecting) {
        const address = wallet.accounts[0].address;
        console.log(" TEST1234 - Setting connected address in NFTDataContext:", address);
        setConnectedAddress(address);
    } else {
        setConnectedAddress(null);
    }
}, [wallet, connecting, setConnectedAddress]);

  useEffect(() => {
    if(wallet && !connecting) {
     ethCreateOfferInitializeWallet(wallet);
     setUserAccount(wallet.accounts[0].address);
     localStorage.setItem('connectedWallet', wallet.label);
     console.log("localStorage wallet:", wallet.label); // Debug log
   }
 }, [wallet, connecting, setUserAccount]);
  useEffect(() => {
   const autoConnect = async () => {
     const previouslyConnectedWallet = localStorage.getItem('connectedWallet');
     if (previouslyConnectedWallet && !wallet && !connecting) {
       try {
         await connect({ autoSelect: { label: previouslyConnectedWallet, disableModals: true } });
         console.log("localStorage autoconnected:", previouslyConnectedWallet); // Debug log
       } catch (error) {
         console.error('localStorage autoconnect failed:', error);
       }
     }
   };
   autoConnect();
   console.log("localStorage autoconnected:", localStorage.getItem('connectedWallet')); // Debug log
 }, []); // Run once on component mount



  const { 
    blockNumber: bb,
    gasPrice: currGas,
    forceRefresh: refreshBlock 
  } = useBlockNumber();


  const [mode, setMode] = useState(() => {
    const savedMode = localStorage.getItem('themeMode');
    return savedMode || 'light';
  });

  useEffect(() => {
    localStorage.setItem('themeMode', mode);
  }, [mode]);

  //const [userAccount, setUserAccount] = useState(''); //chg
  const [amount, setAmount] = useState();
  const [strike, setStrike]=useState();
  const [contractAddress, setBAYC] = useState(NFTAddress);
  const [erc721OptionAddress, setOption] = useState(ZtrikeAddress);
  const [isTransferDisabled, setIsTransferDisabled] = useState(false);
  const [currentOption, setCurrentOption] = useState(0);
  const [switchPC, setPC] = React.useState('Call');
  const [blockMapping, setBlockMapping] = React.useState({});

  React.useEffect(() => {
    const generateBlockMapping = () => {
      const mapping = {};
      const BLOCKS_PER_DAY = 10000;
      
      const periods = [1, 2, 3, 4, 5, 6, 7, 14, 21, 30, 60, 90, 150, 180, 280, 365];

      periods.forEach(days => {
        const blocks = days * BLOCKS_PER_DAY;
        const blockNumber = bb + blocks + (10000 - ((bb + blocks) % 10000));
        
        mapping[blockNumber] = {
          blocks: blockNumber - bb,
          time: convertBlocksToTime(blockNumber - bb)
        };
      });

      setBlockMapping(mapping);
    };

    if (bb > 0) {
      generateBlockMapping();
    }
  }, [bb]);

  

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === 'light'
            ? {
                // Light mode
                primary: {
                  light: '#50de8f',
                  main: '#264a39', //'#264a39', nice dark: #152321
                  dark: '#243e24',
                  contrastText: '#fff',
                },
                secondary: {
                  light: '#ff7961',
                  main: '#f44336',
                  dark: '#e64a19',
                  contrastText: '#000',
                },
                background: {
                  default: '#fcfcfc',
                  paper: '#dbdbdb',
                },
              }
            : {
                // Dark mode
                primary: {
                  light: '#50de8f',//'#63a781',
                  main: '#63a781', //'#264a39',
                  dark: '#243e24',
                  contrastText: '#fff',
                },
                secondary: {
                  light: '#ff7961',
                  main: '#f44336',
                  dark: '#e64a19',
                  contrastText: '#000',
                },
                background: {
                  default: '#414345',
                  paper: '#232526',
                },
              }),
        },
        typography: {
          fontFamily: 'Inter',//'Inter',
        },
        shape: {
          borderRadius: 12,
        },
        custom: {
          gradient1: mode === 'dark'
            ? "radial-gradient(circle at top, #414345, #232526)"
            : "radial-gradient(circle at top, #fcfcfc, #dbdbdb)",
        },
      }),
    [mode]
  );

  const [tabBar, setTabBar] = React.useState([['Write',   true], 
                                            ['Options', false],
                                            ['Trade',   false], 
                                            ['Orders',  false]]);

  function toggleTabBar(i) {
    let cc = [['Write', false], 
              ['Options', false],
              ['Trade', false], 
              ['Orders', false]];

    cc[i] = [tabBar[i][0], true];
    setTabBar(cc);
    
    const hashValues = ['write', 'options', 'trade', 'orders'];
    
    // Only modify hash if it's a direct tab change (not from a link click)
    if (!window.directLinkNavigation) {
        window.history.replaceState(null, null, `#${hashValues[i]}`);
    }
    
    // Reset the flag
    window.directLinkNavigation = false;
  }

  function handleDisableTransfer() {
    console.log("Toggled transfer");
    setIsTransferDisabled(!isTransferDisabled);
  };

  const classes = useStyles(theme);

  const [isAdminView, setIsAdminView] = React.useState(false);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.toLowerCase();
      setIsAdminView(hash === '#admin');
      
      if (!hash.includes('admin')) {
        const hashParts = hash.split('#');
        const filteredParts = hashParts.filter(part => part);
        
        if (filteredParts.length > 0) {
          const tabIndices = {
            'write': 0,
            'options': 1,
            'trade': 2,
            'orders': 3
          };
          
          const tabIndex = tabIndices[filteredParts[0]];
          
          if (tabIndex !== undefined) {
            let cc = [['Write', false], 
                     ['Options', false],
                     ['Trade', false], 
                     ['Orders', false]];
            
            cc[tabIndex] = [tabBar[tabIndex][0], true];
            setTabBar(cc);
          }
        }
      }
    };
  
    handleHashChange();
    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  const { showNotification } = useNotification();

  useEffect(() => {
    notificationService.setNotificationCallback(showNotification);
  }, [showNotification]);

  return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div style={{ background: theme.custom.gradient1}} >
            <Helmet>
              <meta charSet="utf-8" />
              <title>Ztrike | App</title>
              <link rel="canonical" href="http://ztrike.io/app" />
              <style>{'body { background-color: black; }'}</style>
            </Helmet>

            <AppBarModule
              userAccount={userAccount}
              setUserAccount={setUserAccount}
              switchPC={switchPC}
              erc721OptionAddress = {erc721OptionAddress}
              contractAddress = {contractAddress}
              mode={mode}
              setMode={setMode}
              theme={theme}
            />
            <main >
      <div className={classes.container2} style={{maxWidth:'100%', minHeight:'100vh'}}>


        {isAdminView ? (
                <Container maxWidth='md' align='center' style={{ paddingTop: "20px" }}>
                  <ExtraMenu 
                    userAccount={userAccount}
                    setUserAccount={setUserAccount}
                    contractAddress={contractAddress}
                    erc721OptionAddress={erc721OptionAddress}
                    setBAYC={setBAYC}
                    setOption={setOption}
                    wallet={wallet}
                  />
                </Container>
              ) : (
                <>
                <br/><br/>                        
                



                {false ? 
                  <Container maxWidth='md'>
                    <Typography variant='h6' >
                      Write option contract
                    </Typography>
                  </Container>
                  : ''}
                
                <Container maxWidth='md' align='center' style={{  paddingTop: 0, paddingBottom:0}}
                  sx={{border:0, borderColor:'white'}}>
                  <Box style={{margin:0, padding:0, marginBottom:2}} 
                    sx={{ backgroundColor: 'transparent', boxShadow: 'none', 
                    border:0,  borderColor: 'red', borderRadius:1, p: 1,
                    overflow: 'hidden'}}>
                    <Stack direction="row" sx={{display: 'flex',
                                                        flexWrap: 'wrap',
                                                        justifyContent: 'space-between',
                                                        gap: 1,
                                                        border: 0,
                                                        borderColor: 'white',
                                                        borderRadius: 0,
                                                        p: 1,
                                                        margin:0, padding:0
                                                    }}>
                      {
                        tabBar.map( (e, index) => {
                          return (


                              <Button key={index} variant='outline' onClick={()=>toggleTabBar(index)}
                              style={{padding:5, margin:0, paddingLeft:1}}
                              sx={{
                                flexGrow: 1,
                                flexBasis: { xs: '40%', sm: '22%' },
                                maxWidth: { xs: '23%', sm: '23%' },
                                minWidth: 'auto',
                                p: 1,
                                background: e[1] ?  theme.palette.background.paper : 'transparent',
                                border: 0,
                                borderRadius: 1,
                                '&:hover': {
                                  background: e[1] ? theme.palette.background.paper : 'primary.dark',
                                },
                              }}
                              >
                                <Typography noWrap variant='body2' style={{padding:0, margin:0, fontSize:10}}>
                                  {e[0]}
                                </Typography>
                              </Button>
                          );
                        })
                      }
                      
                      
                    </Stack>
                    
                  </Box>
                </Container>

                { tabBar[0][1] ?
                  <Container maxWidth='md' align='center' style={{  paddingTop: 0, paddingBottom:'-200px'}}>
                    <MintForm  
                      contractAddress={contractAddress} 
                      setBAYC={setBAYC}
                      erc721OptionAddress={erc721OptionAddress} 
                      amount={amount} 
                      userAccount={userAccount}
                      setUserAccount={setUserAccount}
                      setAmount={setAmount}
                      setOption={setOption} 
                      isTransferDisabled={isTransferDisabled} 
                      toggleDisabled={handleDisableTransfer}
                      strike={strike}
                      setStrike={setStrike}
                      currentOption={currentOption}
                      setCurrentOption={setCurrentOption}
                      switchPC={switchPC}
                      setPC={setPC}
                      bb={bb}
                      blockMapping={blockMapping}
                      theme={theme}
                      mode={mode}
                    />
                  </Container>
                  : '' }
                  
                  
                { tabBar[1][1] ? 

                  <Container maxWidth='md' align='center' style={{  paddingTop: 0, paddingBottom:'-200px'}}>
                    <OptionsView
                    userAccount = {userAccount}
                    switchPC = {switchPC}
                    toggleTabBar={toggleTabBar}
                    theme={theme}
                    mode={mode}
                    />
                    
                    
                  </Container>
                  : '' }
                
                { tabBar[2][1] ? 
                    
                    
                    <Container maxWidth='md' align='center' style={{  paddingTop: 0}}>
                      <OrderViewContract
                      userAccount = {userAccount}
                      switchPC = {switchPC}
                      theme={theme}
                      curr={bb}
                      mode={mode}
                      blockMapping={blockMapping}
                      toggleTabBar={toggleTabBar}
                      wallet={wallet}
                      />
                    </Container>
                    : '' }

                  { tabBar[3][1] ? 

                    <Container maxWidth='md' align='center' style={{  paddingTop: "0px"}}>
                      <OrdersView
                      userAccount = {userAccount}
                      switchPC = {switchPC}
                      theme={theme}
                      mode={mode}
                      toggleTabBar={toggleTabBar}
                      blockMapping={blockMapping}
                      />
                    </Container>
                    : '' }

              
                            </>
                          )}

                    
        </div>
                
              </main>
              {true ? 
              <AppBarFooter 
              bb={bb}
              gg={currGas}
              erc721OptionAddress={erc721OptionAddress}
              mode={mode}
              />:''}
              
      </div>
  </ThemeProvider>
    );
  }

export default App;