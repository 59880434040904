// notificationService.js
let notificationCallback = () => {};

export const notificationService = {
  setNotificationCallback: (callback) => {
    notificationCallback = callback;
  },
  notify: (message, type) => {
    notificationCallback(message, type);
  }
};