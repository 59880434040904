import { useRef, useMemo, useEffect, useCallback } from 'react'
import { Canvas, useThree } from '@react-three/fiber'
import * as THREE from 'three'
import { OrbitControls } from '@react-three/drei'

function Grid({ count = 20, size = 300, tilt = -Math.PI / 1.7 }) {
  const { camera } = useThree()
  const planeRef = useRef(null)
  const mousePosition = useRef(new THREE.Vector2())
  const raycaster = useMemo(() => new THREE.Raycaster(), [])
  const lastUpdate = useRef(0)
  const throttleTime = 8
  const scrollProgress = useRef(0)

  const initialX = useMemo(() => {
    const gridCenter = Math.floor(count / 2)
    return (gridCenter / count) * 2 - 1
  }, [count])

  const texture = useMemo(() => {
    const canvas = document.createElement('canvas')
    const scale = window.devicePixelRatio > 2 ? 50 : 100
    canvas.width = count * scale
    canvas.height = count * scale
    const context = canvas.getContext('2d')
    
    if (context) {
      context.clearRect(0, 0, canvas.width, canvas.height)
      context.strokeStyle = 'rgba(42, 42, 42, 0.3)'
      context.lineWidth = 1
      for (let i = 0; i <= count; i++) {
        context.beginPath()
        context.moveTo(i * scale, 0)
        context.lineTo(i * scale, canvas.height)
        context.stroke()
        context.beginPath()
        context.moveTo(0, i * scale)
        context.lineTo(canvas.width, i * scale)
        context.stroke()
      }
    }
    
    const tex = new THREE.CanvasTexture(canvas)
    tex.minFilter = THREE.LinearFilter
    tex.magFilter = THREE.LinearFilter
    return tex
  }, [count])

  const updateGridHighlight = useCallback((tileX, tileY) => {
    const canvas = texture.image
    const context = canvas.getContext('2d')
    const scale = canvas.width / count
    
    if (context) {
      context.clearRect(0, 0, canvas.width, canvas.height)
      context.strokeStyle = 'rgba(42, 42, 42, 0.3)'
      context.lineWidth = 1
      for (let i = 0; i <= count; i++) {
        context.beginPath()
        context.moveTo(i * scale, 0)
        context.lineTo(i * scale, canvas.height)
        context.stroke()
        context.beginPath()
        context.moveTo(0, i * scale)
        context.lineTo(canvas.width, i * scale)
        context.stroke()
      }
      
      const highlightTile = (x, y, alpha) => {
        if (x >= 0 && x < count && y >= 0 && y < count) {
          context.fillStyle = `rgba(0, 255, 0, ${alpha})`
          context.fillRect(x * scale, y * scale, scale, scale)
        }
      }
      
      highlightTile(tileX, tileY, 0.5)
      highlightTile(tileX - 1, tileY, 0.3)
      highlightTile(tileX + 1, tileY, 0.3)
      highlightTile(tileX, tileY - 1, 0.3)
      highlightTile(tileX, tileY + 1, 0.3)
      
      const adjacentTiles = [
        [tileX - 2, tileY], [tileX + 2, tileY],
        [tileX, tileY - 2], [tileX, tileY + 2],
        [tileX - 1, tileY - 1], [tileX - 1, tileY + 1],
        [tileX + 1, tileY - 1], [tileX + 1, tileY + 1]
      ]
      adjacentTiles.forEach(([x, y]) => highlightTile(x, y, 0.1))
      
      texture.needsUpdate = true
    }
  }, [count, texture])

  useEffect(() => {
    const handleScroll = () => {
      const maxScroll = window.innerHeight * 0.5
      const scroll = Math.min(window.scrollY / maxScroll, 1)
      scrollProgress.current = scroll
    }
    
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    const handleMouseMove = (event) => {
      mousePosition.current.x = (event.clientX / window.innerWidth) * 2 - 1
      mousePosition.current.y = -(event.clientY / window.innerHeight) * 2 + 1
    }
    
    window.addEventListener('mousemove', handleMouseMove, { passive: true })
    return () => window.removeEventListener('mousemove', handleMouseMove)
  }, [])

  useEffect(() => {
    if (planeRef.current && camera) {
      raycaster.setFromCamera(new THREE.Vector2(initialX, -1), camera)
      const intersects = raycaster.intersectObject(planeRef.current)
      
      if (intersects.length > 0 && intersects[0].uv) {
        const { x, y } = intersects[0].uv
        const tileX = Math.floor(x * count)
        const tileY = Math.floor((1 - y) * count)
        updateGridHighlight(tileX, tileY)
      }
    }
  }, [camera, initialX, count, raycaster, updateGridHighlight])

  return (
    <mesh 
      ref={planeRef} 
      rotation={[tilt, 0, 0]} 
      position={[0, -20, -30]}
      frustumCulled={true}
    >
      <planeGeometry args={[size, size]} />
      <meshBasicMaterial 
        map={texture} 
        transparent={true}
        depthWrite={false}
        depthTest={false}
      />
    </mesh>
  )
}

export default function Background() {
  return (
    <Canvas
      camera={{ position: [0, 40, 60], fov: 50 }}
      style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
      dpr={[1, window.devicePixelRatio > 2 ? 2 : window.devicePixelRatio]}
      performance={{ min: 0.5 }}
    >
      <OrbitControls enableZoom={false} enablePan={false} enableRotate={false} />
      <Grid />
    </Canvas>
  )
}