import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Card, CardActions, CardContent, Skeleton } from '@mui/material';
import ModalCardMedia from './ModalCardMedia';
import { useNFTData } from './NFTDataContext';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function SwipeableTextMobileStepper(props) {
  const theme = useTheme();
  const { getImageFromMetadata } = useNFTData();
  
  // Props destructuring
  const {
    contractAddress,
    listOfNfts,
    currImage,
    setCurrImage,
    handleSelect,
    switchPC,
    placeholder,
    themeColor
  } = props;

  // Local state
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeStepNFTid, setActiveStepNFTid] = React.useState('');
  const [listError, setListError] = React.useState(false);
  const maxSteps = listOfNfts.length;

  // Effect for handling NFT list changes
  React.useEffect(() => {
    if (listOfNfts.length === 0) {
      setListError(true);
    } else {
      const currentNFT = listOfNfts[activeStep];
      if (currentNFT && currentNFT.id) {
        setActiveStepNFTid(currentNFT.id);
        setListError(false);
      }
    }
  }, [activeStep, listOfNfts]);

  // Effect for loading NFT image
  React.useEffect(() => {
    async function loadImage() {
      if (!listOfNfts[activeStep]?.tokenUri) return;
      
      setCurrImage('placeholder'); // Show loading state
      try {
        const imageUrl = await getImageFromMetadata(listOfNfts[activeStep].tokenUri);
        if (imageUrl) {
          setCurrImage(imageUrl);
        }
      } catch (error) {
        console.error("Error loading NFT image:", error);
        setCurrImage('placeholder');
      }
    }
    
    loadImage();
  }, [activeStep, listOfNfts, getImageFromMetadata, setCurrImage]);

  // Navigation handlers
  const handleNext = () => {
    setCurrImage(placeholder);
    setActiveStep((prevActiveStep) => {
      const newStep = prevActiveStep + 1;
      if (listOfNfts[newStep]?.id) {
        setActiveStepNFTid(listOfNfts[newStep].id);
      }
      return newStep;
    });
  };

  const handleBack = () => {
    setCurrImage(placeholder);
    setActiveStep((prevActiveStep) => {
      const newStep = prevActiveStep - 1;
      if (listOfNfts[newStep]?.id) {
        setActiveStepNFTid(listOfNfts[newStep].id);
      }
      return newStep;
    });
  };

  const handleStepChange = (step) => {
    setCurrImage(placeholder);
    setActiveStep(step);
    if (listOfNfts[step]?.id) {
      setActiveStepNFTid(listOfNfts[step].id);
    }
  };

  // Render empty state
  if (listOfNfts.length === 0) {
    return (
      <Box sx={{ maxWidth: 400, flexGrow: 1, borderRadius: 12 }}>
        {!listError && (
          <Typography variant="body2">Loading NFTs...</Typography>
        )}
        {listError && (
          <Typography variant="body2">No NFTs found for this collection</Typography>
        )}
      </Box>
    );
  }

  // Main render
  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1, borderRadius: 12 }}>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={10000}
        autoplay={false}
      >
        {listOfNfts.map((step, index) => (
          <div key={step.id}>
            {Math.abs(activeStep - index) <= 1 ? (
              <Box sx={{ width: '100%', borderRadius: 12, maxHeight: '80%'}} spacing={2} padding={1}>
                <Card sx={{ width: "1/2" }}>
                  {currImage === 'placeholder' ? (
                    <Skeleton variant="rounded" width={200} height={350} sx={{margin:1}}/>
                  ) : (
                    <ModalCardMedia currImage={currImage}/>
                  )}
                  
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      # {step.id}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Contract: {contractAddress?.slice(0, 5)}..{contractAddress?.slice(39)}
                    </Typography>
                    <Typography variant="body2">
                      URI: {step.tokenUri || 'Loading...'}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button 
                      size="small" 
                      variant='outlined' 
                      sx={{
                        color: themeColor,
                        borderColor: themeColor,
                        '&:hover': {
                          backgroundColor: `${themeColor}20`,  // 12% opacity
                          borderColor: `${themeColor}80`,      // 50% opacity
                        }
                      }}
                      onClick={() => handleSelect(step.id)}
                    >
                      Select
                    </Button>
                  </CardActions>
                </Card>
              </Box>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      
      <MobileStepper
        sx={{ borderRadius: 4, background:'transparent'}}
        steps={Math.min(maxSteps, 7)}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            variant='outlined'
            sx={{
              color: themeColor,
              borderColor: themeColor,
              '&:hover': {
                backgroundColor: `${themeColor}20`,  // 12% opacity
                borderColor: `${themeColor}80`,      // 50% opacity
              }
            }}
            size="small"
            onClick={handleNext}
            color={switchPC === 'Call' ? 'primary': 'secondary'}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button 
            variant='outlined'
            sx={{
              color: themeColor,
              borderColor: themeColor,
              '&:hover': {
                backgroundColor: `${themeColor}20`,  // 12% opacity
                borderColor: `${themeColor}80`,      // 50% opacity
              }
            }}
            size="small" 
            color={switchPC === 'Call' ? 'primary': 'secondary'}
            onClick={handleBack} 
            disabled={activeStep === 0}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
}

export default SwipeableTextMobileStepper;